import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {
  Column2Grid,
  FlexContainer,
  Grid,
  GridItem,
  GridRow,
  GridWrapper,
  Row2Grid,
  Row3Grid,
} from './styles';

import { IconButton } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { StyledFlex } from 'src/components/StyledFlex';
import { EsgDmaMatrixDatapointSchema } from '../types';
import { UUID } from 'crypto';
import { SCALING_FACTOR } from './Plot';

const displayValueOrFallback = (
  value: number | null | undefined,
  fallback: string = '-'
) => {
  if (value !== null && value !== undefined) {
    return value.toFixed(2);
  }
  return fallback;
};

const getBackgroundColor = (value: number | null): string => {
  const startAlpha = 0.02;
  const endAlpha = 0.5;

  if (value === null) {
    return 'rgba(0, 0, 0, 0)';
  }

  const alpha = startAlpha + ((endAlpha - startAlpha) * value) / SCALING_FACTOR; // we need to scale the value from 0-3 to 0-1 range

  return `rgba(233, 188, 70, ${alpha})`;
};

interface ScoreGridItemProps {
  score: number | null;
}

function ScoreGridItem({ score }: ScoreGridItemProps) {
  return (
    <GridItem
      style={{
        font: 'Poppins',
        fontSize: '18px',
        fontWeight: 400,
        backgroundColor: getBackgroundColor(score),
      }}
    >
      {displayValueOrFallback(score)}
    </GridItem>
  );
}

interface Props {
  data: EsgDmaMatrixDatapointSchema[];
  onToggleDataPointVisibility: (id: number) => void;
  hiddenDataPoints: number[];
}

export default function DmaMatrixTable(props: Props) {
  const { t } = useTranslation();

  return (
    <GridWrapper>
      <Grid>
        <Row2Grid>
          <GridItem style={{ backgroundColor: '#E8E8E8' }}>
            {t('esg:dmaGrid.horizon')}
          </GridItem>
          <Column2Grid style={{ backgroundColor: '#F6F6F6' }}>
            <GridItem
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#F6F6F6',
                borderRight: 'none',
                marginRight: '-1px',
              }}
            >
              {t('esg:dmaGrid.symbol')}
            </GridItem>
            <GridItem
              style={{
                display: 'flex',
                backgroundColor: '#F6F6F6',
                borderLeft: 'none',
                justifyContent: 'start',
              }}
            >
              {t('esg:dmaGrid.matter')}
            </GridItem>
          </Column2Grid>
        </Row2Grid>
        <Row3Grid>
          <GridItem style={{ backgroundColor: '#E8E8E8' }}>
            {t('esg:dmaGrid.shortTerm')}
          </GridItem>
          <GridItem style={{ backgroundColor: '#F6F6F6' }}>
            {t('esg:dmaGrid.materiality')}:
          </GridItem>
          <FlexContainer style={{ backgroundColor: '#F6F6F6' }}>
            <GridItem>{t('esg:dmaGrid.impact')}</GridItem>
            <GridItem>{t('esg:dmaGrid.financial')}</GridItem>
          </FlexContainer>
        </Row3Grid>
        <Row3Grid>
          <GridItem style={{ backgroundColor: '#E8E8E8' }}>
            {t('esg:dmaGrid.mediumTerm')}
          </GridItem>
          <GridItem style={{ backgroundColor: '#F6F6F6' }}>
            {t('esg:dmaGrid.materiality')}:
          </GridItem>
          <FlexContainer style={{ backgroundColor: '#F6F6F6' }}>
            <GridItem>{t('esg:dmaGrid.impact')}</GridItem>
            <GridItem>{t('esg:dmaGrid.financial')}</GridItem>
          </FlexContainer>
        </Row3Grid>
        <Row3Grid>
          <GridItem style={{ backgroundColor: '#E8E8E8' }}>
            {t('esg:dmaGrid.longTerm')}
          </GridItem>
          <GridItem style={{ backgroundColor: '#F6F6F6' }}>
            {t('esg:dmaGrid.materiality')}:
          </GridItem>
          <FlexContainer style={{ backgroundColor: '#F6F6F6' }}>
            <GridItem>{t('esg:dmaGrid.impact')}</GridItem>
            <GridItem>{t('esg:dmaGrid.financial')}</GridItem>
          </FlexContainer>
        </Row3Grid>
        <GridItem style={{ backgroundColor: '#E8E8E8' }}>
          {t('esg:dmaGrid.impact')}
        </GridItem>
        <GridItem>{t('esg:dmaGrid.showOnGraph')}</GridItem>
      </Grid>
      {props.data.map(({ esg_topic_key: key, ...matter }) => (
        <GridRow key={matter.id}>
          <Column2Grid>
            <GridItem
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
              }}
            >
              <StyledFlex
                sx={{
                  width: '32px',
                  height: '32px',
                  backgroundColor: matter.esg_topic_color,
                  borderRadius: '50%',
                  justifyContent: 'center',
                }}
              >
                {/*hide borders*/}
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                  }}
                />
                {matter.id}
              </StyledFlex>
            </GridItem>
            <GridItem
              style={{
                display: 'flex',
                justifyContent: 'start',
                textAlign: 'left',
              }}
            >
              {matter.esg_topic_title}
            </GridItem>
          </Column2Grid>
          <ScoreGridItem
            score={matter.scores.short.nonfinancial as number | null}
          />
          <ScoreGridItem
            score={matter.scores.short.financial as number | null}
          />
          <ScoreGridItem
            score={matter.scores.medium.nonfinancial as number | null}
          />
          <ScoreGridItem
            score={matter.scores.medium.financial as number | null}
          />
          <ScoreGridItem
            score={matter.scores.long.nonfinancial as number | null}
          />
          <ScoreGridItem
            score={matter.scores.long.financial as number | null}
          />

          <GridItem style={{ backgroundColor: '#E8E8E8', fontSize: '12px' }}>
            {t(`esg:dmaGrid.impactLevel.${matter.impact_level}`)}
          </GridItem>
          <GridItem>
            <IconButton
              sx={{ color: '#38414F', zIndex: 999 }}
              onClick={() => {
                props.onToggleDataPointVisibility(matter.id);
              }}
            >
              {!props.hiddenDataPoints.includes(matter.id) ? (
                <CheckBoxIcon />
              ) : (
                <CheckBoxOutlineBlankIcon />
              )}
            </IconButton>
          </GridItem>
        </GridRow>
      ))}
    </GridWrapper>
  );
}
