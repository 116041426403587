import { t } from 'i18next';

// eslint-disable-next-line import/prefer-default-export
export const sortOptions = [
  {
    value: 'positive',
    label: t(
      'esg:dma.stakeholderAnalytics.dashboard.impactTable.sortingOptions.mostPositive'
    ),
  },
  {
    value: 'negative',
    label: t(
      'esg:dma.stakeholderAnalytics.dashboard.impactTable.sortingOptions.mostNegative'
    ),
  },
];
