import React, { useContext, useEffect, useState } from 'react';
import { UniversalField } from '../types';
import QuestionnaireContext from '../../../Data/QuestionnaireContext';
import styled from 'styled-components';
import Files from './Files';
import RemoveModal from './RemoveModal';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StyledErrorMessage } from '../../index.styles';
import UploadModal from 'src/components/UploadModal';

const FilesWrapper = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
`;

export default function FileUploadWrapper({
  onChange,
  onForceQuestionAnswerSave,
  value,
  field,
  errors,
}: UniversalField) {
  const { questionnaireAnswerId } = useContext(QuestionnaireContext);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [removeFile, setRemoveFile] = useState<string | undefined>(undefined);
  const { t } = useTranslation();
  const [uploadOpen, setUploadOpen] = useState(false);
  const { tokenAuth } = useContext(QuestionnaireContext);
  const getRequestHeaders = async () => {
    const token = await tokenAuth();
    return {
      'X-Questionnaires-Token': token as string,
    };
  };

  const triggerQuestionAnswerSave = onForceQuestionAnswerSave;

  const onUploadClose = () => {
    if (field.visual_type === 'simple-file-picker')
      if (!!value) triggerQuestionAnswerSave();

    if (field.visual_type === 'multi-file-picker')
      if (Array.isArray(value) && !!value.length) triggerQuestionAnswerSave();

    setUploadOpen(false);
  };

  /* backend side validation */
  useEffect(() => {
    const newMessage = errors?.[field.key];
    if (!!newMessage) setErrorMessage(newMessage);
  }, [errors]);

  const url = `api-questionnaires/questionnaire-attachments?questionnaire_answer_id=${questionnaireAnswerId}`;

  const handleRemove = (id: string) => {
    if (field.visual_type === 'simple-file-picker') {
      onChange(null);
    }

    if (field.visual_type === 'multi-file-picker') {
      // @ts-ignore
      const newValue = [...value].filter((el) => el.id !== id);
      onChange(newValue);
    }
  };

  const handleCloseRemoveModal = () => setRemoveFile(undefined);

  const onSuccess = (file: any) => {
    if (field.visual_type === 'simple-file-picker') {
      onChange(file);
    }
    if (field.visual_type === 'multi-file-picker') {
      onChange([...value, file]);
    }
  };

  return (
    <div style={{ marginBottom: '20px' }}>
      <FilesWrapper>
        <Files value={value} setSelectedFile={setRemoveFile} />
      </FilesWrapper>

      <Button
        className="attache"
        onClick={() => setUploadOpen(true)}
        type="button"
      >
        {t('esg:attach')}
      </Button>

      <RemoveModal
        mutation={() => handleRemove(removeFile as string)}
        open={!!removeFile}
        onClose={handleCloseRemoveModal}
      />
      <UploadModal
        url={url}
        open={uploadOpen}
        onClose={onUploadClose}
        onSuccess={onSuccess}
        getRequestHeaders={getRequestHeaders}
      />
      <StyledErrorMessage>{errorMessage}</StyledErrorMessage>
    </div>
  );
}
