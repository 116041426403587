import React from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from '@mui/material';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';

import BnpLogo from 'src/images/bnp_logo.png';
import Logo from 'src/views/components/Logo';
import { LogosContainer, Subtitle, Title } from './index.styles';

export default function Header() {
  const { t } = useTranslation();
  return (
    <>
      <LogosContainer>
        <Box sx={{ ml: '55px' }}>
          <Link to="/">
            <Logo width={195} height={52} />
          </Link>
        </Box>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ ml: '40px', mr: '40px', borderRightWidth: '2px' }}
        />
        <img src={BnpLogo} width="250px" height="68px" />
      </LogosContainer>
      <Title>{t('registration_bnp:title')}</Title>
      <Subtitle>{t('registration_bnp:subtitle')}</Subtitle>
    </>
  );
}
