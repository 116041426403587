import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import {
  useEsgSubTopic,
  useEsgTopic,
  useEsgStakeholdersAnswersAnalytics,
} from 'src/Esg/Dmav2/hooks';
import { StyledPanel } from 'src/Esg/styles';
import TopicRow from './TopicRow';
import { useEsgReports } from 'src/Esg/hooks';

export default function SummaryTable() {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'esg:dma.stakeholderAnalytics.dashboard.summaryTable',
  });
  const { reportId } = useParams();
  const { topics } = useEsgTopic();
  const { subTopics } = useEsgSubTopic();
  const { _instance: report } = useEsgReports({ id: Number(reportId) });
  const { analytics } = useEsgStakeholdersAnswersAnalytics(report?.esg_dma_id);

  return (
    <StyledPanel>
      <Typography variant="h3">{t('title')}</Typography>
      <TableContainer>
        <Table stickyHeader id="summary-table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>&nbsp;</TableCell>
              <TableCell colSpan={11}>{t('opinions')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>{t('ESRS')}</TableCell>
              <TableCell colSpan={5}>{t('positive')}</TableCell>
              <TableCell colSpan={5}>{t('negative')}</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('topic')}</TableCell>
              <TableCell>{t('subTopic')}</TableCell>
              <TableCell>{t('noInfluence')}</TableCell>
              <TableCell>{t('lowInfluence')}</TableCell>
              <TableCell>{t('mediumInfluence')}</TableCell>
              <TableCell>{t('highInfluence')}</TableCell>
              <TableCell>{t('result')}</TableCell>

              <TableCell>{t('noInfluence')}</TableCell>
              <TableCell>{t('lowInfluence')}</TableCell>
              <TableCell>{t('mediumInfluence')}</TableCell>
              <TableCell>{t('highInfluence')}</TableCell>
              <TableCell>{t('result')}</TableCell>
              <TableCell>{t('score')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {topics.map((topic) => (
              <TopicRow
                key={topic.id}
                topic={topic}
                subTopics={subTopics.filter((st) => st.topic_id === topic.id)}
                summary={analytics.summary}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledPanel>
  );
}
