import { useQuery } from 'react-query';
import { useLanguage } from 'src/common/hooks';
import { client } from 'src/utils/api-client';
import { GhgFactorTableEntrySchema } from './types';
import { GhgDataEntryCompletedStatisticsSchema } from './interfaces';

// eslint-disable-next-line
export const useGhgSidebarPermissions = (
  organizationId: number,
  year: number
) => {
  const queryKey = ['ghgSidebarPermissions', { organizationId, year }];
  const query = useQuery(queryKey, () =>
    client
      .get<{ [key: string]: boolean }>(
        '/web/user-organization-permissions/ghg-sidebar',
        {
          params: { organization_id: organizationId, year },
        }
      )
      .then((res) => res.data)
  );

  const data = query.data || {};

  const hasAccess = (scope: string, section: string) =>
    data[`${scope}::${section}`] || false;

  return { data, hasAccess };
};

export const useGhgFactorEntries = () => {
  const { language } = useLanguage();
  const queryKey = ['ghgFactorEntries', language];

  const query = useQuery(queryKey, () =>
    client
      .get<GhgFactorTableEntrySchema[]>('/web/ghg-factors/factor-entries')
      .then((res) => res.data)
  );

  const data = query.data || [];

  return { ...query, data };
};

export const useGhgDataEntriesCompletedStatistics = (
  organizationId: number,
  fromPeriod: string,
  toPeriod: string
) => {
  const query = useQuery<GhgDataEntryCompletedStatisticsSchema[]>(
    ['data-entries-completed', { organizationId, fromPeriod, toPeriod }],
    () =>
      client
        .get('/web/v2/data-entries-completed/statistics', {
          params: {
            organization_id: organizationId,
            from_period: fromPeriod,
            to_period: toPeriod,
          },
        })
        .then((res) => res?.data)
  );
  return { ...query, data: query.data };
};
