import { MenuItem } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ToolsButton from '../ToolsButton';
import exportCsvIcon from '../../images/export_csv_icon.svg';
import exportDocxIcon from '../../images/export_docx_icon.svg';
import { useMutation } from 'react-query';
import { client } from 'src/utils/api-client';
import { toast } from 'react-toastify';
import { getFilenameDateSuffix } from 'src/utils/timeConverter';

interface Props {
  organizationIds?: number[];
  questionnaireIds?: string[];
  reportIds?: number[];
  allowDocx?: boolean;
  size: 'small' | 'medium' | 'large';
}

const EsgExportToolsButton = (props: Props) => {
  const { t } = useTranslation();

  const mutationRequest = (url: string) => {
    const params = {
      organization_ids: props.organizationIds,
      report_ids: props.reportIds,
      questionnaire_ids: props.questionnaireIds,
    };
    return client.get(url, { params, responseType: 'blob' });
  };

  const downloadReport = (response: any, fileName: string) => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(response.data);
    link.download = fileName;
    link.click();
  };

  const handleError = () => {
    toast.error(t('esg:downloadError') as string, {
      autoClose: 10_000,
      toastId: 'submit-error',
    });
  };

  const exportCsvMutation = useMutation({
    mutationFn: () => mutationRequest(`/web/reports/esg-export-csv`),
    onSuccess: (response) => {
      const currentDate = getFilenameDateSuffix();
      downloadReport(response, `esg-report-${currentDate}.csv`);
    },
    onError: () => handleError(),
  });

  const exportDocxMutation = useMutation({
    mutationFn: () => mutationRequest(`/web/reports/esg-export-docx`),
    onSuccess: (response) => {
      const currentDate = getFilenameDateSuffix();
      downloadReport(response, `esg-report-${currentDate}.docx`);
    },
    onError: () => handleError(),
  });

  const isLoading = exportCsvMutation.isLoading || exportDocxMutation.isLoading;

  return (
    <ToolsButton isLoading={isLoading} size={props.size}>
      <MenuItem onClick={() => exportCsvMutation.mutate()} disableRipple>
        <img alt="export_csv_icon" src={exportCsvIcon} />
        {t('esg:exportCsv')}
      </MenuItem>
      {props.allowDocx && (
        <MenuItem onClick={() => exportDocxMutation.mutate()} disableRipple>
          <img alt="export_docx_icon" src={exportDocxIcon} />
          {t('esg:exportDocx')}
        </MenuItem>
      )}
    </ToolsButton>
  );
};

export default EsgExportToolsButton;
