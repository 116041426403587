import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledPanel, StyledTitle } from 'src/Superadmin/index.styles';
import ChangePasswordDialog from './ChangePasswordDialog';
import { useQuery } from 'react-query';
import { client } from '../../../../utils/api-client';
import {
  OrganizationOutFormSchema,
  UserInFormSchema,
} from '../../../index.types';
import Autocomplete from 'src/components/Autocomplete';
import {
  ErrorBuilder,
  SchemaErrors,
  mapError,
} from '../../../../utils/validation';
import TextField from 'src/components/TextField';

export type UserFormErrors = SchemaErrors<UserInFormSchema>;

export type UserFormState = {
  id?: string | number | null;
  myAccount?: boolean;
  firstName: string;
  setFirstName: React.Dispatch<React.SetStateAction<string>>;
  lastName: string;
  setLastName: React.Dispatch<React.SetStateAction<string>>;
  position: string;
  setPosition: React.Dispatch<React.SetStateAction<string>>;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  phone: string;
  setPhone: React.Dispatch<React.SetStateAction<string>>;
  initialOrganizationId: string | null;
  setInitialOrganizationId: React.Dispatch<React.SetStateAction<string | null>>;
  errors: UserFormErrors;
  setErrors: React.Dispatch<React.SetStateAction<UserFormErrors>>;
};

export const validateForm = (
  data: UserInFormSchema,
  is_creating: boolean
): UserFormErrors => {
  const builder = new ErrorBuilder(data).checkTruthy(
    ['first_name', 'last_name', 'email'],
    'validation.required'
  );

  if (is_creating) {
    builder.checkTruthy(['initial_organization_id'], 'validation.required');
  }

  return builder.build();
};

export const useUserForm = (): UserFormState => {
  const [firstName, setFirstName] = React.useState<string>('');
  const [lastName, setLastName] = React.useState<string>('');
  const [position, setPosition] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [phone, setPhone] = React.useState<string>('');
  const [initialOrganizationId, setInitialOrganizationId] = React.useState<
    string | null
  >(null);
  const [errors, setErrors] = React.useState<UserFormErrors>({});

  return {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    position,
    setPosition,
    email,
    setEmail,
    phone,
    setPhone,
    initialOrganizationId,
    setInitialOrganizationId,
    errors,
    setErrors,
  };
};

type Props = UserFormState & {
  onSubmit?: () => void;
};

export default function UserForm(props: Props) {
  const { t } = useTranslation();
  const {
    id,
    myAccount = false,
    onSubmit,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    position,
    setPosition,
    email,
    setEmail,
    phone,
    setPhone,
    initialOrganizationId,
    setInitialOrganizationId,
    errors,
  } = props;
  const { data: organizations } = useQuery('organizations', () =>
    client
      .get<
        OrganizationOutFormSchema[]
      >(`web/settings_v2/organizations?can_edit=true`)
      .then((res) => res?.data || [])
  );
  const shouldSelectInitialOrganization = !id;

  const [changePasswordOpen, setChangePasswordOpen] = React.useState(false);

  const commonProps = {
    fullWidth: true,
    sx: { mb: '24px' },
    onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) =>
      e.key === 'Enter' && onSubmit ? onSubmit() : null,
  };

  return (
    <div>
      <StyledTitle sx={{ fontSize: '28px', mt: '40px' }}>
        {t('superadmin:components.panels.userForm.basicInformation.title')}
      </StyledTitle>

      <StyledPanel sx={{ mt: '32px', p: '32px' }}>
        {shouldSelectInitialOrganization && (
          <Autocomplete
            label={t(
              'superadmin:components.panels.userForm.basicInformation.initialOrg.label'
            )}
            placeholder={t(
              'superadmin:components.panels.userForm.basicInformation.initialOrg.placeholder'
            )}
            options={organizations?.map((org) => ({
              value: org.id.toString(),
              label: org.name,
            }))}
            value={initialOrganizationId}
            onChange={(val) => setInitialOrganizationId(val)}
            sx={{ mb: '24px' }}
            errorText={mapError(
              errors,
              'initial_organization_id',
              initialOrganizationId
            )}
          />
        )}
        <TextField
          {...commonProps}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          label={t(
            'superadmin:components.panels.userForm.basicInformation.firstName.label'
          )}
          placeholder={t(
            'superadmin:components.panels.userForm.basicInformation.firstName.placeholder'
          )}
          errorText={mapError(errors, 'first_name', firstName)}
        />
        <TextField
          {...commonProps}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          label={t(
            'superadmin:components.panels.userForm.basicInformation.lastName.label'
          )}
          placeholder={t(
            'superadmin:components.panels.userForm.basicInformation.lastName.placeholder'
          )}
          errorText={mapError(errors, 'last_name', lastName)}
        />
        <TextField
          {...commonProps}
          value={position}
          onChange={(e) => setPosition(e.target.value)}
          label={t(
            'superadmin:components.panels.userForm.basicInformation.position.label'
          )}
          placeholder={t(
            'superadmin:components.panels.userForm.basicInformation.position.placeholder'
          )}
          errorText={mapError(errors, 'position', position)}
        />
        <TextField
          {...commonProps}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          label={t(
            'superadmin:components.panels.userForm.basicInformation.phone.label'
          )}
          placeholder={t(
            'superadmin:components.panels.userForm.basicInformation.phone.placeholder'
          )}
          errorText={mapError(errors, 'phone', phone)}
        />
        <TextField
          {...commonProps}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          label={t(
            'superadmin:components.panels.userForm.basicInformation.email.label'
          )}
          placeholder={t(
            'superadmin:components.panels.userForm.basicInformation.email.placeholder'
          )}
          type="email"
          errorText={mapError(errors, 'email', email)}
          disabled={!!id}
        />
        {!!myAccount && (
          <>
            <TextField
              {...commonProps}
              value="**********"
              onClick={() => setChangePasswordOpen(true)}
              onChange={() => {}}
              label={t(
                'superadmin:components.panels.userForm.basicInformation.password.label'
              )}
              placeholder={t(
                'superadmin:components.panels.userForm.basicInformation.password.placeholder'
              )}
              type="password"
              helperText={
                <div
                  onClick={() => setChangePasswordOpen(true)}
                  style={{
                    width: '100%',
                    textAlign: 'right',
                    cursor: 'pointer',
                  }}
                >
                  {t(
                    'superadmin:components.panels.userForm.basicInformation.password.changePassword'
                  )}
                </div>
              }
            />
            <ChangePasswordDialog
              open={changePasswordOpen}
              onClose={() => setChangePasswordOpen(false)}
            />
          </>
        )}
      </StyledPanel>
    </div>
  );
}
