import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import { Tabs } from '@mui/material';
import { StyledPanel, StyledTab, StyledTitle } from 'src/Esg/styles';
import { useEsgOrganization } from 'src/common/hooks';
import { useEsgStakeholderAnalytics } from './hooks';

const TABS = ['dashboard', 'table', 'subtopic', 'individual'];

export default function DmaStakeholderAnalytics() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const tab = location.pathname.split('/').pop();
  const { reportId } = useParams();
  const { id: organizationId } = useEsgOrganization();

  const { dataCount } = useEsgStakeholderAnalytics(
    organizationId,
    reportId as string
  );

  const handleTabChange = (_: any, _tab: string) =>
    navigate(`./stakeholders/analytics/${_tab}`);

  const title = t('esg:dma.stakeholderAnalytics.title', {
    count: dataCount,
  });

  return (
    <>
      <StyledPanel sx={{ p: '30x', pb: 0, mt: '20px' }}>
        <StyledTitle>{title}</StyledTitle>
        <br />
        <Tabs variant="fullWidth" value={tab} onChange={handleTabChange}>
          {TABS.map((_tab: string) => (
            <StyledTab
              key={_tab}
              label={t(`esg:dma.stakeholderAnalytics.tabs.${_tab}`)}
              id={_tab}
              value={_tab}
            />
          ))}
        </Tabs>
      </StyledPanel>
      <br />
      <Outlet />
    </>
  );
}
