import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionnaireMethodology } from 'src/Esg/types';

interface Props {
  value: QuestionnaireMethodology;
  // eslint-disable-next-line no-unused-vars
  onChange: (event: SelectChangeEvent<QuestionnaireMethodology>) => void;
}

export default function MethodologySelect(props: Props) {
  const { t } = useTranslation();
  return (
    <FormControl fullWidth>
      <InputLabel id="questionnaire-methodology-label">
        {t('esg:questionnaireMethodology.methodology')}
      </InputLabel>
      <Select
        labelId="questionnaire-methodology-label"
        label={t('esg:questionnaireMethodology.methodology')}
        {...props}
      >
        {Object.keys(QuestionnaireMethodology).map((methodology) => (
          <MenuItem key={methodology} value={methodology}>
            {t(`esg:questionnaireMethodology.${methodology}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
