import { Button, ThemeProvider, createTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { StyledFlex } from 'src/components/StyledFlex';
import { setSelectedCompanies } from 'src/redux-file/ghgSuperdashboard/slice';
import { usePlatformNavigation } from 'src/utils/navigation';
import RaportPopup from '../RaportPopup';
import { useAppSelector } from 'src/redux-file/hooks';
import { useQuery } from 'react-query';
import { client } from 'src/utils/api-client';
import { OrganizationDataOutDetailedSchema } from 'src/utils/api.interfaces';
import DownloadIcon from '@mui/icons-material/Download';

// this local theme is used to override and reset back to the default MuiButton styles
// as it was overwhelmed by the global theme
const localTheme = createTheme({
  typography: {
    fontFamily: 'Poppins',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#2F2F2F',
          backgroundColor: '#FFF',
          boxShadow: 'none',
          borderRadius: 20,
          borderColor: '#2F2F2F',
          textTransform: 'none',
          ':hover': {
            boxShadow: 'none',
            backgroundColor: '#E0E0E0',
          },
        },
      },
    },
  },
});
export default function ActionButtons() {
  const { t } = useTranslation();
  const { productOrganizations } = usePlatformNavigation();
  const { selectedCompanies } = useAppSelector((s) => s.ghgSuperdashboard);
  const dispatch = useDispatch();

  const [reportPopupOpen, setReportPopupOpen] = React.useState(false);

  // we need this ugly next 8 lines to fetch organization detailed schema (co2 data), as redux organizations do not have co2 field
  const { data: organizations } = useQuery('superdashboard-organizations', () =>
    client
      .get<OrganizationDataOutDetailedSchema[]>('/web/organizations')
      .then((res) => res.data.filter((org) => !!org.co2))
  );
  const reportingFreq =
    organizations?.find((o) => o.id === selectedCompanies[0])?.co2
      ?.reporting_freq || 'M';

  const handleClearAll = () => dispatch(setSelectedCompanies([]));
  const handleSelectAll = () =>
    dispatch(setSelectedCompanies(productOrganizations.map((org) => org.id)));

  return (
    <ThemeProvider theme={localTheme}>
      <StyledFlex sx={{ mt: '8px' }}>
        <Button
          variant="contained"
          size="small"
          sx={{ width: '49%' }}
          onClick={handleClearAll}
        >
          {t('superdashboardGhg:sidebar.buttons.clear')}
        </Button>
        <Button
          variant="contained"
          size="small"
          sx={{ width: '49%' }}
          onClick={handleSelectAll}
        >
          {t('superdashboardGhg:sidebar.buttons.selectAll')}
        </Button>
      </StyledFlex>

      <Button
        disabled={selectedCompanies.length === 0}
        startIcon={<DownloadIcon />}
        variant="contained"
        size="small"
        sx={{ width: '100%', mt: '8px' }}
        onClick={() => setReportPopupOpen(true)}
      >
        {t('menu.download-report')}
      </Button>

      <RaportPopup
        opened={reportPopupOpen}
        setOpened={setReportPopupOpen}
        organizationIds={selectedCompanies}
        reportingFreq={reportingFreq}
      />
    </ThemeProvider>
  );
}
