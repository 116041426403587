import {
  Box,
  Button,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import React from 'react';
import { StyledPanel } from 'src/components/StyledPanel';
import {
  AreaOfImport,
  CbamImportedGoodInSchema,
  CbamImportedGoodOutSchema,
  CustomsPreviousProcedure,
  CustomsRequestedProcedure,
} from '../types';
import { toast } from 'react-toastify';
import NavigationBlockerModal from 'src/components/NavigationBlockerModal';
import { useTranslation } from 'react-i18next';
import { FormErrors, requiredFields, validateForm } from './FormValidation';
import { extractSchemaErrors, mapError } from 'src/utils/validation';
import {
  useActors,
  useImportedGoods,
  useProductionProcesses,
  useSupportingDocumentsForImportedGoods,
} from '../hooks';
import {
  StyledFormControl,
  StyledSectionHeader,
  StyledNumberField,
  StyledSectionDivider,
  StyledTextField,
} from '../styles';
import { Delete } from '@mui/icons-material';
import { quantityUnit } from '../utils';
import { useCbamOrganization } from 'src/common/hooks';

interface Props {
  id?: Number;
  reportId: string;
  onSaved?: () => void;
}

export default function ImportedGoodForm(props: Props) {
  const { id, onSaved, reportId } = props;
  const { id: organizationId } = useCbamOrganization();

  const { data, create, update } = useImportedGoods(reportId);
  const { data: actors } = useActors();
  const { data: processes } = useProductionProcesses();
  const { data: documents } = useSupportingDocumentsForImportedGoods();

  const good = data?.find((good) => good.id === id);

  const { t } = useTranslation(undefined, {
    keyPrefix: 'cbam:importedGoods',
  });

  const { t: supportingDocumentTranslation } = useTranslation(undefined, {
    keyPrefix: 'cbam:supportingDocuments',
  });

  const [form, setForm] = React.useState<
    CbamImportedGoodOutSchema | CbamImportedGoodInSchema
  >({
    cbam_organization_id: organizationId,
    name: '',
    amount: null,
    import_area: null,
    requested_procedure: null,
    previous_procedure: null,

    importer_id: null,
    representative_id: null,
    production_process_id: null,
    report_id: Number(reportId),
    supporting_documents: [],
    remarks: '',
  });

  const [errors, setErrors] = React.useState<FormErrors>({});

  const [isModified, setIsModified] = React.useState(false);

  const handleFormChange = (
    key: string,
    value: string | number | (number | null)[] | null
  ) => {
    const newForm = {
      ...form,
      [key]: value,
    };
    setForm(newForm);
    setErrors(validateForm(newForm, t));
    setIsModified(true);
  };

  const handleFormChangeDocument = (index: number, value: number) => {
    const newDocuments = form.supporting_documents.slice();
    newDocuments[index] = value;
    handleFormChange('supporting_documents', newDocuments);
  };

  const handleFormAddDocument = () => {
    const newDocuments = form.supporting_documents.slice();
    newDocuments.push(null);
    handleFormChange('supporting_documents', newDocuments);
  };

  const handleFormRemoveDocument = (index: number) => {
    const newDocuments = form.supporting_documents.slice();
    newDocuments.splice(index, 1);
    handleFormChange('supporting_documents', newDocuments);
  };

  const usedDocuments = form.supporting_documents.map(
    (doc) => documents.find((d) => d.id === doc) || null
  );

  React.useEffect(() => {
    if (good) setForm(good);
  }, [good]);

  const save = !good ? create.mutateAsync : update.mutateAsync;

  const isError = (fieldName: keyof CbamImportedGoodInSchema) =>
    !!form[fieldName] && !!mapError(errors, fieldName, form[fieldName]);
  const errorText = (fieldName: keyof CbamImportedGoodInSchema) =>
    isError(fieldName) && (mapError(errors, fieldName, form[fieldName]) || '');

  const handleSaveClick = () => {
    setIsModified(false);
    save(form)
      .then(() => onSaved?.())
      .catch((err: any) => {
        setIsModified(true);
        toast.error('Failed to save');
        const extractedErrors = extractSchemaErrors(err, form);
        setErrors(extractedErrors);
      });
  };

  const unit = quantityUnit(
    processes.find((p) => p.id === form.production_process_id)
  );

  return (
    <Box>
      <NavigationBlockerModal shouldBlock={isModified} />
      <StyledPanel>
        <StyledSectionHeader>{t('about')}</StyledSectionHeader>

        <StyledTextField
          label={t(`form.name`)}
          value={form.name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleFormChange('name', e.currentTarget.value);
          }}
          required={requiredFields.includes('name')}
          error={isError('name')}
          helperText={errorText('name')}
        />
        <StyledFormControl
          required={requiredFields.includes('importer_id')}
          error={isError('importer_id')}
        >
          <InputLabel id="importer_id">
            {errorText('importer_id') || t('form.importer_id')}
          </InputLabel>
          <Select
            key={form.importer_id}
            labelId="importer_id"
            label={errorText('importer_id') || t('form.importer_id')}
            value={form.importer_id || ''}
            onChange={(e) => handleFormChange('importer_id', e.target.value)}
          >
            {actors?.map((actor) => (
              <MenuItem key={actor.id} value={actor.id}>
                {actor.name} ({actor.identifier})
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>
        <StyledFormControl
          required={requiredFields.includes('representative_id')}
          error={isError('representative_id')}
        >
          <InputLabel id="representative_id">
            {errorText('representative_id') || t('form.representative_id')}
          </InputLabel>
          <Select
            key={form.representative_id}
            labelId="representative_id"
            label={
              errorText('representative_id') || t('form.representative_id')
            }
            value={form.representative_id || ''}
            onChange={(e) =>
              handleFormChange('representative_id', e.target.value)
            }
          >
            <MenuItem
              onClick={() => handleFormChange('representative_id', null)}
            >
              None
            </MenuItem>
            {actors?.map((actor) => (
              <MenuItem key={actor.id} value={actor.id}>
                {actor.name} ({actor.identifier})
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>
        <StyledFormControl
          required={requiredFields.includes('production_process_id')}
          error={isError('production_process_id')}
        >
          <InputLabel id="production_process_id">
            {errorText('production_process_id') ||
              t('form.production_process_id')}
          </InputLabel>
          <Select
            key={form.production_process_id}
            labelId="production_process_id"
            label={
              errorText('production_process_id') ||
              t('form.production_process_id')
            }
            value={form.production_process_id || ''}
            onChange={(e) =>
              handleFormChange('production_process_id', e.target.value)
            }
          >
            {processes?.map((process) => (
              <MenuItem key={process.id} value={process.id}>
                {process.name}
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>

        <StyledSectionDivider />

        <StyledSectionHeader>{t('customs')}</StyledSectionHeader>

        <StyledNumberField
          label={`${t('form.amount')} [${unit}]`}
          value={form.amount}
          onChange={(value) => handleFormChange('amount', value)}
          required={requiredFields.includes('amount')}
          error={isError('amount')}
          helperText={errorText('amount')}
        />

        <StyledFormControl
          required={requiredFields.includes('import_area')}
          error={isError('import_area')}
        >
          <InputLabel id="import_area">
            {errorText('import_area') || t('form.import_area')}
          </InputLabel>
          <Select
            labelId="import_area"
            label={errorText('import_area') || t('form.import_area')}
            value={form.import_area || ''}
            onChange={(e) => handleFormChange('import_area', e.target.value)}
          >
            {Object.values(AreaOfImport).map((area) => (
              <MenuItem key={area} value={area}>
                {t(`areaOfImport.${area}`)}
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>

        <StyledFormControl
          required={requiredFields.includes('requested_procedure')}
          error={isError('requested_procedure')}
        >
          <InputLabel id="requested_procedure">
            {errorText('requested_procedure') || t('form.requested_procedure')}
          </InputLabel>
          <Select
            key={form.requested_procedure}
            labelId="requested_procedure"
            label={
              errorText('requested_procedure') || t('form.requested_procedure')
            }
            value={form.requested_procedure || ''}
            onChange={(e) =>
              handleFormChange('requested_procedure', e.target.value)
            }
          >
            {Object.values(CustomsRequestedProcedure).map((proc) => (
              <MenuItem key={proc} value={proc} sx={{ whiteSpace: 'normal' }}>
                ({proc}) {t(`requestedProcedure.${proc}`)}
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>

        <StyledFormControl
          required={requiredFields.includes('previous_procedure')}
          error={isError('previous_procedure')}
        >
          <InputLabel id="previous_procedure">
            {errorText('previous_procedure') || t('form.previous_procedure')}
          </InputLabel>
          <Select
            key={form.previous_procedure}
            labelId="previous_procedure"
            label={
              errorText('previous_procedure') || t('form.previous_procedure')
            }
            value={form.previous_procedure || ''}
            onChange={(e) =>
              handleFormChange('previous_procedure', e.target.value)
            }
          >
            <MenuItem
              onClick={() => handleFormChange('previous_procedure', null)}
            >
              None
            </MenuItem>
            {Object.values(CustomsPreviousProcedure).map((proc) => (
              <MenuItem key={proc} value={proc} sx={{ whiteSpace: 'normal' }}>
                ({proc}) {t(`requestedProcedure.${proc}`)}
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>

        <StyledSectionDivider />

        <StyledSectionHeader>{t('extra')}</StyledSectionHeader>

        {usedDocuments.map((doc, index) => (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
            <StyledFormControl error={isError('supporting_documents')}>
              <InputLabel id="supporting_documents">
                {t('form.supporting_documents')}
              </InputLabel>
              <Select
                labelId="supporting_documents"
                label={t('form.supporting_documents')}
                value={doc?.id || ''}
                onChange={(e) =>
                  handleFormChangeDocument(index, Number(e.target.value))
                }
              >
                {documents?.map((document) => (
                  <MenuItem key={document.id} value={document.id}>
                    {document.reference_number} (
                    {supportingDocumentTranslation(`types.${document.type}`)})
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>
            <IconButton onClick={() => handleFormRemoveDocument(index)}>
              <Delete />
            </IconButton>
          </Box>
        ))}

        {isError('supporting_documents') && (
          <Typography color="error">
            {errorText('supporting_documents')}
          </Typography>
        )}

        <Button sx={{ mb: '12px' }} onClick={handleFormAddDocument}>
          Add Document
        </Button>

        <StyledTextField
          label={t(`form.remarks`)}
          value={form.remarks || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleFormChange('remarks', e.currentTarget.value);
          }}
          multiline
          rows="3"
        />
      </StyledPanel>
      <br />
      <br />
      <Button
        sx={{ float: 'right' }}
        onClick={handleSaveClick}
        disabled={!isModified || Object.keys(validateForm(form, t)).length > 0}
      >
        {t('save')}
      </Button>
    </Box>
  );
}
