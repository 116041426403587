import { IconButton, Typography } from '@mui/material';
import React from 'react';
import { StyledFlex } from 'src/components/StyledFlex';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTranslation } from 'react-i18next';

export const usePaginationSwitch = () => {
  const [currPage, setCurrPage] = React.useState<number>(0);
  const handlePrevClick = () => setCurrPage((prevState) => prevState - 1);
  const handleNextClick = () => setCurrPage((prevState) => prevState + 1);
  return {
    currPage,
    setCurrPage,
    handlePrevClick,
    handleNextClick,
  };
};

interface Props {
  currPage: number;
  pagesCount: number;
  onPrevClick: () => void;
  onNextClick: () => void;
}
export default function PaginatorSwitch(props: Props) {
  const { t } = useTranslation();
  const { currPage, pagesCount, onPrevClick, onNextClick } = props;
  const text = `${currPage + 1} ${t('esg:common.outOf')} ${pagesCount}`;
  const isPrevDisabled = currPage === 0;
  const isNextDisabled = currPage >= pagesCount - 1;
  return (
    <StyledFlex sx={{ justifyContent: 'flex-start' }}>
      <IconButton disabled={isPrevDisabled} onClick={onPrevClick}>
        <ArrowBackIosNewIcon />
      </IconButton>
      <Typography>{text}</Typography>
      <IconButton disabled={isNextDisabled} onClick={onNextClick}>
        <ArrowForwardIosIcon />
      </IconButton>
    </StyledFlex>
  );
}
