import React from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { StyledCustomSelectValue, StyledNullComponent } from './index.styles';
import { t } from 'i18next';
import { defaultColors } from './utils';

type optionType = {
  label: string;
  selectedLabel?: string;
  value: any;
};

type ColorsType = {
  [key: string]: {
    main: string;
    contrastText: string;
  };
};

interface Props {
  key?: string;
  options: optionType[];
  value: any;
  // eslint-disable-next-line no-unused-vars
  onChange?: (value: any) => void;
  disabled?: boolean;
  fullWidth?: boolean;
  maxWidth?: string;
  colors?: ColorsType;
}
export default function CustomSelect(props: Props) {
  const { key, fullWidth, disabled = false, colors = defaultColors } = props;
  const { options, value, onChange = () => {} } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const selectedOption = options.find((opt) => opt.value === value);
  const handleClose = () => setAnchorEl(null);
  const handleOpen = (event: React.MouseEvent<HTMLElement>) =>
    !disabled && setAnchorEl(event.currentTarget);

  const nullLabel = disabled
    ? t('esg:common.customSelect.disabled')
    : t('esg:common.select');

  return (
    <center style={{ opacity: disabled ? 0.4 : 1, width: '100%' }}>
      {value === null ? (
        <StyledNullComponent onClick={handleOpen} disabled={disabled}>
          {nullLabel}
          <ArrowDropDownIcon />
        </StyledNullComponent>
      ) : (
        <Button
          disabled={disabled}
          id={key}
          disableElevation
          onClick={handleOpen}
          variant="secondary"
          size="small"
          sx={{
            textTransform: 'none',
            borderRadius: '20px',
            height: '40px',
            minWidth: '40px',
            maxWidth: props.maxWidth,
            whiteSpace: 'nowrap',
            backgroundColor: colors[value]?.main,
            color: colors[value]?.contrastText,
            // No background change on hover
            '&:hover': { backgroundColor: colors[value]?.main },
          }}
          fullWidth={fullWidth}
        >
          <StyledCustomSelectValue sx={{ color: colors[value]?.contrastText }}>
            {selectedOption?.selectedLabel || selectedOption?.label}
          </StyledCustomSelectValue>
        </Button>
      )}
      <Menu id={key} anchorEl={anchorEl} open={open} onClose={handleClose}>
        {options.map((option) => (
          <MenuItem
            key={option.value}
            onClick={() => {
              onChange(option.value);
              handleClose();
            }}
            sx={{ minWidth: '150px', whiteSpace: 'wrap' }}
            disableRipple
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </center>
  );
}
