import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  BigNumbers,
  MainMetricsContainer,
  MainMetricsPanel,
  StyledTextLight,
} from './index.styles';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
} from 'chart.js';
import BarChart from './BarChart';
import StakeholdersImpactTable from './StakeholdersImpactTable';
import { SurveyData } from './index.types';
import ScatterChart from './AnswersMatrix';
import { useEsgOrganization } from '../../../../../common/hooks';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { client } from '../../../../../utils/api-client';
import PageLoading from '../../../../../components/PageLoading';
import SomethingWentWrong from '../../../../../components/SomethingWentWrong';
import { dataLabelPlugin } from '../../../utils';
import { Typography } from '@mui/material';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  dataLabelPlugin
);

export default function Dashboard() {
  const { t } = useTranslation();
  const { id: organizationId } = useEsgOrganization();

  const { reportId } = useParams();

  const endpointUrl = `web/esg_stakeholders/${organizationId}/${reportId}/stakeholders_answers_visualization`;

  const { data, isError } = useQuery(
    ['stakeholders_answers', endpointUrl],
    () => client.get<SurveyData>(endpointUrl).then((r) => r.data)
  );

  if (isError) return <SomethingWentWrong />;
  if (data === undefined) return <PageLoading />;

  return (
    <div style={{ fontStyle: 'Poppins !important' }}>
      <Typography variant="h1">
        {t('esg:dma.stakeholderAnalytics.dashboard.mainMetrics')}
      </Typography>
      <MainMetricsContainer>
        <MainMetricsPanel>
          <StyledTextLight>
            {t('esg:dma.stakeholderAnalytics.dashboard.sumOfReceivedAnswers')}
          </StyledTextLight>
          <BigNumbers> {data.responses_count}</BigNumbers>
        </MainMetricsPanel>

        <MainMetricsPanel>
          <StyledTextLight>
            {t(
              'esg:dma.stakeholderAnalytics.dashboard.numOfTypesOfStakeholders'
            )}
          </StyledTextLight>
          <BigNumbers> {data.surveyed_stakeholder_types_count}</BigNumbers>
        </MainMetricsPanel>
        <MainMetricsPanel>
          <StyledTextLight>
            {t(
              'esg:dma.stakeholderAnalytics.dashboard.numOfStakeholdersWhoResponded'
            )}
          </StyledTextLight>
          <BigNumbers>{data.stakeholder_types_with_responses_count}</BigNumbers>
        </MainMetricsPanel>
      </MainMetricsContainer>
      <br />
      <ScatterChart data={data.summary} />
      <br />
      <StakeholdersImpactTable data={data} />
      <br />
      <BarChart data={data.summary} />
    </div>
  );
}
