import React from 'react';
import { TableCell } from '@mui/material';

import { useTranslation } from 'react-i18next';
import CustomSelect from 'src/components/CustomSelect';
import {
  EsgDmaRiskOpportunityMatterDataOutSchema,
  EsgDmaRiskOpportunityOutSchema,
  TimeHorizon,
} from '../../../../types';
import { financialScaleOptions, probabilityOptions } from '../utils';
import { useParams } from 'react-router-dom';
import FinancialScore from '../Scores/FinancialScore';
import { useEsgReports } from 'src/Esg/hooks';
import { useRiskOpportunityMatterData } from 'src/Esg/Dmav2/hooks';
import { useQueryClient } from 'react-query';

interface Props {
  riskOpportunity: EsgDmaRiskOpportunityOutSchema | null;
  timeHorizon: TimeHorizon;
}

export default function RiskOpportunityCells(props: Props) {
  const { timeHorizon, riskOpportunity } = props;
  const queryClient = useQueryClient();
  const { t } = useTranslation(undefined, {
    keyPrefix: 'esg:dma.step5TableV2.body',
  });
  const { reportId } = useParams();
  const { _instance: report } = useEsgReports({ id: Number(reportId) });
  const mattersData = useRiskOpportunityMatterData(
    { esg_dma_id: report?.esg_dma_id },
    { enabled: !!report }
  );

  if (!riskOpportunity) return <TableCell colSpan={4} />;
  if (mattersData.isLoading) return null;

  const matterData: Partial<EsgDmaRiskOpportunityMatterDataOutSchema> =
    mattersData._data.find(
      (i) =>
        i.esg_dma_risk_opportunity_id === riskOpportunity?.id &&
        i.time_horizon === timeHorizon
    ) || {
      esg_dma_id: report?.esg_dma_id,
      esg_dma_risk_opportunity_id: riskOpportunity?.id,
      time_horizon: timeHorizon,
      financial_scale: null,
      financial_probability: null,
    };

  const save = !!matterData?.id ? mattersData.update : mattersData.create;
  const handleChange = (key: string, value: any) =>
    save.mutateAsync({ ...matterData, [key]: value }).then(() => {
      // invalidating impacts to get fresh scores
      queryClient.invalidateQueries(['dma-risk-opportunities']);
    });

  return (
    <>
      <TableCell>
        <CustomSelect
          options={financialScaleOptions(t)}
          value={matterData.financial_scale}
          onChange={(value) => handleChange('financial_scale', value)}
        />
      </TableCell>
      <TableCell>
        <CustomSelect
          options={probabilityOptions(t)}
          value={matterData.financial_probability}
          onChange={(value) => handleChange('financial_probability', value)}
        />
      </TableCell>
      {timeHorizon === TimeHorizon.SHORT && (
        <FinancialScore riskOpportunity={riskOpportunity} />
      )}
    </>
  );
}
