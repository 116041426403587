import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import {
  CreateOrganizationMemberSchema,
  OrganizationOutFormSchema,
  UpdateOrganizationMemberSchema,
} from 'src/Superadmin/index.types';
import { client } from 'src/utils/api-client';

// eslint-disable-next-line import/prefer-default-export
export const useOrganizationMember = (memberId: number) => {
  const url = 'web/settings_v2/organization-members';
  const queryClient = useQueryClient();
  const { t } = useTranslation(undefined, {
    keyPrefix: 'superadmin:settings.tabs.users.usersTable.toasts.success',
  });

  const updateOrganizationMember = useMutation({
    mutationFn: (data: Omit<UpdateOrganizationMemberSchema, 'user_id'>) =>
      client.put<UpdateOrganizationMemberSchema>(`${url}/${data.id}`, {
        ...data,
        organization_id: data.organizationId,
        user_id: memberId,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries('organization-members');
      toast.success(t('update') as string);
    },
  });

  const deleteOrganizationMember = useMutation({
    mutationFn: (id: string) => client.delete(`${url}/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries('organization-members');
      toast.success(t('delete') as string);
    },
  });

  const createOrganizationMember = useMutation({
    mutationFn: (data: Omit<CreateOrganizationMemberSchema, 'user_id'>) =>
      client.post<CreateOrganizationMemberSchema>(url, {
        ...data,
        organization_id: data.organizationId,
        user_id: memberId,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries('organization-members');
      toast.success(t('post') as string);
    },
  });

  const { data } = useQuery('organization-groups', () =>
    client
      .get<OrganizationOutFormSchema[]>('web/settings_v2/organizations')
      .then((res) => res?.data || [])
  );

  const organizations = data || [];

  return {
    organizations,
    createOrganizationMember,
    updateOrganizationMember,
    deleteOrganizationMember,
  };
};
