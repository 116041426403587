import React, { useMemo } from 'react';
import Container from '../components/Container';
import assets from '../assets';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import ReportTabs from '../../../../../components/esg/reportTabs';
import Title from '../components/Title';
import PageLoading from '../../../../../components/PageLoading';
import { createTokenFetcher } from '../../../../components/QuestionnaireV3/helpers';
import { client } from '../../../../../utils/api-client';
import { EsgStakeholderSurveyMetadataSchema } from '../types';
import QuestionnaireV3 from '../../../../components/QuestionnaireV3';
import { useTranslation } from 'react-i18next';
import { useSurveyMeta } from '../hooks';
import SomethingWentWrong from '../../../../../components/SomethingWentWrong';
import NotFound from '../../../../components/NotFound';

const getUrlForIdx = (idx: number) => `../questionnaire/${idx}`;

export default function StakeholderSurveyQuestionnaire() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { token, idx: pathQuestionnaireIdx } = useParams();
  const { data: meta, isError, endpointUrl } = useSurveyMeta(token);

  const questionnaireIdx = parseInt(pathQuestionnaireIdx || '0', 10);
  const questionnaire = meta?.questionnaires[questionnaireIdx];

  const tokenFetcher = useMemo(
    () =>
      createTokenFetcher(async () => {
        // TODO: error handling would be nice..
        if (!questionnaire) return '';
        const { data } =
          await client.put<EsgStakeholderSurveyMetadataSchema>(endpointUrl);
        return data.questionnaires.find(
          (x) =>
            x.qv3_questionnaire_answer_id ===
            questionnaire.qv3_questionnaire_answer_id
        )?.qv3_questionnaire_answer_token!;
      }, questionnaire?.qv3_questionnaire_answer_token),
    [questionnaire, endpointUrl]
  );

  if (isError) return <SomethingWentWrong />;
  if (!meta) return <PageLoading />;
  if (meta.questionnaires.length === 0) return <NotFound />;
  if (!questionnaire) return <Navigate to="../questionnaire" />;

  const tabData = meta.questionnaires.map((el, i) => ({
    name: el.questionnaire_title,
    url: getUrlForIdx(i),
  }));

  const prevLink =
    questionnaireIdx === 0
      ? undefined
      : {
          onClick: () => {
            navigate(getUrlForIdx(questionnaireIdx - 1));
          },
          label: 'esg:stakeholderSurvey.back',
        };

  const nextLink = {
    onClick: () => {
      if (questionnaireIdx === meta.questionnaires.length - 1) {
        navigate(`../end`); // TODO: redirect to Summary when it's ready
        return;
      }
      navigate(getUrlForIdx(questionnaireIdx + 1));
    },
    label: 'esg:stakeholderSurvey.next',
  };

  return (
    <Container
      header={<img src={assets.intro.image} />}
      prevLink={prevLink}
      nextLink={nextLink}
    >
      <Title
        title={t('esg:stakeholderSurvey.title')}
        period={meta.report_period}
      />
      <ReportTabs data={tabData} />

      <QuestionnaireV3
        key={questionnaireIdx}
        questionnaireAnswerId={questionnaire.qv3_questionnaire_answer_id}
        tokenAuth={tokenFetcher}
      />
    </Container>
  );
}
