import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { IconButton, Typography } from '@mui/material';
import GroupsIcon from '@mui/icons-material/Groups';
import AnalyticsIcon from '@mui/icons-material/AnalyticsOutlined';
import FactCheckIcon from '@mui/icons-material/FactCheck';

import DmaStep from './components/DmaStep';
import DmaStage from './components/DmaStage';
import DmaPanel from './components/DmaPanel';
import LightTooltip from 'src/components/LightTooltip';
import { ImageSlider } from './components/ImageSlider';
import { useLanguage } from 'src/common/hooks';
import { StyledFlex } from 'src/components/StyledFlex';
import { useEsgReports } from '../hooks';
import { useEsgDmas } from '../Dmav2/hooks';
import PageLoading from 'src/components/PageLoading';
import SomethingWentWrong from 'src/components/SomethingWentWrong';

export default function Dma() {
  const { t, language } = useLanguage();
  const { reportId } = useParams();
  const { _instance: report } = useEsgReports({ id: Number(reportId) });
  const { _instance: dma, isLoading } = useEsgDmas(
    { id: report?.esg_dma_id },
    { enabled: !!report }
  );
  if (isLoading) return <PageLoading />;
  if (!dma) return <SomethingWentWrong />;

  const generateImageUrls = (
    language: string,
    presentationNumber: number,
    slidesCount: number
  ) => {
    const baseUrl = `https://assets.envirly.pl/dma/topics-slides/${language}`;
    return Array.from(
      { length: slidesCount },
      (_, index) =>
        `${baseUrl}/${String(presentationNumber).padStart(2, '0')}_${String(
          index + 1
        ).padStart(2, '0')}.png`
    );
  };

  return (
    <>
      <StyledFlex sx={{ justifyContent: 'flex-end' }}>
        <Typography variant="h6">Version: {dma.version}</Typography>
      </StyledFlex>
      <DmaPanel title={t('esg:dma.panels.introduction')}>
        <DmaStage
          title={t('esg:dma.stages.introduction.title')}
          subtitle={t('esg:dma.stages.introduction.subtitle')}
          containerSx={{ border: '4px solid #e9bc46' }}
        >
          <DmaStep
            title={t('esg:dma.stages.introduction.steps.1')}
            videoComponent={
              <ImageSlider images={generateImageUrls(language, 1, 5)} />
            }
          />
          <DmaStep
            title={t('esg:dma.stages.introduction.steps.2')}
            videoComponent={
              <ImageSlider images={generateImageUrls(language, 2, 9)} />
            }
          />
        </DmaStage>
      </DmaPanel>
      <br />
      <DmaPanel title={t('esg:dma.panels.dataCollectionAndAnalysis')}>
        <DmaStage
          title={t('esg:dma.stages.informationOverview.title')}
          color="#A1DF76"
        >
          <DmaStep
            title={t('esg:dma.stages.informationOverview.steps.1')}
            videoComponent={
              <ImageSlider images={generateImageUrls(language, 3, 4)} />
            }
          />
          <DmaStep
            title={t('esg:dma.stages.informationOverview.steps.2')}
            navigateTo="../../questionnaires/BP-1"
          />
          <DmaStep
            title={t('esg:dma.stages.informationOverview.steps.3')}
            navigateTo="../../questionnaires/GOV-1"
          />
          <DmaStep
            title={t('esg:dma.stages.informationOverview.steps.4')}
            navigateTo="../../questionnaires/GOV-2"
          />
          <DmaStep
            title={t('esg:dma.stages.informationOverview.steps.5')}
            navigateTo="../../questionnaires/GOV-3"
          />
          <DmaStep
            title={t('esg:dma.stages.informationOverview.steps.6')}
            navigateTo="../../questionnaires/GOV-5"
          />
          <DmaStep
            title={t('esg:dma.stages.informationOverview.steps.7')}
            navigateTo="../../questionnaires/Other-Info"
          />
        </DmaStage>
        <br />
        <DmaStage
          title={t(
            'esg:dma.stages.preliminaryAssessmentOfImpactSignificance.title'
          )}
        >
          <DmaStep
            title={t(
              'esg:dma.stages.preliminaryAssessmentOfImpactSignificance.steps.1'
            )}
            videoComponent={
              <ImageSlider images={generateImageUrls(language, 4, 9)} />
            }
          />
          <DmaStep
            title={t(
              'esg:dma.stages.preliminaryAssessmentOfImpactSignificance.steps.3'
            )}
            navigateToComponent={
              <LightTooltip
                title={t('esg:dma.step.step2TopicEvaluationButton.toggle')}
              >
                <Link to="./step-2/topic-evaluation">
                  <IconButton>
                    <FactCheckIcon />
                  </IconButton>
                </Link>
              </LightTooltip>
            }
          />
        </DmaStage>
        <br />
        <DmaStage
          title={t(
            'esg:dma.stages.preliminaryAssessmentOfFinancialMateriality.title'
          )}
        >
          <DmaStep
            title={t(
              'esg:dma.stages.preliminaryAssessmentOfFinancialMateriality.steps.1'
            )}
            videoComponent={
              <ImageSlider images={generateImageUrls(language, 5, 10)} />
            }
          />
          <DmaStep
            title={t(
              'esg:dma.stages.preliminaryAssessmentOfFinancialMateriality.steps.3'
            )}
            navigateToComponent={
              <LightTooltip
                title={t('esg:dma.step.step3TopicEvaluationButton.toggle')}
              >
                <Link to="./step-3/topic-evaluation">
                  <IconButton>
                    <FactCheckIcon />
                  </IconButton>
                </Link>
              </LightTooltip>
            }
          />
        </DmaStage>
        <br />
        <DmaStage title={t('esg:dma.stages.interestedParties.title')}>
          <DmaStep
            title={t('esg:dma.stages.interestedParties.steps.1')}
            videoComponent={
              <ImageSlider images={generateImageUrls(language, 6, 7)} />
            }
          />
          <DmaStep
            title={t('esg:dma.stages.interestedParties.steps.2')}
            navigateTo="../../questionnaires/SBM-1"
          />
          <DmaStep
            title={t('esg:dma.stages.interestedParties.steps.3')}
            navigateTo="../../questionnaires/SBM-2"
          />
          <DmaStep
            title={t('esg:dma.stages.interestedParties.steps.4')}
            navigateToComponent={
              <LightTooltip title={t('esg:dma.step.stakeholdersButton.toggle')}>
                <Link to="./stakeholders/selecting-stakeholders">
                  <IconButton>
                    <GroupsIcon />
                  </IconButton>
                </Link>
              </LightTooltip>
            }
          />
          <DmaStep
            title={t('esg:dma.stages.interestedParties.steps.5')}
            navigateToComponent={
              <LightTooltip title={t('esg:dma.step.analyticsButton.toggle')}>
                <Link to="./stakeholders/analytics/dashboard">
                  <IconButton>
                    <AnalyticsIcon />
                  </IconButton>
                </Link>
              </LightTooltip>
            }
          />
        </DmaStage>
      </DmaPanel>
      <br />
      <DmaPanel title={t('esg:dma.panels.doubleMaterialityAssessment')}>
        <DmaStage
          title={t('esg:dma.stages.finalAssesmentOfImpactSignificance.title')}
        >
          <DmaStep
            title={t(
              'esg:dma.stages.finalAssesmentOfImpactSignificance.steps.1'
            )}
            videoComponent={
              <ImageSlider images={generateImageUrls(language, 7, 9)} />
            }
          />
          <DmaStep
            title={t(
              'esg:dma.stages.finalAssesmentOfImpactSignificance.steps.4'
            )}
            navigateToComponent={
              <LightTooltip
                title={t('esg:dma.step.step5TopicEvaluationButton.toggle')}
              >
                <Link to="./step-5/topic-evaluation">
                  <IconButton>
                    <FactCheckIcon />
                  </IconButton>
                </Link>
              </LightTooltip>
            }
          />
        </DmaStage>
        <br />
        <DmaStage
          title={t('esg:dma.stages.finalAssesmentOfFinancialMateriality.title')}
        >
          <DmaStep
            title={t(
              'esg:dma.stages.finalAssesmentOfFinancialMateriality.steps.1'
            )}
            videoComponent={
              <ImageSlider images={generateImageUrls(language, 8, 10)} />
            }
          />
          <DmaStep
            title={t(
              'esg:dma.stages.finalAssesmentOfFinancialMateriality.steps.4'
            )}
            navigateToComponent={
              <LightTooltip
                title={t('esg:dma.step.step6TopicEvaluationButton.toggle')}
              >
                <Link to="./step-6/topic-evaluation">
                  <IconButton>
                    <FactCheckIcon />
                  </IconButton>
                </Link>
              </LightTooltip>
            }
          />
        </DmaStage>
        <br />
        <DmaStage title={t('esg:dma.stages.dualRelevanceMatrix.title')}>
          <DmaStep
            title={t('esg:dma.stages.dualRelevanceMatrix.steps.1')}
            videoComponent={
              <ImageSlider images={generateImageUrls(language, 9, 2)} />
            }
          />
          <DmaStep
            title={t('esg:dma.stages.dualRelevanceMatrix.steps.2')}
            navigateToComponent={
              <LightTooltip title={t('esg:dma.step.analyticsButton.toggle')}>
                <Link to="matrix">
                  <IconButton>
                    <AnalyticsIcon />
                  </IconButton>
                </Link>
              </LightTooltip>
            }
          />
          <DmaStep
            title={t('esg:dma.stages.dualRelevanceMatrix.steps.3')}
            navigateTo="../../questionnaires/BP-2"
          />
          <DmaStep
            title={t('esg:dma.stages.dualRelevanceMatrix.steps.4')}
            navigateTo="../../questionnaires/GOV-4"
          />
          <DmaStep
            title={t('esg:dma.stages.dualRelevanceMatrix.steps.5')}
            navigateTo="../../questionnaires/IRO-1"
          />
          <DmaStep
            title={t('esg:dma.stages.dualRelevanceMatrix.steps.6')}
            navigateTo="../../questionnaires/IRO-2"
          />
          <DmaStep
            title={t('esg:dma.stages.dualRelevanceMatrix.steps.7')}
            navigateTo="../../questionnaires/SBM-3"
          />
        </DmaStage>
        <br />
        <DmaStage title={t('esg:dma.stages.selfAssessment.title')}>
          <DmaStep
            title={t('esg:dma.stages.selfAssessment.steps.1')}
            videoComponent={
              <ImageSlider images={generateImageUrls(language, 10, 2)} />
            }
          />
          <DmaStep
            title={t('esg:dma.stages.selfAssessment.steps.2')}
            navigateTo="../../questionnaires/Other-SA"
          />
        </DmaStage>
      </DmaPanel>
    </>
  );
}
