import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { AddCircleOutlineOutlined as AddCircleOutlineOutlinedIcon } from '@mui/icons-material';
import InviteSupplierModal from '../components/InviteSupplierModal';
import { useSupplierSurvey } from '../hooks';
import { toast } from 'react-toastify';
import ConfirmationModal from 'src/components/ConfirmationModal';
import ProductPeriodModal from '../components/ProductPeriodModal';
import { GhgSupplierSurvey } from '../../interfaces';
import { format } from 'date-fns';
import { useGhgOrganization } from '../../../common/hooks';
import TableHeader from 'src/Ghg/components/TableV2/TableHeader';
import ButtonsCell from 'src/Ghg/components/TableV2/ButtonsCell';
import themeColors from 'src/theme/colors';
import { StyledPanel } from 'src/components/StyledPanel';
import { StyledTableV2Cell } from 'src/Ghg/components/TableV2/styles';
import PreviewDialog from './PreviewDialog';

const T_KEY = 'ghg:scope3GoodsAndServices.productsFromSuppliers';
const dateFormat = 'yyyy-MM-dd';

export default function ProductsFromSuppliers() {
  const { t } = useTranslation();

  const [deleteInvitation, setDeleteInvitation] =
    React.useState<GhgSupplierSurvey | null>(null);

  const [addReportSurvey, setAddReportSurvey] =
    React.useState<GhgSupplierSurvey | null>(null);

  const organization = useGhgOrganization();

  const [newInvitation, setNewInvitation] = React.useState<boolean>(false);
  const [editInvitation, setEditInvitation] =
    React.useState<GhgSupplierSurvey | null>(null);

  const [previewAnswerId, setPreviewAnswerId] = React.useState<number>();
  const [previewOpen, setPreviewOpen] = React.useState<boolean>(false);

  const { data, remove } = useSupplierSurvey(organization.id);

  const handleSendAgainClick = (invitation: GhgSupplierSurvey | null) =>
    setEditInvitation(invitation);

  const handleDeleteClick = (invitation: GhgSupplierSurvey) =>
    setDeleteInvitation(invitation);

  const handleDeleteInvitation = () =>
    remove.mutateAsync(deleteInvitation?.id as number).then(() => {
      toast.success(
        t('ghg:scope3GoodsAndServices.toasts.invitationDeleted') as string
      );
      setDeleteInvitation(null);
    });

  const formatDate = (date: string) => {
    return format(new Date(date), dateFormat);
  };

  const formatSentAt = (sentAt: string) => {
    return `${t(`${T_KEY}.table.sent`)}: ${formatDate(sentAt)}`;
  };

  const formatSubmittedAt = (submittedAt: string | null | undefined) => {
    if (submittedAt) {
      return formatDate(submittedAt);
    }
    return t(`${T_KEY}.table.noResponse`);
  };

  return (
    <div style={{ minHeight: '500px', minWidth: '900px' }}>
      <Typography variant="h2" sx={{ mt: '32px', mb: '32px' }}>
        {t(`${T_KEY}.title`)}
      </Typography>

      <StyledPanel>
        <TableContainer>
          <Table>
            <TableHeader
              headerKeys={[
                'productName.any',
                'supplierName',
                'reportingPeriods',
                'suppliersResponse',
              ]}
            />
            <TableBody>
              {(data || []).map((row) => (
                <TableRow key={row.id}>
                  <StyledTableV2Cell number={row.id}>
                    <Typography
                      variant="captionText"
                      color={themeColors.ghgDarkGrey}
                    >
                      {formatSentAt(row.created_at)}
                    </Typography>
                    <Typography>{row.product_name}</Typography>
                  </StyledTableV2Cell>
                  <StyledTableV2Cell number={row.id}>
                    <Typography>{row.supplier_name}</Typography>
                  </StyledTableV2Cell>
                  <StyledTableV2Cell number={row.id}>
                    {row.answer?.goods.map((rp) => (
                      <Typography key={rp.id}>{rp.reporting_period}</Typography>
                    ))}
                  </StyledTableV2Cell>
                  <StyledTableV2Cell number={row.id}>
                    <Typography>
                      {formatSubmittedAt(row.answer?.submitted_at)}
                    </Typography>
                  </StyledTableV2Cell>
                  <ButtonsCell
                    id={row.id}
                    onDelete={() => handleDeleteClick(row)}
                    onSendAgain={() => handleSendAgainClick(row)}
                    onAddToReportingPeriod={
                      row.answer?.is_submitted
                        ? () => setAddReportSurvey(row)
                        : null
                    }
                    onPreview={
                      row.answer?.is_submitted
                        ? () => {
                            setPreviewAnswerId(row.id);
                            setPreviewOpen(true);
                          }
                        : null
                    }
                  />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledPanel>

      <Button
        variant="contained"
        sx={{ mt: '25px' }}
        startIcon={
          <AddCircleOutlineOutlinedIcon sx={{ transform: 'scale(1.2)' }} />
        }
        onClick={() => setNewInvitation(true)}
      >
        {t(`goods-and-services.question1.method2.button`)}
      </Button>
      <InviteSupplierModal
        key={editInvitation?.id}
        open={!!newInvitation || !!editInvitation}
        onClose={() => {
          setNewInvitation(false);
          setEditInvitation(null);
        }}
        invitation={editInvitation}
      />

      <ProductPeriodModal
        surveyAnswerId={addReportSurvey?.answer?.id as number}
        open={!!addReportSurvey}
        onClose={() => setAddReportSurvey(null)}
      />

      <ConfirmationModal
        open={!!deleteInvitation}
        onClose={() => setDeleteInvitation(null)}
        onFalse={() => setDeleteInvitation(null)}
        onTrue={handleDeleteInvitation}
        titleKey={`${T_KEY}.table.deleteConfirmation.title`}
        textKey={`${T_KEY}.table.deleteConfirmation.text`}
      />

      <PreviewDialog
        id={previewAnswerId}
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
      />
    </div>
  );
}
