import { throttle } from 'lodash';
import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ToastProvider() {
  const [isAtTop, setIsAtTop] = useState(true);

  useEffect(() => {
    // throttling (200ms) applied to reduce browser workload during frequent scroll events
    const handleScroll = throttle(() => setIsAtTop(window.scrollY <= 40), 200);

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <style>
        {`.Toastify__toast{
          border-radius: 16px; 
          color: #38414F;
          font-weight: 600;
          font-size: 14px;
          letter-spacing: 0.36px; 
        }`}
      </style>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{
          marginTop: isAtTop ? '60px' : '0px',
        }}
      />
    </div>
  );
}
