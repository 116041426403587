import React from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Badge, IconButton, Tabs } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import { StyledContainer, StyledTitle, StyledTab } from '../../styles';
import { useEsgStakeholderAnalytics } from './StakeholdersAnalytics/hooks';
import { useEsgOrganization } from 'src/common/hooks';
import { useEsgReports } from 'src/Esg/hooks';
import { useEsgDmas } from '../hooks';

const getSelectedTab = () => {
  const url = window.location.href;
  const regex = /\/(selecting-stakeholders|analytics|quality-answers)/;
  const match = url.match(regex);
  if (match) {
    return match[1];
  } else {
    return 'selecting-stakeholders';
  }
};

export default function SelectingStakeHolders() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { reportId } = useParams();
  const { id: organizationId } = useEsgOrganization();
  const { _instance: report } = useEsgReports({ id: Number(reportId) });
  const { _instance: dma } = useEsgDmas(
    { id: report?.esg_dma_id },
    { enabled: !!report }
  );

  const { dataCount } = useEsgStakeholderAnalytics(
    organizationId,
    reportId as string
  );

  const tab = getSelectedTab();

  if (!dma) return null;

  const handleTabChange = (_: any, _tab: string) => {
    const base = `/esg/reports/${reportId}/dma/v${dma.version}/stakeholders/${_tab}`;
    switch (_tab) {
      case 'analytics':
        return navigate(`${base}/dashboard`);
      default:
        return navigate(base);
    }
  };

  const answerBadge =
    dataCount > 0 ? (
      <Badge badgeContent={dataCount} color="primary" sx={{ pr: '10px' }}>
        {t('esg:dma.stakeholderTabs.analytics')}
      </Badge>
    ) : (
      t('esg:dma.stakeholderTabs.analytics')
    );

  return (
    <StyledContainer>
      <StyledTitle>
        <IconButton
          onClick={() =>
            navigate(`/esg/reports/${reportId}/dma/v${dma.version}`)
          }
        >
          <KeyboardArrowLeftIcon fontSize="large" sx={{ color: '#38414f' }} />
        </IconButton>
        {t('esg:dma.selectingStakeholders.title')}
      </StyledTitle>
      <Tabs sx={{ mt: '20px' }} value={tab} onChange={handleTabChange}>
        <StyledTab
          label={t('esg:dma.stakeholderTabs.selectingStakeholders')}
          id="selecting-stakeholders"
          value="selecting-stakeholders"
        />
        <StyledTab label={answerBadge} id="analytics" value="analytics" />
        <StyledTab
          label="Quality Answers"
          id="quality-answers"
          value="quality-answers"
        />
      </Tabs>
      <Outlet />
    </StyledContainer>
  );
}
