import React, { useContext, useEffect } from 'react';
import { StyledPanel, StyledTitle } from '../../index.styles';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { client } from 'src/utils/api-client';
import Autocomplete from 'src/components/Autocomplete';
import { OrganizationOutFormSchema, UnitTypeSchema } from '../../index.types';
import { UnitFormValidationContext } from './unit-utils';
import { mapError } from '../../../utils/validation';

type UnitType = {
  id: string;
  name: string;
};

export type OrganizationalStructureState = {
  groupId?: number;
  setGroupId: (
    v: number | undefined
  ) =>
    | void
    | Promise<void>
    | React.Dispatch<React.SetStateAction<number | undefined>>;
  unitType: UnitTypeSchema | null;
  setUnitType: React.Dispatch<React.SetStateAction<UnitTypeSchema | null>>;
};

export const useOrganizationalStructure = (): OrganizationalStructureState => {
  const [unitType, setUnitType] = React.useState<UnitTypeSchema | null>(null);
  const [groupId, setGroupId] = React.useState<number | undefined>(undefined);
  return { unitType, setUnitType, groupId, setGroupId };
};

type Props = OrganizationalStructureState & {
  orgId?: string;
};

export default function OrganizationalStructure(props: Props) {
  const { t } = useTranslation();
  const { groupId, setGroupId, unitType, setUnitType } = props;

  const { data: groups } = useQuery('organization-groups', () =>
    client
      .get<
        OrganizationOutFormSchema[]
      >(`web/settings_v2/organizations?exclude_org_id=${props.orgId || 0}&can_edit=true`)
      .then((res) => res?.data || [])
  );
  const { data: types } = useQuery<UnitType[]>('organization-types', () =>
    client.get('web/settings_v2/unit-types').then((res) => res?.data || [])
  );
  // unfortunate, but Autocomplete supports only strings
  const groupIdStr = groupId == null ? null : groupId.toString();
  const errors = useContext(UnitFormValidationContext);

  // the selection can only be hidden
  // if the organization already exists (props.orgId is set) AND there are no groups to choose from
  const hideGroupChoice = !!props.orgId && groups?.length === 0;

  useEffect(() => {
    if (groups === undefined) return;
    // if the groupId coming from backend is inaccessible to this user
    // we just reset it and omit it from the form
    if (!!groupId && !groups.some((x) => x.id === groupId))
      setGroupId(undefined);
  }, [groupId, groups]);

  return (
    <div>
      <StyledTitle sx={{ fontSize: '28px', mt: '40px' }}>
        {t('superadmin:components.panels.unit.organizationalStructure.title')}
      </StyledTitle>

      <StyledPanel sx={{ mt: '32px', p: '32px' }}>
        {!hideGroupChoice && (
          <Autocomplete
            label={t(
              'superadmin:components.panels.unit.organizationalStructure.group.label'
            )}
            placeholder={t(
              'superadmin:components.panels.unit.organizationalStructure.group.placeholder'
            )}
            options={groups?.map((org) => ({
              value: org.id.toString(),
              label: org.name,
            }))}
            value={groupIdStr}
            onChange={(val) => setGroupId(val ? +val : undefined)}
            sx={{ mb: '24px' }}
            errorText={mapError(errors, 'parent_id', groupIdStr)}
          />
        )}

        <Autocomplete
          label={t(
            'superadmin:components.panels.unit.organizationalStructure.type.label'
          )}
          placeholder={t(
            'superadmin:components.panels.unit.organizationalStructure.type.placeholder'
          )}
          options={types?.map((type) => ({ value: type.id, label: type.name }))}
          value={unitType?.id ?? null}
          onChange={(x) => setUnitType({ id: x! })}
          sx={{ mb: '24px' }}
          errorText={mapError(errors, 'unit_type', unitType?.id ?? null)}
        />
      </StyledPanel>
    </div>
  );
}
