import { t } from 'i18next';

import {
  EsgDmaImpactOutSchema,
  EsgDmaRiskOpportunityOutSchema,
} from 'src/Esg/Dmav2/types';

// eslint-disable-next-line import/prefer-default-export
export const parseImpactOptions = (
  riskOpportunity: EsgDmaRiskOpportunityOutSchema,
  impacts: EsgDmaImpactOutSchema[]
) => {
  const nullValue = {
    value: '',
    label: t(`esg:dma.step3.tableV2.category.NONE`),
  };

  const impactOptions: { value: number | string; label: string }[] = impacts
    .filter(
      (i) =>
        // changing the riskopportunity impact is possible only within the same subsubtopic
        i.esg_dma_sub_sub_topic_id === riskOpportunity.esg_dma_sub_sub_topic_id
    )
    .map((i) => ({ value: i.id, label: i.name }));

  return [nullValue, ...impactOptions];
};
