import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useActors } from '../hooks';
import { Box, IconButton, Typography } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { StyledFlex } from '../../components/StyledFlex';
import ActorForm from './Form';
import { useTranslation } from 'react-i18next';

export default function Actor() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation(undefined, {
    keyPrefix: 'cbam:actors',
  });
  const { data } = useActors();
  const actorId = Number(id);
  const actor = data.find((actor) => actor.id === actorId);
  return (
    <Box>
      <StyledFlex sx={{ justifyContent: 'flex-start' }}>
        <IconButton onClick={() => navigate('..')}>
          <ChevronLeft />
        </IconButton>
        <Typography variant="h2">
          {t('actor')}: {actor?.name || t('new')}
        </Typography>
      </StyledFlex>
      <br />
      <ActorForm
        key={data.length}
        id={actorId}
        onSaved={() => navigate('..')}
      />
    </Box>
  );
}
