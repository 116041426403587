import { t } from 'i18next';
import { TFunction } from 'react-i18next';
import {
  EsgDmaImpactOutSchema,
  EsgDmaRiskOpportunityOutSchema,
  MatterDataSchema,
  Stage23HierarchySchema,
} from 'src/Esg/Dmav2/types';

export const scaleOptions = (t: TFunction) => [
  { value: 0, label: t('scale.0'), selectedLabel: t('scale.selected_0') },
  { value: 1, label: t('scale.1'), selectedLabel: t('scale.selected_1') },
  { value: 2, label: t('scale.2'), selectedLabel: t('scale.selected_2') },
  { value: 3, label: t('scale.3'), selectedLabel: t('scale.selected_3') },
  {
    value: null,
    label: t('scale.null'),
    selectedLabel: t('scale.selected_null'),
  },
];

export const scopeOptions = (t: TFunction) => [
  { value: 0, label: t('scope.0'), selectedLabel: t('scope.selected_0') },
  { value: 1, label: t('scope.1'), selectedLabel: t('scope.selected_1') },
  { value: 2, label: t('scope.2'), selectedLabel: t('scope.selected_2') },
  { value: 3, label: t('scope.3'), selectedLabel: t('scope.selected_3') },
  {
    value: null,
    label: t('scope.null'),
    selectedLabel: t('scope.selected_null'),
  },
];

export const probabilityOptions = (t: TFunction) => [
  {
    value: 0,
    label: t('probability.0'),
    selectedLabel: t('probability.selected_0'),
  },
  {
    value: 1,
    label: t('probability.1'),
    selectedLabel: t('probability.selected_1'),
  },
  {
    value: 2,
    label: t('probability.2'),
    selectedLabel: t('probability.selected_2'),
  },
  {
    value: 3,
    label: t('probability.3'),
    selectedLabel: t('probability.selected_3'),
  },
  {
    value: null,
    label: t('probability.null'),
    selectedLabel: t('probability.selected_null'),
  },
];

export const irreversibleOptions = (t: TFunction) => [
  {
    value: 1,
    label: t('irreversible.1'),
    selectedLabel: t('irreversible.selected_1'),
  },
  {
    value: 2,
    label: t('irreversible.2'),
    selectedLabel: t('irreversible.selected_2'),
  },
  {
    value: 3,
    label: t('irreversible.3'),
    selectedLabel: t('irreversible.selected_3'),
  },
  {
    value: null,
    label: t('irreversible.null'),
    selectedLabel: t('irreversible.selected_null'),
  },
];

export const financialScaleOptions = (t: TFunction) => [
  {
    value: 0,
    label: t('financialScale.0'),
    selectedLabel: t('financialScale.selected_0'),
  },
  {
    value: 1,
    label: t('financialScale.1'),
    selectedLabel: t('financialScale.selected_1'),
  },
  {
    value: 2,
    label: t('financialScale.2'),
    selectedLabel: t('financialScale.selected_2'),
  },
  {
    value: 3,
    label: t('financialScale.3'),
    selectedLabel: t('financialScale.selected_3'),
  },
  {
    value: null,
    label: t('financialScale.null'),
    selectedLabel: t('financialScale.selected_null'),
  },
];

export const formatScore = (value?: number) => Number(value).toFixed(2) ?? '-';

export const prepareData = (
  stage23hierarchy: Stage23HierarchySchema,
  impacts: EsgDmaImpactOutSchema[],
  riskOpportunities: EsgDmaRiskOpportunityOutSchema[]
): MatterDataSchema[] => {
  const subtitles = [
    ...stage23hierarchy.subtopics.flatMap(({ subsubtopics, description }) =>
      subsubtopics.map(({ id: subSubTopicId }) => ({
        subSubTopicId,
        title: description,
      }))
    ),
    ...stage23hierarchy.subsubtopics.map(
      ({ id: subSubTopicId, description }) => ({
        subSubTopicId,
        title: description,
      })
    ),
  ];

  const results: MatterDataSchema[] = [];

  subtitles.forEach(({ subSubTopicId, title }) => {
    const subsubTopicImpacts = impacts.filter(
      (i) => i.esg_dma_sub_sub_topic_id === subSubTopicId
    );
    const standaloneRiskOpportunities = riskOpportunities.filter(
      (r) =>
        r.esg_dma_sub_sub_topic_id === subSubTopicId && !r.esg_dma_impact_id
    );

    // for each impact of the subsubtopic
    for (const impact of subsubTopicImpacts) {
      // collecting impacts riskOpportunities
      const impactsRiskOpportunities = riskOpportunities.filter(
        (r) => r.esg_dma_impact_id === impact.id
      );
      // register if is a standalone impact
      if (impactsRiskOpportunities.length === 0)
        results.push({ subSubTopicId, title, impact, riskOpportunity: null });
      // else register each impact and riskOpportunity
      else
        impactsRiskOpportunities.forEach((riskOpportunity) => {
          results.push({ subSubTopicId, title, impact, riskOpportunity });
        });
    }
    //  register for each standalone riskOpportunity
    standaloneRiskOpportunities.forEach((riskOpportunity) => {
      results.push({ subSubTopicId, title, impact: null, riskOpportunity });
    });
  });
  return results;
};

export const prepareTitleDescriptionTooltip = (
  impact: EsgDmaImpactOutSchema | null,
  riskOpportunity: EsgDmaRiskOpportunityOutSchema | null
): string => {
  const info = [];
  if (impact?.description) {
    info.push(`${t('esg:dma.step5TableV2.impactDescription')}:`);
    info.push(impact.description);
  }
  if (impact?.description && riskOpportunity?.description) info.push('');
  if (riskOpportunity?.description) {
    info.push(`${t('esg:dma.step5TableV2.riskOpportunityDescription')}:`);
    info.push(riskOpportunity.description);
  }
  return info.join('\n');
};
