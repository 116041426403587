import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { client } from 'src/utils/api-client';
import TabGroup, { Tab } from '../../../components/TabGroup';
import Title from '../../../components/Title';
import { useGhgOrganization } from 'src/common/hooks';
import { getTokenAPI } from '../../../utils';
import {
  getFirstDateOfPeriod,
  timeConverter,
} from '../../../utils/timeConverter';
import Box from '../../components/Box';
import GraphComponent from '../../components/Dashboard/GraphComponent';
import Icon from '../../components/Icon';
import {
  IntuitiveCalendarCollapsable,
  IntuitiveCalendarPeriod,
} from '../../components/IntuitiveCalendar';
import QuestionnaireTitle from '../../components/Questionnaire/Title';
import { Row } from '../Dashboard/index.styles';
import {
  PageContainer,
  SectionGroupContainer,
} from '../Questionnaire/index.styles';
import {
  ParticipationBodyCell,
  ParticipationHead,
  ParticipationHeadCell,
  ParticipationTable,
  StyledInput,
} from './index.styles';
import styles from './styles.module.css';
import DataEntryCompletedCheckbox from 'src/Ghg/components/DataEntryCompletedCheckbox';
import { GhgDataEntryCompletedType } from 'src/Ghg/components/DataEntryCompletedCheckbox/interfaces';

export default function EmployeesEmissions() {
  const organization = useGhgOrganization();
  const reportingPeriod = organization.co2.reporting_freq;

  const [apiKey, setApiKey] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [activeStartDate, setActiveStartDate] = useState<Date | null>(
    new Date(new Date().toDateString())
  );
  const [activeEndDate, setActiveEndDate] = useState<Date | null>(null);
  const [figuresDate, setFiguresDate] = useState<Date | null>(new Date());
  const [textValue, setTextValue] = useState('Loading...');
  const { t } = useTranslation();

  const figuresPeriod = timeConverter(figuresDate, reportingPeriod);

  const {
    data: figures,
    isLoading: isFiguresLoading,
    isError: isFiguresError,
  } = useQuery(
    [
      'ghg',
      'employee_survey_figures',
      { organizationId: organization.id, figuresPeriod },
    ],
    () =>
      client
        .get(
          `/web/v2/employee_survey_figures?period=${figuresPeriod}&organization_id=${organization.id}`
        )
        .then((res) => res.data)
  );

  const {
    surveys_percentage_filled: ESPercentageOfCompleted,
    business_travel_emissions: businessTravelEmissions,
    employee_emissions: employeesStats,
    employees_means_of_transport: employeesMeansOfTransport,
  } = figures || {};

  useEffect(() => {
    if (!!selectedDate && !!activeStartDate && reportingPeriod) {
      getTokenAPI(
        `${organization.id}`,
        timeConverter(selectedDate, reportingPeriod),
        format(activeStartDate, 'yyyy-MM-dd'),
        !!activeEndDate ? format(activeEndDate, 'yyyy-MM-dd') : undefined
      ).then((res) => {
        if (!res.token) throw new Error(res.detail[0].msg);
        setApiKey(res.token);
      });
    }
  }, [selectedDate, reportingPeriod, activeStartDate, activeEndDate]);

  useEffect(() => {
    if (apiKey)
      setTextValue(`${window.location.origin}/employee_form?api-key=${apiKey}`);
  }, [apiKey]);

  useEffect(() => {
    setTextValue(t('employees-emissions.loading'));
  }, []);

  return (
    <PageContainer>
      <SectionGroupContainer>
        <QuestionnaireTitle text={t('menu.employee-surveys')} />

        <div>
          <p className={styles.subtitle}>{t('employees-emissions.desc')}</p>
        </div>

        <ParticipationTable>
          <ParticipationHead>
            <ParticipationHeadCell>
              {t('employees-emissions.modal-info.text1')}
            </ParticipationHeadCell>
            <ParticipationHeadCell>
              {t('employees-emissions.modal-info.text2')} [%]
            </ParticipationHeadCell>
          </ParticipationHead>
          <div>
            <ParticipationBodyCell>
              <div>500+</div>
              <div>8%</div>
            </ParticipationBodyCell>
            <ParticipationBodyCell>
              <div>251-500</div>
              <div>10%</div>
            </ParticipationBodyCell>
            <ParticipationBodyCell>
              <div>51-250</div>
              <div>15%</div>
            </ParticipationBodyCell>
            <ParticipationBodyCell>
              <div>11-50</div>
              <div>20%</div>
            </ParticipationBodyCell>
            <ParticipationBodyCell>
              <div>1-10</div>
              <div>50%</div>
            </ParticipationBodyCell>
          </div>
        </ParticipationTable>

        <TabGroup>
          <Tab id="download-link" title="employees-emissions.download">
            <>
              <div style={{ marginBottom: '48px' }}>
                <Title
                  title={t('employees-emissions.select-period')}
                  description={null}
                />
                <IntuitiveCalendarPeriod
                  reportingPeriod={reportingPeriod}
                  selectedDate={getFirstDateOfPeriod(
                    selectedDate ? new Date(selectedDate) : new Date(),
                    reportingPeriod ?? 'M'
                  )}
                  setSelectedDate={setSelectedDate}
                />
              </div>
              <div style={{ marginBottom: '24px' }}>
                <Title
                  title={t('employees-emissions.select-active-period')}
                  description={null}
                />
                <div className={styles.inputWrapper}>
                  <IntuitiveCalendarCollapsable
                    startDate={activeStartDate}
                    setStartDate={setActiveStartDate}
                    endDate={activeEndDate}
                    setEndDate={setActiveEndDate}
                    calendarWidth={100}
                    minDate={new Date(new Date().toDateString())}
                    maxDate={null}
                  />
                </div>
              </div>

              <DataEntryCompletedCheckbox
                selectedDate={selectedDate}
                type={GhgDataEntryCompletedType.SCOPE_3_EMPLOYEE_SURVEY}
                sx={{ mt: '-24px' }}
              />
              <div>
                <div className={styles.labelWrapper}>
                  <p className={styles.label}>
                    <Icon name="link" alt="link icon" />
                    {t('employees-emissions.link-title')}
                  </p>
                </div>
                <div className={styles.inputWrapper}>
                  <StyledInput readOnly value={textValue} type="text" />
                  <button
                    onClick={() => navigator.clipboard.writeText(textValue)}
                    className={styles.button}
                    type="button"
                  >
                    <Icon name="copy" alt="copy icon" />{' '}
                    {t('employees-emissions.button')}
                  </button>
                </div>
              </div>
            </>
          </Tab>
          <Tab id="check-results" title="employees-emissions.check">
            <>
              <div style={{ marginBottom: '48px' }}>
                <Title
                  title={t('employees-emissions.select-dashboard-period')}
                  description={null}
                />
                <IntuitiveCalendarPeriod
                  reportingPeriod={reportingPeriod}
                  selectedDate={getFirstDateOfPeriod(
                    figuresDate ? new Date(figuresDate) : new Date(),
                    reportingPeriod ?? 'M'
                  )}
                  setSelectedDate={setFiguresDate}
                />
              </div>

              <Row>
                <Box text="employees-emissions.employees-percent">
                  <GraphComponent
                    plotData={ESPercentageOfCompleted?.value}
                    error={isFiguresError}
                    errorMsg={ESPercentageOfCompleted?.error_msg}
                    errorType={ESPercentageOfCompleted?.error_type}
                    loading={isFiguresLoading}
                  />
                </Box>

                <Box text="dashboard.means">
                  <GraphComponent
                    plotData={employeesMeansOfTransport?.value}
                    error={isFiguresError}
                    errorMsg={employeesMeansOfTransport?.error_msg}
                    errorType={employeesMeansOfTransport?.error_type}
                    loading={isFiguresLoading}
                  />
                </Box>
              </Row>

              <Row firstEl={40} secondEl={60}>
                <Box text="employees-emissions.commuting-and-business">
                  <GraphComponent
                    plotData={employeesStats?.value}
                    error={isFiguresError}
                    errorMsg={employeesStats?.error_msg}
                    errorType={employeesStats?.error_type}
                    loading={isFiguresLoading}
                  />
                </Box>

                <Box text="dashboard.employee-emissions">
                  <GraphComponent
                    plotData={businessTravelEmissions?.value}
                    error={isFiguresError}
                    errorMsg={businessTravelEmissions?.error_msg}
                    errorType={businessTravelEmissions?.error_type}
                    loading={isFiguresLoading}
                  />
                </Box>
              </Row>
            </>
          </Tab>
        </TabGroup>
      </SectionGroupContainer>
    </PageContainer>
  );
}
