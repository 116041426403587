import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { client } from 'src/utils/api-client';
import { getFlatData } from './utils';

import { StyledPanel, StyledTitle } from 'src/Superadmin/index.styles';
import { StyledFlex } from 'src/components/StyledFlex';
import StructureTable from './StructureTable';
import { ContentProps } from '../index.types';
import { usePlatform } from 'src/redux-file/hooks';
import MaxOrgReachedAlert from './MaxOrgReachedAlert';

// eslint-disable-next-line no-unused-vars
export default function Structure(props: ContentProps) {
  const { t } = useTranslation();
  const { organizations, platformSubscriber } = usePlatform();
  const isMaxOrgReached =
    (organizations || []).length >= platformSubscriber.maxNumOrganizations;

  const { data } = useQuery('organization-structure', () =>
    client
      .get('/web/settings_v2/organizations/tree')
      .then((res) => res.data?.nodes || [])
  );

  const flatData = getFlatData(data || []);

  return (
    <div>
      <StyledFlex>
        <StyledTitle sx={{ fontSize: '28px' }}>
          {t('superadmin:settings.tabs.structure.title')}
        </StyledTitle>
      </StyledFlex>
      <br />

      {isMaxOrgReached && <MaxOrgReachedAlert />}

      <StyledPanel sx={{ mt: '24px', minWidth: '1050px' }}>
        <StructureTable data={flatData} />
      </StyledPanel>
    </div>
  );
}
