import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';
import { StyledPanel } from 'src/Esg/styles';
import { useDmaHierarchy } from '../../hooks';
import TopicRow from './TopicRow';
import { useTranslation } from 'react-i18next';

export default function QualityAnswers() {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'esg:dma.qualityAnswers',
  });
  const { topics } = useDmaHierarchy();

  return (
    <StyledPanel sx={{ mt: '20px' }}>
      <TableContainer>
        <Table id="quality-answers-table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>{t('ESRS')}</TableCell>
              <TableCell colSpan={4}>{t('opinions')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('topic')}</TableCell>
              <TableCell>{t('subTopic')}</TableCell>
              <TableCell>{t('issueRaised')} </TableCell>
              <TableCell>{t('impactMateriality')}</TableCell>
              <TableCell>{t('financialMateriality')}</TableCell>
              <TableCell>{t('comment')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {topics.map((topic) => (
              <TopicRow key={topic.id} topic={topic} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledPanel>
  );
}
