import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import assets from '../assets';
import Container from '../components/Container';
import Title from '../components/Title';
import QuestionnaireTable from './Table';

import { useEsgStakeholderSurveyMetadata } from '../../hooks';

export default function StakeholderSurvey() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data: surveyMetaData } = useEsgStakeholderSurveyMetadata();
  const prevLink = {
    onClick: () => navigate(`../intro`),
    label: 'esg:stakeholderSurvey.back',
  };
  const nextLink = {
    onClick: () => navigate(`../end`),
    label: 'esg:stakeholderSurvey.next',
  };
  return (
    <Container
      header={<img src={assets.intro.image} />}
      prevLink={prevLink}
      nextLink={nextLink}
    >
      <Title
        title={t('esg:stakeholderSurvey.title')}
        period={surveyMetaData?.report_period || ''}
      />
      <Typography
        dangerouslySetInnerHTML={{
          __html: t('esg:stakeholderSurvey.description'),
        }}
      />
      <br />
      <Typography variant="h5">
        {t('esg:stakeholderSurvey.subtitle')}
      </Typography>
      <br />
      <QuestionnaireTable />
    </Container>
  );
}
