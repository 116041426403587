import React from 'react';
import { useTranslation } from 'react-i18next';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DangerousIcon from '@mui/icons-material/Dangerous';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import WbIncandescentIcon from '@mui/icons-material/WbIncandescent';
import Typography from '@mui/material/Typography';
import { StyledSectionIcon } from './index.styles';

export type SectionTypeKey = 'intro' | 'questions' | 'risks' | 'files';

function getIcon(sectionTypeKey: SectionTypeKey) {
  const sx = {
    height: '32px',
    width: '32px',
  };
  const icon = {
    intro: <WbIncandescentIcon sx={{ rotate: '180deg', ...sx }} />,
    questions: <FormatListBulletedIcon sx={sx} />,
    risks: <DangerousIcon sx={sx} />,
    files: <AttachFileIcon sx={sx} />,
  }[sectionTypeKey];

  return icon;
}

interface Props {
  sectionTypeKey: SectionTypeKey;
}

export default function IconAndSectionTitle(props: Props) {
  const { t } = useTranslation();
  const text = t(`esg:qv3_sections_labels.${props.sectionTypeKey}`, {
    defaultValue: null,
  });
  if (text === null) return null;

  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <Typography
        sx={{ fontSize: '28px', lineHeight: '38px' }}
        dangerouslySetInnerHTML={{ __html: text }}
      />
      <StyledSectionIcon>{getIcon(props.sectionTypeKey)}</StyledSectionIcon>
    </div>
  );
}
