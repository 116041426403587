import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { PageState } from '../../types';
import { RegisterQuestion, RegisterText } from './index.styles';

export default function CommonParagraph(props: {
  setPageState: React.Dispatch<React.SetStateAction<PageState>>;
  isClient: boolean | null;
  setIsClient: React.Dispatch<React.SetStateAction<boolean | null>>;
}) {
  const { setPageState, isClient, setIsClient } = props;
  const { t } = useTranslation();
  const isClientAsString = isClient?.toString() ?? null;

  return (
    <>
      <RegisterText>{t('registration_bnp:registerText')}</RegisterText>
      <RegisterQuestion>
        {t('registration_bnp:isClientQuestion')}
      </RegisterQuestion>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        value={isClientAsString}
        sx={{ ml: '10px' }}
      >
        <FormControlLabel
          control={<Radio />}
          value="true"
          label={t('yes')}
          onClick={() => {
            setPageState('registration-first-page');
            setIsClient(true);
          }}
        />
        <FormControlLabel
          control={<Radio />}
          value="false"
          label={t('no')}
          onClick={() => {
            setPageState('not-a-client');
            setIsClient(false);
          }}
        />
      </RadioGroup>
    </>
  );
}
