import { useMutation, useQueryClient } from 'react-query';
import { client } from 'src/utils/api-client';

export type LcaProductCopyInSchema = {
  name: string;
};

export const useProductActions = (productId: number) => {
  const queryClient = useQueryClient();
  const url = `/web/lca/products/${productId}`;
  const invalidateProducts = () => {
    queryClient.invalidateQueries({ queryKey: ['lca', 'products'] });
  };

  const copyProduct = useMutation({
    mutationFn: (data: LcaProductCopyInSchema) =>
      client.post(`${url}/copy`, data),
    onSuccess: invalidateProducts,
  });

  const deleteProduct = useMutation({
    mutationFn: () => client.delete(url),
    onSuccess: invalidateProducts,
  });

  const exportToExcel = useMutation({
    mutationFn: () =>
      client
        .get(`${url}/export-to-excel`, { responseType: 'blob' })
        .then((res) => {
          const contentDisposition = res.headers['content-disposition'];
          let filename = 'exported_file.xlsx'; // Default filename

          if (contentDisposition) {
            const match = contentDisposition.match(/filename="(.+?)"/);
            if (match?.[1]) [, filename] = match;
          }
          return { blob: res.data, filename };
        }),
    onSuccess: ({ blob, filename }) => {
      // Create a link element to download the file
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;

      // Set the filename (optional, but good for user experience)
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Clean up the URL object
      window.URL.revokeObjectURL(url);
    },
  });

  return { copyProduct, deleteProduct, exportToExcel };
};
