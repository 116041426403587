import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material';
import { UUID } from 'crypto';
import React from 'react';
import { useLanguage } from 'src/common/hooks';
import { useEsgDmas } from 'src/Esg/Dmav2/hooks';
import { DmaVersion } from 'src/Esg/Dmav2/types';

interface Props {
  value: UUID | DmaVersion | null;
  // eslint-disable-next-line no-unused-vars
  onDmaChange: (value: UUID | DmaVersion) => void;
}

export default function DmaSelect(props: Props & SelectProps) {
  const { t } = useLanguage({ keyPrefix: 'esg:dma' });
  const { _data: dmas } = useEsgDmas();
  const dmaOptions = dmas.map(({ id, name }) => ({ value: id, label: name }));
  return (
    <FormControl fullWidth>
      <InputLabel id="dma-select-label">
        {t('doubleMaterialityAssessment')}
      </InputLabel>
      <Select
        labelId="dma-select-label"
        label={t('doubleMaterialityAssessment')}
        onChange={(event) =>
          props.onDmaChange(event.target.value as UUID | DmaVersion)
        }
        {...props}
        value={props.value ?? DmaVersion.V1}
      >
        <MenuItem value={DmaVersion.V1}>{t('createNewDma')} (v1)</MenuItem>
        <MenuItem value={DmaVersion.V2}>{t('createNewDma')} (v2)</MenuItem>
        {dmaOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
