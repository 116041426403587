import { OrganizationalLogoOutSchema } from '../components/OrganizationalLogo/types';

export enum ReportingPeriod {
  YEARLY = 'Y',
  MONTHLY = 'M',
  QUARTERLY = 'Q',
}

export enum GOODS_AND_SERVICES_OR_CAPITAL_GOODS {
  // eslint-disable-next-line no-unused-vars
  GOODS_AND_SERVICES = 'goods-and-services',
  // eslint-disable-next-line no-unused-vars
  CAPITAL_GOODS = 'capital-goods',
}

export interface GhgSupplierSurveyAnswerInSchema {
  company_name: string;
  contact_email: string;
  valid_since: string;
  valid_until: string;
  emissions_known: boolean;
}

export interface GhgSupplierSurveyAnswerBaseSchema
  extends GhgSupplierSurveyAnswerInSchema {
  id: number;
  is_submitted: boolean;
  submitted_at: string | null;
  questionnaire_answer: string;
}

export interface GhgSupplierSurveyAnswerSchema
  extends GhgSupplierSurveyAnswerBaseSchema {
  goods: GhgSupplierGoodsBase[];
}

export interface GhgSupplierSurveyAnswerWithTokenSchema
  extends GhgSupplierSurveyAnswerSchema {
  qv3_token: string | null;
}

export interface GhgSupplierSurveyBase {
  id: number;
  created_at: string;
  co2_organization: number;
  product_name: string;
  supplier_name: string;
  access_token: string | null;
}

export interface GhgSupplierSurvey extends GhgSupplierSurveyBase {
  answer: GhgSupplierSurveyAnswerSchema | null;
}

export interface GhgSupplierGoodsBase {
  id: number;
  amount: number;
  reporting_period: string;
  type: GOODS_AND_SERVICES_OR_CAPITAL_GOODS;
  emission_kg_eq_co2_per_unit: number;
  co2_organization_id: number;
  survey_answer_id: number;
}

export interface GhgSupplierGoods extends GhgSupplierGoodsBase {
  survey_answer: GhgSupplierSurveyAnswerBaseSchema;
  survey: GhgSupplierSurveyBase;
}

export interface GhgSupplierSurveyWithTokenSchema extends GhgSupplierSurvey {
  answer: GhgSupplierSurveyAnswerWithTokenSchema | null;
  organization_name: string;
  organization_logo: OrganizationalLogoOutSchema | null;
}

export interface GhgDataEntryCompletedStatisticsSchema {
  scope_1_percent: number;
  scope_2_percent: number;
  scope_3_percent: number;
}
