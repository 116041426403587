import React from 'react';
import { Box, ListItem, ListItemText } from '@mui/material';

import { usePlatformNavigation } from 'src/utils/navigation';
import SearchInput from './SearchInput';
import OrganizationsCheckboxNode from './OrganizationsCheckboxNode';
import ActionButtons from './ActionButtons';
import { useTranslation } from 'react-i18next';

export default function CompanyFilter() {
  const { t } = useTranslation();
  const { productOrganizations } = usePlatformNavigation();
  const orgIds = productOrganizations.map((org) => org.id);
  const topOrganizations = productOrganizations.filter(
    (org) => !orgIds.includes(org.parent_id)
  );

  return (
    <>
      <ListItem>
        <ListItemText sx={{ p: '4px 8px' }}>
          {t('superdashboardGhg:sidebar.organizations.title')}
        </ListItemText>
      </ListItem>
      <Box sx={{ m: '8px 4px', mt: '0px' }}>
        <SearchInput />
        <ActionButtons />
      </Box>
      <Box
        sx={{
          height: 'calc(100% - 360px)',
          overflowY: 'scroll',
          marginTop: '8px',
        }}
      >
        {topOrganizations.map((org) => (
          <OrganizationsCheckboxNode key={org.id} organization={org} />
        ))}
      </Box>
    </>
  );
}
