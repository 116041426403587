import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import { StyledPanel, StyledTitle } from '../../index.styles';
import {
  StyledCell,
  StyledProductCellContainer,
  StyledRow,
  StyledTable,
} from '../StructureTable/index.styles';
import { OrganizationTreeNodeSchema } from '../StructureTable/index.types';

interface Props {
  organizations: OrganizationTreeNodeSchema[];
}

export default function GroupOrganizations(props: Props) {
  const { organizations } = props;
  const { t } = useTranslation();

  return (
    <div>
      <StyledTitle sx={{ fontSize: '28px', mt: '40px' }}>
        {t('superadmin:components.panels.unit.groupOrganizations.title')}
      </StyledTitle>

      <StyledPanel sx={{ mt: '32px', p: '32px' }}>
        <StyledTable>
          <StyledRow>
            <StyledCell
              type="header"
              w="60%"
              sx={{ borderLeftColor: '#38414f' }}
            >
              {t('superadmin:settings.tabs.structure.table.unitName')}
            </StyledCell>
            <StyledCell type="header" w="20%">
              {t('superadmin:settings.tabs.structure.table.unitType')}
            </StyledCell>
            <StyledCell type="header" w="20%">
              <StyledProductCellContainer>
                <span>GHG</span>
                <span>ESG</span>
                <span>LCA</span>
              </StyledProductCellContainer>
            </StyledCell>
          </StyledRow>

          {organizations.map((organization) => (
            <StyledRow key={organization.id}>
              <StyledCell type="unit" w="60%">
                {organization.name}
              </StyledCell>
              <StyledCell type="unit" w="20%">
                {organization.unit_type?.name}
              </StyledCell>
              <StyledCell type="unit" w="20%">
                <StyledProductCellContainer sx={{ color: '#5A9531' }}>
                  <span>
                    {organization.active_products?.includes('co2') && (
                      <CheckCircleSharpIcon />
                    )}
                  </span>
                  <span>
                    {organization.active_products?.includes('esg') && (
                      <CheckCircleSharpIcon />
                    )}
                  </span>
                  <span>
                    {organization.active_products?.includes('lca') && (
                      <CheckCircleSharpIcon />
                    )}
                  </span>
                </StyledProductCellContainer>
              </StyledCell>
            </StyledRow>
          ))}
        </StyledTable>
      </StyledPanel>
    </div>
  );
}
