import React, { useEffect, useState } from 'react';
import {
  PageContainer,
  Reminder,
  ChecksListWrapper,
  ChecksListItem,
  SectionGroupContainer,
  CheckListItemTextMain,
  CheckListItemText,
  Section,
  PreviewBox,
  StyledHeader,
  StyledTh,
  AddButton,
  FinalProductDescription,
  FinalProductTable,
  FinalProductTableHead,
  FinalProductTableRow,
} from './index.styles';
import QuestionnaireTitle from '../../components/Questionnaire/Title';
import { useTranslation } from 'react-i18next';
import Title from '../../../components/Title';
import {
  getSoldProductsData,
  setSelectedDate,
} from '../../../redux-file/questionnaires/thunks';
import { useAppDispatch, useAppSelector } from '../../../redux-file/hooks';
import { useGhgOrganization } from 'src/common/hooks';
import ButtonGroupField from '../../../components/ButtonGroupField';
import { useNavigate } from 'react-router-dom';
import SoldProductPreview from '../../components/Questionnaire/preview/soldProductsPreview';
import { SoldProductsInterface } from '../../../redux-file/questionnaires/interfaces';
import {
  timeConverter,
  getFirstDateOfPeriod,
} from '../../../utils/timeConverter';
import QuestionnaireSectionTitle from '../../components/QuestionnaireSectionTitle';
import { overview_sold_products_cloud } from './clouds_data';
import { IntuitiveCalendarPeriod } from '../../components/IntuitiveCalendar';
import DataEntryCompletedCheckbox from 'src/Ghg/components/DataEntryCompletedCheckbox';
import { GhgDataEntryCompletedType } from 'src/Ghg/components/DataEntryCompletedCheckbox/interfaces';

export const index_url = '/ghg/emissions/questionnairepage-sold-products';
export default function IndexSoldProducts() {
  type PageType =
    | 'index'
    | 'final-products'
    | 'indirect-products'
    | 'fuels-and-raw-materials';
  const { selectedDate, soldProducts } = useAppSelector(
    (state) => state.questionnaire
  );

  const organization = useGhgOrganization();
  const reportingPeriod = organization.co2.reporting_freq;

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [page, setPage] = useState<PageType>('index');
  const navigate = useNavigate();
  const [showSelect, setShowSelect] = useState(false);
  const [finalProductPage, setFinalProductPage] = useState('');

  const options = [
    ['final-products', t('sold-products.index.select.final-product')],
    ['indirect-products', t('sold-products.index.select.indirect-product')],
    [
      'fuels-and-raw-materials',
      t('sold-products.index.select.fuel-and-raw-materials'),
    ],
  ];

  const final_product_options = [
    ['direct', t('sold-products.final-products.options.direct')],
    ['indirect', t('sold-products.final-products.options.indirect')],
  ];

  const handleClick = (value: PageType) => {
    setPage(value);
  };

  const handleFinalProductClick = (value: string) => {
    setFinalProductPage(value);
  };

  useEffect(() => {
    if (selectedDate && reportingPeriod) {
      dispatch(
        getSoldProductsData({
          period: timeConverter(selectedDate, reportingPeriod),
        })
      );
    }
  }, [selectedDate, reportingPeriod]);

  useEffect(() => {
    if (page === 'fuels-and-raw-materials') {
      navigate(
        '/ghg/emissions/questionnairepage-sold-products/fuels-and-raw-materials'
      );
    }

    if (finalProductPage === 'direct') {
      navigate(
        '/ghg/emissions/questionnairepage-sold-products/final-products-direct'
      );
    }

    if (finalProductPage === 'indirect') {
      navigate(
        '/ghg/emissions/questionnairepage-sold-products/final-products-indirect'
      );
    }
  }, [page, finalProductPage]);

  return (
    <PageContainer>
      <SectionGroupContainer>
        <QuestionnaireTitle info text={t('menu.sold-products')} />
        <Reminder>{t('sold-products.index.reminder')}</Reminder>

        <ChecksListWrapper>
          <ChecksListItem>
            <svg
              width="24"
              height="19"
              viewBox="0 0 24 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.85492 17.2674C6.31412 18.7117 8.6713 18.7117 10.1305 17.2859L23.226 4.4534C24.2549 3.45347 24.2549 1.82395 23.2447 0.824019L23.1698 0.74995C22.1596 -0.249983 20.532 -0.249983 19.5218 0.74995L7.49271 12.6566L4.40593 9.6012C3.43313 8.63831 1.82426 8.60127 0.814039 9.56417C-0.252304 10.5456 -0.271012 12.1936 0.757915 13.2121L4.85492 17.2674Z"
                fill="#165D5C"
              />
            </svg>
            <div>
              <CheckListItemTextMain>
                {t('sold-products.index.check1.main')}
              </CheckListItemTextMain>
              <CheckListItemText>
                {t('sold-products.index.check1.text')}
              </CheckListItemText>
            </div>
          </ChecksListItem>
          <ChecksListItem>
            <svg
              width="24"
              height="19"
              viewBox="0 0 24 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.85492 17.2674C6.31412 18.7117 8.6713 18.7117 10.1305 17.2859L23.226 4.4534C24.2549 3.45347 24.2549 1.82395 23.2447 0.824019L23.1698 0.74995C22.1596 -0.249983 20.532 -0.249983 19.5218 0.74995L7.49271 12.6566L4.40593 9.6012C3.43313 8.63831 1.82426 8.60127 0.814039 9.56417C-0.252304 10.5456 -0.271012 12.1936 0.757915 13.2121L4.85492 17.2674Z"
                fill="#165D5C"
              />
            </svg>
            <div>
              <CheckListItemTextMain>
                {t('sold-products.index.check2.main')}
              </CheckListItemTextMain>
              <CheckListItemText>
                {t('sold-products.index.check2.text')}
              </CheckListItemText>
            </div>
          </ChecksListItem>
          <ChecksListItem>
            <svg
              width="24"
              height="19"
              viewBox="0 0 24 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.85492 17.2674C6.31412 18.7117 8.6713 18.7117 10.1305 17.2859L23.226 4.4534C24.2549 3.45347 24.2549 1.82395 23.2447 0.824019L23.1698 0.74995C22.1596 -0.249983 20.532 -0.249983 19.5218 0.74995L7.49271 12.6566L4.40593 9.6012C3.43313 8.63831 1.82426 8.60127 0.814039 9.56417C-0.252304 10.5456 -0.271012 12.1936 0.757915 13.2121L4.85492 17.2674Z"
                fill="#165D5C"
              />
            </svg>
            <div>
              <CheckListItemTextMain>
                {t('sold-products.index.check3.main')}
              </CheckListItemTextMain>
              <CheckListItemText>
                {t('sold-products.index.check3.text')}
              </CheckListItemText>
            </div>
          </ChecksListItem>
        </ChecksListWrapper>
        <Section>
          <Title title={t('forms.select-period')} description={null} />
          <IntuitiveCalendarPeriod
            reportingPeriod={reportingPeriod}
            selectedDate={getFirstDateOfPeriod(
              selectedDate ? new Date(selectedDate) : new Date(),
              reportingPeriod ?? 'M'
            )}
            setSelectedDate={(selDate: Date) =>
              dispatch(setSelectedDate(selDate))
            }
          />
        </Section>
        <DataEntryCompletedCheckbox
          selectedDate={selectedDate}
          type={GhgDataEntryCompletedType.SCOPE_3_SOLD_PRODUCTS}
          sx={{ mt: '0px' }}
        />
        {soldProducts.length !== 0 && (
          <Section>
            <QuestionnaireSectionTitle
              active={false}
              level="secondary"
              boxContent={overview_sold_products_cloud}
              text={t('sold-products.preview.title')}
            />

            <PreviewBox>
              <StyledHeader>
                <StyledTh width={34}>
                  {t('sold-products.preview.name')}
                </StyledTh>
                <StyledTh width={22}>
                  {t('sold-products.preview.product-type')}
                </StyledTh>
                <StyledTh width={22}>
                  {t('sold-products.preview.intensity')}
                </StyledTh>
                <StyledTh width={22}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('sold-products.preview.emission'),
                    }}
                  />
                </StyledTh>
                <StyledTh width={10} numberOfIcons="three">
                  {t('sold-products.preview.edit')}
                </StyledTh>
              </StyledHeader>
              {soldProducts.map((_: SoldProductsInterface, id: number) => (
                <SoldProductPreview key={id} id={id} state={soldProducts} />
              ))}
            </PreviewBox>
            {!showSelect && (
              <AddButton type="button" onClick={() => setShowSelect(true)}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_2433_10049)">
                    <path
                      d="M13 7H11V11H7V13H11V17H13V13H17V11H13V7ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2433_10049">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                {t('sold-products.buttons.add')}
              </AddButton>
            )}
          </Section>
        )}
        {(soldProducts.length === 0 || showSelect) && (
          <Section>
            <Title
              title={t('sold-products.index.select.title')}
              description={null}
            />
            <ButtonGroupField
              options={options}
              value={page ?? ''}
              setValue={(value: PageType) => handleClick(value)}
            />
          </Section>
        )}
        {page === 'final-products' && (
          <div>
            <FinalProductDescription>
              {t('sold-products.final-products.index-desc')}
            </FinalProductDescription>

            <FinalProductTable>
              <FinalProductTableHead columns="1fr 2fr 2fr">
                <div>{t('sold-products.final-products.table.head.col1')}</div>
                <div>{t('sold-products.final-products.table.head.col2')}</div>
                <div>{t('sold-products.final-products.table.head.col3')}</div>
              </FinalProductTableHead>

              <FinalProductTableRow columns="1fr 2fr 2fr">
                <div>{t('sold-products.final-products.table.row1.col1')}</div>
                <div>{t('sold-products.final-products.table.row1.col2')}</div>
                <div>{t('sold-products.final-products.table.row1.col3')}</div>
              </FinalProductTableRow>

              <FinalProductTableRow columns="1fr 2fr 2fr">
                <div>{t('sold-products.final-products.table.row2.col1')}</div>
                <div>{t('sold-products.final-products.table.row2.col2')}</div>
                <div>{t('sold-products.final-products.table.row2.col3')}</div>
              </FinalProductTableRow>
            </FinalProductTable>
            <Title
              title={t('sold-products.final-products.options.title')}
              description={null}
            />
            <ButtonGroupField
              options={final_product_options}
              value={finalProductPage ?? ''}
              setValue={(value: string) => handleFinalProductClick(value)}
            />
          </div>
        )}
      </SectionGroupContainer>
    </PageContainer>
  );
}
