import React from 'react';
import { useTranslation } from 'react-i18next';
import { RegistrationUBNPSchema } from '../../form';
import OneValueCheckboxField from 'src/components/CheckboxField';
import { CheckboxWrapper, ConsentsInformation } from './index.styles';

export default function Consents(props: {
  form: RegistrationUBNPSchema;
  setForm: React.Dispatch<React.SetStateAction<RegistrationUBNPSchema>>;
}) {
  const { form, setForm } = props;
  const { t } = useTranslation();

  return (
    <>
      <ConsentsInformation>
        {t('registration_bnp:consentsInformation')}
      </ConsentsInformation>
      {['bnp_analytics', 'bnp_representation'].map((field) => (
        <CheckboxWrapper key={field} sx={{ mt: '10px', mb: '10px' }}>
          <OneValueCheckboxField
            label=""
            value={form[field as keyof RegistrationUBNPSchema] as boolean}
            setValue={(value: boolean) => setForm({ ...form, [field]: value })}
          />
          <span
            dangerouslySetInnerHTML={{
              __html: t(`registration_bnp:form.${field}`),
            }}
            style={{
              fontSize: '12px',
              color: '#606462',
              lineHeight: '18px',
            }}
          />
        </CheckboxWrapper>
      ))}
      <span
        dangerouslySetInnerHTML={{
          __html: t('registration_bnp:dataProcessingInformation'),
        }}
        style={{
          fontSize: '8px',
          color: '#606462',
          lineHeight: '13px',
        }}
      />
    </>
  );
}
