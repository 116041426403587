import { Summary } from './Stakeholders/StakeholdersAnalytics/Dashboard/index.types';
import { Chart, Plugin } from 'chart.js';
import _ from 'lodash';

export const getNumericOptions = (t: any) => [
  { value: 0, selectedLabel: '0', label: t('esg:common.numericOptions.0') },
  { value: 1, selectedLabel: '1', label: t('esg:common.numericOptions.1') },
  { value: 2, selectedLabel: '2', label: t('esg:common.numericOptions.2') },
  { value: 3, selectedLabel: '3', label: t('esg:common.numericOptions.3') },
  { value: 4, selectedLabel: '4', label: t('esg:common.numericOptions.4') },
  { value: 5, selectedLabel: '5', label: t('esg:common.numericOptions.5') },
  {
    value: null,
    label: t('esg:common.numericOptions.none'),
  },
];

export const getStakeholderNatureOptions = (t: any) => [
  {
    value: 'affected_stakeholder',
    label: t('esg:common.stakeholderNatureOptions.affectedStakeholder'),
  },
  {
    value: 'user',
    label: t('esg:common.stakeholderNatureOptions.user'),
  },
  {
    value: 'affected_stakeholder_and_user',
    label: t('esg:common.stakeholderNatureOptions.affectedStakeholderAndUser'),
  },
  {
    value: 'not_applicable',
    label: t('esg:common.stakeholderNatureOptions.notApplicable'),
  },
  {
    value: null,
    label: t('esg:common.stakeholderNatureOptions.none'),
  },
];

export const getBooleanOptions = (t: any) => [
  {
    value: null,
    label: t('esg:common.none'),
  },
  {
    value: true,
    label: t('esg:common.yes'),
  },
  {
    value: false,
    label: t('esg:common.no'),
  },
];

export const getProbabilityNumericOptions = (t: any) => [
  {
    value: 1,
    label: t('esg:common.probabilityNumericOptions.small'),
  },
  {
    value: 3,
    label: t('esg:common.probabilityNumericOptions.medium'),
  },
  {
    value: 5,
    label: t('esg:common.probabilityNumericOptions.high'),
  },
  {
    value: null,
    label: t('esg:common.numericOptions.none'),
  },
];

export function adjustDataPoints(summaries: Summary[]): Summary[] {
  // Create a deep copy of the summaries array
  const adjustedSummaries = _.cloneDeep(summaries);

  // Use a map to track positions and count how many points share the same position
  const positionCount: { [position: string]: Summary[] } = {};

  // Group points by their positions
  adjustedSummaries.forEach((summary) => {
    const posKey = `${summary.calculated_positive_score},${summary.calculated_negative_score}`;
    if (!positionCount[posKey]) {
      positionCount[posKey] = [];
    }
    positionCount[posKey].push(summary);
  });

  // Adjust points that are at the exact same position
  Object.keys(positionCount).forEach((posKey) => {
    const points = positionCount[posKey];
    if (points.length > 1) {
      // More than one point at the same position, move them vertically
      points.forEach((point, index) => {
        // Increment the y-coordinate for each subsequent point
        point.calculated_negative_score += 0.01 * (index + 1);
      });
    }
  });

  const minDistance = 0.6;

  for (let i = 0; i < adjustedSummaries.length; i++) {
    for (let j = i + 1; j < adjustedSummaries.length; j++) {
      const dx =
        adjustedSummaries[j].calculated_positive_score -
        adjustedSummaries[i].calculated_positive_score;
      const dy =
        adjustedSummaries[j].calculated_negative_score -
        adjustedSummaries[i].calculated_negative_score;
      // Ensure the distance is at least 0.01 to avoid division by zero
      const distance = Math.max(Math.sqrt(dx * dx + dy * dy), 0.01);

      if (distance < minDistance) {
        // Calculate the required adjustment to reach the minimum distance.
        const overlap = minDistance - distance;
        const adjustX = (overlap / distance) * dx;
        const adjustY = (overlap / distance) * dy;

        // This maintains the relative positioning while ensuring minimal movement.
        adjustedSummaries[i].calculated_positive_score -= adjustX / 2;
        adjustedSummaries[i].calculated_negative_score -= adjustY / 2;
        adjustedSummaries[j].calculated_positive_score += adjustX / 2;
        adjustedSummaries[j].calculated_negative_score += adjustY / 2;
      }
    }
  }

  return adjustedSummaries;
}

interface CustomScatterDataPoint {
  x: number;
  y: number;
}

export const dataLabelPlugin: Plugin<'scatter'> = {
  id: 'dataLabelPlugin',
  afterDatasetsDraw: (
    chart: Chart<'scatter', CustomScatterDataPoint[], string>
  ) => {
    const { ctx } = chart;

    chart.data.datasets.forEach((dataset) => {
      ctx.fillStyle = '#38414F';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.font = '600 16px Poppins';

      dataset.data.forEach((dataPoint) => {
        if (!dataPoint) {
          return;
        }
        const xPos = chart.scales.x.getPixelForValue(dataPoint.x);
        const yPos = chart.scales.y.getPixelForValue(dataPoint.y);

        ctx.fillText(dataset.label as string, xPos, yPos);
      });
    });
  },
};
