import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './locale/en/en.json';
import pl from './locale/pl/pl.json';
import ghgEn from './locale/en/ghg.json';
import ghgPl from './locale/pl/ghg.json';
import esgEn from './locale/en/esg.json';
import esgPl from './locale/pl/esg.json';
import superadminEn from './locale/en/superadmin.json';
import superadminPl from './locale/pl/superadmin.json';
import registrationEn from './locale/en/registration.json';
import registrationPl from './locale/pl/registration.json';
import superdashboardGhgPl from './locale/pl/superdashboardGhg.json';
import superdashboardGhgEn from './locale/en/superdashboardGhg.json';
import registrationBnpPl from './locale/pl/registration_bnp.json';
import cbam from './locale/en/cbam.json';
import { LocalStorageKeys } from './common/constants';

const SUPPORTED_NAMESPACES = [
  'legacyGhg',
  'ghg',
  'esg',
  'superadmin',
  'superdashboardGhg',
  'registration',
  'registration_bnp',
  'cbam',
];

const resources = {
  en: {
    legacyGhg: en,
    esg: esgEn,
    ghg: ghgEn,
    superdashboardGhg: superdashboardGhgEn,
    superadmin: superadminEn,
    registration: registrationEn,
    registration_bnp: registrationBnpPl, // the same for both languages
    cbam, // the same for both languages
  },
  pl: {
    legacyGhg: pl,
    esg: esgPl,
    ghg: ghgPl,
    superdashboardGhg: superdashboardGhgPl,
    superadmin: superadminPl,
    registration: registrationPl,
    registration_bnp: registrationBnpPl,
    cbam,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    compatibilityJSON: 'v3',
    resources,
    supportedLngs: Object.keys(resources),
    fallbackLng: 'en',
    ns: SUPPORTED_NAMESPACES,
    fallbackNS: SUPPORTED_NAMESPACES,
    detection: {
      order: ['querystring', 'localStorage', 'navigator'],
      caches: ['localStorage'],
      lookupLocalStorage: LocalStorageKeys.LOCAL_LANGUAGE,
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
