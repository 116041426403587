import React from 'react';
import { useTranslation } from 'react-i18next';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CircleIcon from '@mui/icons-material/Circle';
import EditIcon from '@mui/icons-material/Edit';

import { DmaStep as Props } from './index.types';
import { StyledTitle, StyledContainer } from './index.styles';
import DmaStepDialog from './DmaStepDialog';
import DmaStepNavigateTo from './DmaStepNavigateTo';
import { StyledFlex } from 'src/components/StyledFlex';
import { Box, IconButton } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export default function DmaStep({
  startIcon,
  title,
  disabled,
  substep,
  videoComponent,
  navigateTo,
  navigateToComponent,
  navigateToOutside,
}: Props) {
  const { t } = useTranslation();
  // when disabled, the DmaStep is grayed out and no action buttons are displayed
  const actionButtons = disabled ? (
    t('esg:dma.step.comingSoon')
  ) : (
    <StyledFlex sx={{ justifyContent: 'flex-end' }}>
      {videoComponent && (
        <DmaStepDialog
          icon={<PlayArrowIcon />}
          content={videoComponent as React.ReactElement}
        />
      )}
      {navigateToComponent ||
        (navigateTo && (
          <DmaStepNavigateTo icon={<EditIcon />} navigateTo={navigateTo} />
        ))}
      {navigateToOutside && (
        <IconButton onClick={() => window.open(navigateToOutside, '_blank')}>
          <OpenInNewIcon />
        </IconButton>
      )}
    </StyledFlex>
  );

  return (
    <StyledContainer sx={{ color: disabled ? '#ccc' : 'inherit' }}>
      <StyledFlex>
        {substep && <Box sx={{ minWidth: '40px' }} />}
        {startIcon || <CircleIcon sx={{ fontSize: '10px' }} />}
        <StyledTitle>{title}</StyledTitle>
      </StyledFlex>
      <div style={{ minWidth: '150px', textAlign: 'right' }}>
        {actionButtons}
      </div>
    </StyledContainer>
  );
}
