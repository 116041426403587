import { Tab, Tabs } from '@mui/material';
import React from 'react';
import { SETTINGS_TABS } from './index.types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface Props {
  tab: SETTINGS_TABS;
  setTab: React.Dispatch<React.SetStateAction<SETTINGS_TABS>>;
}

const SUPERADMIN_TABS: SETTINGS_TABS[] = [
  SETTINGS_TABS.STRUCTURE_TAB,
  SETTINGS_TABS.USERS_TAB,
];

export default function SuperadminTabs(props: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { tab, setTab } = props;

  const handleTabChange = (_: React.SyntheticEvent, tabName: SETTINGS_TABS) => {
    // sets the hash in the url
    navigate(`${window.location.pathname}#${tabName}`);
    setTab(tabName);
  };

  return (
    <Tabs sx={{ mt: '20px' }} value={tab} onChange={handleTabChange}>
      {SUPERADMIN_TABS.map((tabName) => (
        <Tab
          key={tabName}
          label={t(`superadmin:settings.tabs.${tabName}.title`)}
          id={tabName}
          value={tabName}
        />
      ))}
    </Tabs>
  );
}
