import React from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import { Summary } from '../index.types';
import { StyledText, StyledTextLight } from '../index.styles';
import { StyledPanel } from '../../../../../styles';

export default function BarChart({ data }: { data: Summary[] }) {
  const { t } = useTranslation();

  const labels = data.map((item: any) => item.stakeholder_topic.name);
  const positiveScores = data.map(
    (item: any) => item.calculated_positive_score
  );
  const negativeScores = data.map(
    (item: any) => item.calculated_negative_score
  );

  const chartData = {
    labels,
    datasets: [
      {
        label: t(
          'esg:dma.stakeholderAnalytics.dashboard.barchart.positiveImpact'
        ),
        data: positiveScores,
        backgroundColor: '#A1E4C8',
      },
      {
        label: t(
          'esg:dma.stakeholderAnalytics.dashboard.barchart.negativeImpact'
        ),
        data: negativeScores,
        backgroundColor: '#FF9292',
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          maxRotation: 40,
          font: {
            size: 12,
            weight: 'bold',
          },
          autoSkip: false,
        },
      },
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
      title: {
        display: true,
      },
    },
  };

  return (
    <StyledPanel>
      <StyledText>
        {t('esg:dma.stakeholderAnalytics.dashboard.barchart.title')}
      </StyledText>
      <StyledTextLight>
        {t('esg:dma.stakeholderAnalytics.dashboard.barchart.subtitle')}
      </StyledTextLight>
      <div
        style={{
          width: '100%',
          height: '450px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Bar data={chartData} options={options} />
      </div>
    </StyledPanel>
  );
}
