import React, { useMemo } from 'react';
import assets from '../assets';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSupplierSurvey } from '../hooks';
import { createTokenFetcher } from '../../../views/components/QuestionnaireV3/helpers';
import PageLoading from '../../../components/PageLoading';
import SomethingWentWrong from '../../../components/SomethingWentWrong';
import QuestionnaireV3 from '../../../views/components/QuestionnaireV3';
import SurveyContainer from '../../../components/Survey/Container';
import { Logo } from '../../../components/Survey/icons';
import ImageHeader from '../../../components/Survey/ImageHeader';
import Title from '../../../components/Survey/Title';
import { toast } from 'react-toastify';

export default function SupplierSurveyQuestionnaire() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const survey = useSupplierSurvey();

  const tokenFetcher = useMemo(
    () => createTokenFetcher(survey.fetchQv3Token),
    [survey.fetchQv3Token]
  );

  if (survey.isError) return <SomethingWentWrong />;
  if (!survey.data) return <PageLoading />;
  if (!survey.data.answer) return <Navigate to="../" />;
  if (survey.data.answer.is_submitted) return <Navigate to="../end" />;

  const prevLink = {
    onClick: () => {
      navigate('../initial-questions');
    },
    label: 'ghg:supplierSurvey.back',
  };

  const nextLink = {
    onClick: () =>
      survey.submit
        .mutateAsync()
        .then(() => navigate(`../end`))
        .catch(() =>
          toast.error(t('ghg:supplierSurvey.submitError') as string, {
            autoClose: 10_000,
            toastId: 'submit-error',
          })
        ),
    label: 'ghg:supplierSurvey.send',
  };

  return (
    <SurveyContainer
      logo={<Logo />}
      header={
        <ImageHeader
          imageSrc={assets.questionnaire.image}
          text={t('ghg:supplierSurvey.mainHeader')}
          small
        />
      }
      prevLink={prevLink}
      nextLink={nextLink}
    >
      <Title title={t('ghg:supplierSurvey.questionnaire.title')} />
      <QuestionnaireV3
        questionnaireAnswerId={survey.data.answer.questionnaire_answer}
        tokenAuth={tokenFetcher}
      />
    </SurveyContainer>
  );
}
