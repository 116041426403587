import { t } from 'i18next';
import * as Sentry from '@sentry/react';

import { FieldValue } from '../../Field/Fields/types';
import {
  Qv3SummaryTableSchema,
  Qv3SummaryTableType,
  Cell,
  CellType,
  FieldType,
  SummaryTable,
  SummaryFieldValues,
} from '../types';

export const renderValue = (value: SummaryFieldValues, type: FieldType) => {
  switch (type) {
    case FieldType.Boolean:
      return Object.values(value).map((v) =>
        t(!!v ? 'esg:common.yes' : 'esg:common.no')
      );
    case FieldType.Decimal:
      return Object.values(value).map((v) =>
        v === null ? '-' : (v as number).toFixed(2).replace(/\.0+$/, '')
      );
    default:
      return Object.values(value).map((v: FieldValue) => {
        if (!hasValue(v)) return '-';
        return String(v);
      });
  }
};

export const parseGridTableData = (data: Qv3SummaryTableSchema[]) => {
  const r: {
    [key: string]: {
      id: number;
      key: string;
      columns: string[];
      rows: Cell[][];
    };
  } = {};

  for (const table of data) {
    for (const questionnaire of table.questionnaires) {
      for (const question of questionnaire.questions) {
        const key = question.title.trim();
        if (!r[key])
          r[key] = {
            id: table.id,
            key,
            rows: [],
            columns: ['No.'],
          };
        const row: Cell[] = [
          {
            value: String(r[key].rows.length + 1),
            type: CellType.Question,
          },
        ];
        for (const field of question.fields) {
          if (!r[key].columns.includes(field.title))
            r[key].columns.push(field.title);
          row.push({
            value: renderValue(field.value, field.type),
            type: CellType.Value,
          });
        }
        // only add row if there are answer values
        if (row.length > 1) r[key].rows.push(row);
      }
    }
  }
  return Object.entries(r)
    .filter(([_, v]) => v.rows.length > 0)
    .map(([k, v]) => ({
      title: k,
      ...v,
    }));
};

export const parseIndividualByFieldTableData = (
  data: Qv3SummaryTableSchema[],
  columns: string[]
) => {
  const tables: SummaryTable[] = [];
  data.forEach((table, tIdx) => {
    const _table: SummaryTable = {
      id: table.id,
      title: `${table.title}: ${t('questionnaireV3.summary.answer')} #${
        tIdx + 1
      }`,
      columns,
      rows: [],
    };
    table.questionnaires.forEach((questionnaire) =>
      questionnaire.questions.forEach((question) =>
        question.fields.forEach((field) => {
          _table.rows.push([
            { value: question.title, type: CellType.Question },
            { value: field.title, type: CellType.Question },
            {
              value: renderValue(field.value, field.type),
              type: CellType.Value,
            },
          ]);
        })
      )
    );
    tables.push(_table);
  });
  return tables;
};

export const parseSubtopicTableData = (
  data: Qv3SummaryTableSchema[],
  columns: string[]
) => {
  const r: {
    [key: string]: {
      id: number;
      key: string;
      columns: string[];
      rows: Cell[][];
    };
  } = {};

  data.forEach((table, tIdx) => {
    table.questionnaires.forEach((questionnaire) =>
      questionnaire.questions.forEach((question) => {
        const key = question.title.trim();
        if (!r[key]) r[key] = { id: table.id, key, columns, rows: [] };

        question.fields.forEach((field) => {
          const row: Cell[] = [
            {
              value: `${table.title} ${t('questionnaireV3.summary.answer')} #${
                tIdx + 1
              }`,
              type: CellType.Question,
            },
            { value: field.title, type: CellType.Question },
            {
              value: renderValue(field.value, field.type),
              type: CellType.Value,
            },
          ];
          r[key].rows.push(row);
        });
      })
    );
  });
  return Object.entries(r)
    .filter(([_, v]) => v.rows.length > 0)
    .map(([k, v]) => ({
      title: k,
      ...v,
    }));
};

export const checkUndefinedCells = (data: SummaryTable[]) => {
  const undefinedCells: {
    page: { id: number; key?: string; title: string };
    row: number;
    column: number;
  }[] = [];
  for (const page of data) {
    for (const [rowIdx, row] of page.rows.entries()) {
      for (const [colIdx, cell] of row.entries()) {
        if (!cell) {
          undefinedCells.push({
            page: { id: page.id, key: page.key, title: page.title },
            row: rowIdx,
            column: colIdx,
          });
        }
      }
    }
  }
  if (undefinedCells.length > 0)
    throw new Error(
      `Undefined cells found in summary table: ${JSON.stringify(
        undefinedCells
      )}`
    );
  return data;
};

export const parseSummaryData = (
  data: Qv3SummaryTableSchema[],
  type: Qv3SummaryTableType,
  customColumns: string[] = []
) => {
  try {
    switch (type) {
      case Qv3SummaryTableType.Grid:
        return checkUndefinedCells(parseGridTableData(data));
      case Qv3SummaryTableType.IndividualByField:
        return checkUndefinedCells(
          parseIndividualByFieldTableData(data, customColumns)
        );
      case Qv3SummaryTableType.Subtopic:
        return checkUndefinedCells(parseSubtopicTableData(data, customColumns));
      default:
        throw new Error('Invalid table type');
    }
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
  }
  return [];
};

export const hasValue = (v: any) =>
  !(v === undefined || v === null || v === '');
