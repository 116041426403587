import React from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import { StyledText, StyledTextLight } from '../styles';
import { StyledPanel } from '../../../../../styles';
import { useParams } from 'react-router-dom';
import { useBarChartData } from './hooks';

export default function BarChart() {
  const { t } = useTranslation();
  const { reportId } = useParams();
  const { chartData, chartOptions } = useBarChartData(Number(reportId));

  return (
    <StyledPanel>
      <StyledText>
        {t('esg:dma.stakeholderAnalytics.dashboard.barchart.title')}
      </StyledText>
      <StyledTextLight>
        {t('esg:dma.stakeholderAnalytics.dashboard.barchart.subtitle')}
      </StyledTextLight>
      <div
        style={{
          width: '100%',
          height: '450px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {chartData && chartOptions && (
          <Bar data={chartData} options={chartOptions} />
        )}
      </div>
    </StyledPanel>
  );
}
