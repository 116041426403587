import React from 'react';
import {
  Box,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  Link,
} from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

interface IReference {
  text: string | null;
  link_label: string | null;
  name: string | null;
  number: string | null;
  section: string | null;
  subsection: string | null;
  paragraph: string | null;
  subparagraph: string | null;
  clause: string | null;
  related_ar: string | null;
  reference_type: string | null;
  conditional_type: string | null;
  mandatory_type: string | null;
  datapoint_id: string | null;
  uri: string | null;
}

interface DatapointMateriality {
  is_material: boolean;
}

type DatapointMaterialityMap = { [key: string]: DatapointMateriality };
export interface IReferencesContext {
  questions: { [key: string]: IReference[] };
  fields: { [key: string]: IReference[] };
  datapointMateriality?: DatapointMaterialityMap;
}

export const ReferecesContext = React.createContext<IReferencesContext>({
  questions: {},
  fields: {},
  datapointMateriality: {},
});

function Reference(props: {
  reference: IReference;
  materiality?: DatapointMateriality;
}) {
  const { reference, materiality } = props;
  const { t } = useTranslation(undefined, { keyPrefix: 'esg:common' });
  const header = [
    reference.name,
    reference.number,
    reference.section,
    reference.subsection,
    reference.paragraph,
    reference.subparagraph,
    reference.clause,
  ]
    .filter((x) => !!x)
    .join(', ');

  const isMaterial = materiality?.is_material === true;

  const getDatapointDescription = () => {
    if (!reference.datapoint_id) return null;

    const types = [
      !!reference.conditional_type ? t('conditional') : undefined,
      reference.mandatory_type === 'Voluntary' ? t('voluntary') : undefined,
    ].filter((x) => !!x);
    const isMandatory = types.length === 0;
    const type = (isMandatory ? [t('mandatory')] : types).join(', ');

    return (
      <>
        {isMaterial && (
          <Box
            component="span"
            sx={{
              color: 'darkred',
              fontStyle: 'normal',
            }}
          >
            {t('material')}{' '}
          </Box>
        )}
        <Box
          component="span"
          sx={{
            color: !isMandatory ? 'orange' : undefined,
            fontStyle: 'normal',
          }}
        >
          {type} Datapoint ({reference.datapoint_id})
        </Box>
        {'; '}
      </>
    );
  };

  return (
    <>
      <Typography variant="captionText" fontWeight="bold" component="span">
        {getDatapointDescription()}
        {header}
        {header && reference.related_ar && ', '}
        {reference.related_ar && (
          <Box component="span" sx={{ color: 'primary.main' }}>
            {reference.related_ar}
          </Box>
        )}
        {(header || reference.related_ar) && reference.text && ': '}
      </Typography>
      {reference.text && (
        <Typography
          variant="captionText"
          component="span"
        >{`"${reference.text}" `}</Typography>
      )}
      {reference.uri && (
        <Link variant="captionText" href={reference.uri} target="_blank">
          (link)
        </Link>
      )}
    </>
  );
}

function References(props: {
  references: IReference[];
  datapointMateriality?: DatapointMaterialityMap;
}) {
  const { references, datapointMateriality = {} } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'esg:common',
  });
  const [isCollapseOpen, setIsCollapseOpen] = React.useState(true);
  React.useEffect(() => {
    if (
      references &&
      references.length >= 2 &&
      !references.find((x) => x.datapoint_id)
    ) {
      setIsCollapseOpen(false);
    }
  }, [references]);

  const referencesWithMateriality = React.useMemo(() => {
    const mapped = references.map((reference) => {
      const materiality = datapointMateriality[reference.datapoint_id ?? ''];
      return {
        reference,
        materiality,
      };
    });
    return _.sortBy(mapped, (x) => !x.materiality?.is_material);
  }, [references, datapointMateriality]);

  if ((references?.length ?? 0) === 0) return null;

  return (
    <Box
      sx={{
        fontStyle: 'italic',
        color: '#505050',
        border: '1px solid #D4E3FB',
        borderBottom: '3px solid #D4E3FB',
        borderRadius: '4px',
        background: '#F2F6FC',
        padding: '8px 16px',
        marginBottom: '32px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="captionText">{t('references')}:</Typography>
        <IconButton onClick={() => setIsCollapseOpen((x) => !x)}>
          <KeyboardArrowDown />
        </IconButton>
      </div>
      <Collapse in={isCollapseOpen} timeout="auto" unmountOnExit>
        <List dense>
          {referencesWithMateriality.map(
            ({ reference, materiality }, index) => (
              <ListItem key={index}>
                <ListItemText>
                  <Reference reference={reference} materiality={materiality} />
                </ListItemText>
              </ListItem>
            )
          )}
        </List>
      </Collapse>
    </Box>
  );
}

const MemorizedReferences = React.memo(References);

// eslint-disable-next-line no-unused-vars, react/no-unused-prop-types
export function QuestionReferences(props: { questionKey: string }) {
  return null; // we dont have these for now
  /*
  const referencesContext: IReferecesContext =
    React.useContext(ReferecesContext);
  const references = referencesContext?.questions[props.questionKey] || [];
  return <MemorizedReferences references={references} />;
  */
}

const EMPTY_ARRAY: any[] = [];

export function FieldReferences(props: { fieldKey: string }) {
  const referencesContext: IReferencesContext =
    React.useContext(ReferecesContext);
  const references = referencesContext?.fields[props.fieldKey] || EMPTY_ARRAY;

  return (
    <MemorizedReferences
      references={references}
      datapointMateriality={referencesContext.datapointMateriality}
    />
  );
}
