import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  capital_goods_cloud,
  purchased_goods_cloud,
} from 'src/views/Pages/Questionnaire/clouds_data';
import QuestionnaireSectionTitle from 'src/views/components/QuestionnaireSectionTitle';
import SupplierMethodBox from './SupplierMethodBox';
import { GOODS_AND_SERVICES_OR_CAPITAL_GOODS } from '../../interfaces';
import AverageMethodBox from './AverageMethodBox';
import EcoinventMethodBox from './EcoinventMethodBox';
import SpendingMethodBox from './SpendingMethodBox';
import { questionForGoodType } from 'src/Ghg/utils';
import { Box } from '@mui/material';
import DataEntryCompletedCheckbox from 'src/Ghg/components/DataEntryCompletedCheckbox';
import { GhgDataEntryCompletedType } from 'src/Ghg/components/DataEntryCompletedCheckbox/interfaces';
import { useAppSelector } from 'src/redux-file/hooks';

type SectionProps = {
  type: GOODS_AND_SERVICES_OR_CAPITAL_GOODS;
};

export default function GoodsSection(props: SectionProps) {
  const { selectedDate } = useAppSelector((state) => state.app);

  const cloud =
    props.type === GOODS_AND_SERVICES_OR_CAPITAL_GOODS.GOODS_AND_SERVICES
      ? purchased_goods_cloud
      : capital_goods_cloud;

  const dataEntryCompletedType =
    props.type === GOODS_AND_SERVICES_OR_CAPITAL_GOODS.GOODS_AND_SERVICES
      ? GhgDataEntryCompletedType.SCOPE_3_GOODS_AND_SERVICES
      : GhgDataEntryCompletedType.SCOPE_3_CAPITAL_GOODS;

  const { t } = useTranslation(undefined, {
    keyPrefix: `goods-and-services.${questionForGoodType[props.type]}`,
  });

  return (
    <Box sx={{ mt: '42px' }}>
      <QuestionnaireSectionTitle
        level="primary"
        text={t('title')}
        boxContent={cloud}
        active={undefined}
      />
      <DataEntryCompletedCheckbox
        selectedDate={selectedDate || new Date()}
        type={dataEntryCompletedType}
      />
      <br />
      <SupplierMethodBox type={props.type} />
      <br />
      <AverageMethodBox type={props.type} />
      <br />
      <EcoinventMethodBox type={props.type} />
      <br />
      <SpendingMethodBox type={props.type} />
    </Box>
  );
}
