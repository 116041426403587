import { Typography } from '@mui/material';
import React from 'react';
import { StyledPanel } from 'src/Superadmin/index.styles';
import { StyledFlex } from 'src/components/StyledFlex';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Trans } from 'react-i18next';
import { usePlatform } from 'src/redux-file/hooks';

export default function MaxOrgReachedAlert() {
  const { platformSubscriber } = usePlatform();
  const limit = platformSubscriber.maxNumOrganizations;
  return (
    <StyledPanel>
      <StyledFlex sx={{ justifyContent: 'flex-start' }}>
        <WarningAmberIcon sx={{ mr: '20px', fontSize: '30px' }} />

        <Typography>
          <Trans
            i18nKey="superadmin:settings.tabs.structure.maxOrgReached"
            values={{ limit }}
          />
        </Typography>
      </StyledFlex>
    </StyledPanel>
  );
}
