import { Button, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { CbamPrecursorInSchema, CbamPrecursorOutSchema } from 'src/Cbam/types';
import { usePrecursors, useProductionProcesses } from 'src/Cbam/hooks';
import { StyledPanel } from 'src/components/StyledPanel';
import {
  StyledFormControl,
  StyledNumberField,
  StyledTextField,
} from '../../../styles';
import { StyledFlex } from 'src/components/StyledFlex';
import { useChangeTracker } from '../components/CounterProvider';
import { quantityUnit } from 'src/Cbam/utils';
import { FormErrors, requiredFields, validateForm } from './FormValidation';
import { extractSchemaErrors, mapError } from 'src/utils/validation';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

interface Props {
  productionProcessId: number;
  id?: number;
  onSaved?: () => void;
}
export default function Precursor(props: Props) {
  const { productionProcessId, id, onSaved } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'cbam:productionProcess.precursor',
  });
  const { isModified, setIsModified } = useChangeTracker();
  const precursors = usePrecursors(Number(productionProcessId));
  const precursor = precursors.data.find((m) => m.id === Number(id));
  const processes = useProductionProcesses();

  const initialForm = {
    name: '',
    precursor_id: null,
    production_process_id: productionProcessId,
    amount: null,
  };

  const [form, setForm] = React.useState<
    CbamPrecursorOutSchema | CbamPrecursorInSchema
  >(initialForm);

  const [errors, setErrors] = React.useState<FormErrors>({});

  const handleFormChange = (key: string, value: any) => {
    setIsModified(true);
    const newForm = {
      ...form,
      [key]: value,
    };
    setForm(newForm);
    setErrors(validateForm(newForm, t));
  };

  const isError = (fieldName: keyof CbamPrecursorInSchema) =>
    !!form[fieldName] && !!mapError(errors, fieldName, form[fieldName]);
  const errorText = (fieldName: keyof CbamPrecursorInSchema) =>
    isError(fieldName) && mapError(errors, fieldName, form[fieldName] || '');

  React.useEffect(() => {
    if (precursor) setForm(precursor);
  }, [precursor]);

  const onSuccess = () => {
    setIsModified(false);
    onSaved?.();
  };

  const onError = (err: any) => setErrors(extractSchemaErrors(err, form));

  const handleSaveClick = () =>
    !id
      ? precursors.create.mutateAsync(form).then(onSuccess).catch(onError)
      : precursors.update
          .mutateAsync({ ...form, id })
          .then(onSuccess)
          .catch(onError);

  const unit = quantityUnit(
    processes.data.find((p) => p.id === form.precursor_id)
  );

  return (
    <StyledPanel sx={{ m: '20px 0px' }}>
      <StyledTextField
        label="Name"
        value={form.name}
        onChange={(e) => handleFormChange('name', e.target.value)}
        required={requiredFields.includes('name')}
        error={isError('name')}
        helperText={errorText('name')}
      />

      <StyledFormControl required error={isError('precursor_id')}>
        <InputLabel id="process-id-label">Precursor Process</InputLabel>

        <Select
          labelId="process-id-label"
          label="Precursor Process"
          value={form.precursor_id || ''}
          onChange={(e) => handleFormChange('precursor_id', e.target.value)}
        >
          {processes.data.map((p) => (
            <MenuItem key={p.id} value={p.id}>
              {p.name} ({p.aggregated_goods_category})
            </MenuItem>
          ))}
        </Select>
      </StyledFormControl>
      <StyledNumberField
        label={`Amount [${unit}]`}
        value={form.amount}
        onChange={(value) => handleFormChange('amount', value)}
        required={requiredFields.includes('amount')}
        error={isError('amount')}
        helperText={errorText('amount')}
      />
      <StyledFlex>
        <Button
          onClick={() => (id ? precursors.delete.mutate(id) : onSaved?.())}
          color="primary"
        >
          Remove Answer
        </Button>
        {isModified && (
          <Button
            color="primary"
            onClick={handleSaveClick}
            sx={{ minWidth: '200px' }}
            disabled={Object.keys(validateForm(form, t)).length > 0}
          >
            {id ? 'Save' : 'Create'}
          </Button>
        )}
      </StyledFlex>
    </StyledPanel>
  );
}
