import React from 'react';
import SurveyContainer from '../../../components/Survey/Container';
import Title from '../../../components/Survey/Title';
import { Trans, useTranslation } from 'react-i18next';
import { Logo } from '../../../components/Survey/icons';
import ImageHeader from '../../../components/Survey/ImageHeader';
import { Subtitle } from '../styles';
import assets from '../assets';
import { useSupplierSurvey } from '../hooks';
import PageLoading from '../../../components/PageLoading';
import { Box, Typography } from '@mui/material';
import NotFound from '../../../views/components/NotFound';

export default function SupplierSurveyEnd() {
  const { t } = useTranslation();
  const survey = useSupplierSurvey();

  if (survey.isError) return <NotFound />;
  if (!survey.data) return <PageLoading />;

  return (
    <SurveyContainer
      logo={<Logo />}
      header={
        <ImageHeader
          imageSrc={assets.end.image}
          text={t('ghg:supplierSurvey.mainHeader')}
        />
      }
    >
      <Box>
        <Title title={t('ghg:supplierSurvey.end.title')} />
        <Subtitle>{t('ghg:supplierSurvey.end.subtitle')}</Subtitle>
        <Trans i18nKey="ghg:supplierSurvey.end.message" />
        <Typography>{survey.data.organization_name}</Typography>
      </Box>
    </SurveyContainer>
  );
}
