export enum GhgDataEntryCompletedType {
  SCOPE_1_STATIONARY_COMBUSTION = 'scope_1_stationary_combustion',
  SCOPE_1_FUEL_CONSUMPTION = 'scope_1_fuel_consumption',
  SCOPE_1_DISTANCE_WISE = 'scope_1_distance_wise',
  SCOPE_1_FUGITIVE_EMISSIONS = 'scope_1_fugitive_emissions',
  SCOPE_1_PROCESS_EMISSIONS = 'scope_1_process_emissions',

  SCOPE_2_ELECTRICITY_CONSUMPTION = 'scope_2_electricity_consumption',
  SCOPE_2_STEAM = 'scope_2_steam',
  SCOPE_2_HEAT = 'scope_2_heat',
  SCOPE_2_COOLING = 'scope_2_cooling',
  SCOPE_2_EV_ENERGY_WISE = 'scope_2_ev_energy_wise',
  SCOPE_2_EV_DISTANCE_WISE = 'scope_2_ev_distance_wise',

  SCOPE_3_GOODS_AND_SERVICES = 'scope_3_goods_and_services',
  SCOPE_3_CAPITAL_GOODS = 'scope_3_capital_goods',

  SCOPE_3_TRANSPORT_UPSTREAM = 'scope_3_transport_upstream',
  SCOPE_3_TRANSPORT_DOWNSTREAM = 'scope_3_transport_downstream',
  SCOPE_3_DISTRIBUTION_UPSTREAM = 'scope_3_distribution_upstream',
  SCOPE_3_DISTRIBUTION_DOWNSTREAM = 'scope_3_distribution_downstream',

  SCOPE_3_BUILDINGS_UPSTREAM = 'scope_3_buildings_upstream',
  SCOPE_3_ASSETS_UPSTREAM = 'scope_3_assets_upstream',
  SCOPE_3_BUILDINGS_DOWNSTREAM = 'scope_3_buildings_downstream',
  SCOPE_3_ASSETS_DOWNSTREAM = 'scope_3_assets_downstream',
  SCOPE_3_FRANCHISES = 'scope_3_franchises',
  SCOPE_3_INVESTMENTS = 'scope_3_investments',

  SCOPE_3_WASTE = 'scope_3_waste',
  SCOPE_3_FUEL_RELATED = 'scope_3_fuel_related',
  SCOPE_3_ENERGY_RELATED = 'scope_3_energy_related',
  SCOPE_3_OTHER_EMISSIONS = 'scope_3_other_emissions',

  SCOPE_3_SOLD_PRODUCTS = 'scope_3_emissions_of_sold_products',

  SCOPE_3_EMPLOYEE_SURVEY = 'scope_3_employee_emissions',
}

export interface GhgDataEntryCompletedInSchema {
  organization_id: number;
  period: string;
  data_type: GhgDataEntryCompletedType;
}

export interface GhgDataEntryCompletedOutSchema
  extends GhgDataEntryCompletedInSchema {
  id: number;
  completed_at: string;
  completed_by: string;
}
