import {
  GhgDataEntryCompletedInSchema,
  GhgDataEntryCompletedOutSchema,
} from './interfaces';
import { QueryOptions, useResourceController } from 'src/common/hooks';

// eslint-disable-next-line import/prefer-default-export
export const useGhgDataEntriesCompleted = (
  queryParams: { organization_id: number; period: string },
  queryOptions:
    | QueryOptions<GhgDataEntryCompletedOutSchema>
    | undefined = undefined
) =>
  useResourceController<
    GhgDataEntryCompletedInSchema,
    GhgDataEntryCompletedOutSchema
  >(
    `/web/v2/data-entries-completed`,
    ['data-entries-completed', queryParams],
    queryParams,
    queryOptions
  );
