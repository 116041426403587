import React from 'react';
import Container from '../components/Container';
import assets from '../assets';
import Title from '../components/Title';
import { useNavigate, useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useBasicSurveyMeta } from '../hooks';
import SomethingWentWrong from '../../../../../components/SomethingWentWrong';
import PageLoading from '../../../../../components/PageLoading';

export default function StakeholderSurveySummary() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { token } = useParams();
  const { data: meta, isError } = useBasicSurveyMeta(token);

  if (isError) return <SomethingWentWrong />;
  if (!meta) return <PageLoading />;

  return (
    <Container
      header={<img src={assets.summary.image} />}
      nextLink={{
        onClick: () => navigate(`../end`),
        label: 'esg:stakeholderSurvey.submit',
      }}
      prevLink={{
        onClick: () => navigate(`../questionnaire`),
        label: 'esg:stakeholderSurvey.back',
      }}
    >
      <div>
        <Title
          title={t('esg:stakeholderSurvey.title')}
          period={meta.report_period}
        />
        <Trans i18nKey="esg:stakeholderSurvey.summary" />
        {/*  TODO: Summary */}
      </div>
    </Container>
  );
}
