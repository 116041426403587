import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, IconButton } from '@mui/material';
import {
  Delete as DeleteIcon,
  Send as SendIcon,
  AddCircleOutlineOutlined as AddCircleOutlineOutlinedIcon,
  Download,
} from '@mui/icons-material';
import {
  StyledHeader,
  StyledSection,
  StyledTitle,
  StyledCell,
  StyledCellHeader,
  StyledCellText,
  StyledColumnName,
} from '../../../components/Table/index.styles';
import { StyledFlex } from 'src/components/StyledFlex';
import { getColorFromNumber } from 'src/Ghg/utils';
import PageLoading from 'src/components/PageLoading';
import LightTooltip from 'src/components/LightTooltip';
import CodeOfConductSurveyModal from '../SurveyModal';
import { toast } from 'react-toastify';
import ConfirmationModal from 'src/components/ConfirmationModal';
import { format } from 'date-fns';
import { useGhgOrganization } from '../../../../common/hooks';
import { useCodeOfConductSurveys } from '../hooks';
import { GhgSupplierCodeOfConductSurveyOutSchema } from 'src/Ghg/CodeOfConductSurvey/types';

const dateFormat = 'yyyy-MM-dd';

const T_PREFIX = 'ghg:scope3GoodsAndServices.codeOfConduct';

export default function CodeOfConductRequests() {
  const { t } = useTranslation(undefined, {
    keyPrefix: T_PREFIX,
  });

  const [deleteSurvey, setDeleteSurvey] =
    React.useState<GhgSupplierCodeOfConductSurveyOutSchema | null>(null);

  const organization = useGhgOrganization();

  const [newSurvey, setNewSurvey] = React.useState<boolean>(false);
  const [editSurvey, setEditSurvey] =
    React.useState<GhgSupplierCodeOfConductSurveyOutSchema | null>(null);

  const { query, remove } = useCodeOfConductSurveys(organization.id);

  const handleSendAgainClick = (
    invitation: GhgSupplierCodeOfConductSurveyOutSchema
  ) => setEditSurvey(invitation);

  const handleDeleteClick = (
    invitation: GhgSupplierCodeOfConductSurveyOutSchema
  ) => setDeleteSurvey(invitation);

  const handleDeleteSurvey = () =>
    remove.mutateAsync(deleteSurvey?.id as number).then(() => {
      toast.success(t('toasts.surveyDeleted') as string);
      setDeleteSurvey(null);
    });

  return (
    <div style={{ minHeight: '500px', minWidth: '900px' }}>
      <StyledTitle>{t('title')}</StyledTitle>

      <StyledSection>
        <StyledFlex
          sx={{ bgcolor: '#606462', color: '#fff', alignItems: 'normal' }}
        >
          <StyledHeader sx={{ borderLeft: '8px solid #606462' }}>
            <StyledColumnName>
              {t('table.columns.supplierName')}
            </StyledColumnName>
          </StyledHeader>
          <StyledHeader>
            <StyledColumnName>{t('table.columns.email')}</StyledColumnName>
          </StyledHeader>
          <StyledHeader>
            <StyledColumnName>
              {t('table.columns.dateOfResponse')}
            </StyledColumnName>
          </StyledHeader>
          <StyledHeader sx={{ minWidth: '195px', maxWidth: '195px' }}>
            <StyledColumnName>
              {t('table.columns.editOptions')}
            </StyledColumnName>
          </StyledHeader>
        </StyledFlex>
        {query.data === undefined && <PageLoading />}

        {(query.data || []).map((row) => {
          const indentColor = getColorFromNumber(row.id);
          const borderLeft = `6px solid ${indentColor}`;
          return (
            <StyledFlex key={row.id} sx={{ alignItems: 'normal' }}>
              <StyledCell sx={{ borderLeft }}>
                <StyledCellText>{row.supplier_name}</StyledCellText>
              </StyledCell>
              <StyledCell sx={{ borderLeft }}>
                {row.sent_at && (
                  <StyledCellHeader>
                    {t('table.sent')}:{' '}
                    {format(new Date(row.sent_at), dateFormat)}
                  </StyledCellHeader>
                )}
                <StyledCellText sx={{ wordBreak: 'break-word' }}>
                  {row.email}
                </StyledCellText>
              </StyledCell>
              <StyledCell sx={{ borderLeft }}>
                <StyledCellText>
                  {(row.answer &&
                    format(new Date(row.answer.submitted_at), dateFormat)) ||
                    '-'}
                </StyledCellText>
              </StyledCell>
              <StyledCell
                sx={{
                  borderLeft,
                  minWidth: '195px',
                  maxWidth: '195px',
                }}
              >
                <LightTooltip title={t('table.buttons.download')}>
                  <IconButton
                    disabled={!row.answer}
                    href={row.answer?.signed_file.url ?? '#'}
                    target="_blank"
                  >
                    <Download />
                  </IconButton>
                </LightTooltip>
                <LightTooltip title={t('table.buttons.sendAgain')}>
                  <IconButton onClick={() => handleSendAgainClick(row)}>
                    <SendIcon />
                  </IconButton>
                </LightTooltip>
                <LightTooltip title={t('table.buttons.remove')}>
                  <IconButton onClick={() => handleDeleteClick(row)}>
                    <DeleteIcon />
                  </IconButton>
                </LightTooltip>
              </StyledCell>
            </StyledFlex>
          );
        })}
      </StyledSection>

      <Button
        variant="contained"
        sx={{ mt: '25px' }}
        startIcon={
          <AddCircleOutlineOutlinedIcon sx={{ transform: 'scale(1.2)' }} />
        }
        onClick={() => setNewSurvey(true)}
      >
        {t('table.addSurvey')}
      </Button>
      <CodeOfConductSurveyModal
        key={editSurvey?.id}
        open={!!newSurvey || !!editSurvey}
        onClose={() => {
          setNewSurvey(false);
          setEditSurvey(null);
        }}
        survey={editSurvey}
      />

      <ConfirmationModal
        open={!!deleteSurvey}
        onClose={() => setDeleteSurvey(null)}
        onFalse={() => setDeleteSurvey(null)}
        onTrue={handleDeleteSurvey}
        titleKey={`${T_PREFIX}.table.deleteConfirmation.title`}
        textKey={`${T_PREFIX}.table.deleteConfirmation.text`}
      />
    </div>
  );
}
