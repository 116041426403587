import React from 'react';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import LightTooltip from 'src/components/LightTooltip';
import { Box } from '@mui/material';

const LightTooltipHtml = (props: TooltipProps) => {
  const { title } = props;

  return (
    <LightTooltip
      {...props}
      title={
        <Box
          dangerouslySetInnerHTML={{
            __html: title as string,
          }}
        />
      }
      sx={{
        [`& .${tooltipClasses.tooltip}`]: {
          maxWidth: '500px',
        },
      }}
    />
  );
};

export default LightTooltipHtml;
