import React from 'react';

import { CheckCircle, Error } from '@mui/icons-material';
import themeColors from 'src/theme/colors';
import LightTooltip from 'src/components/LightTooltip';
import { useLanguage } from 'src/common/hooks';

interface Props {
  hasDescription: boolean;
}
export default function DescritionMark(props: Props) {
  const { hasDescription } = props;
  const { t } = useLanguage({ keyPrefix: 'esg:dma.common' });

  const color = hasDescription ? themeColors.completed : themeColors.error;
  const Icon = hasDescription ? CheckCircle : Error;
  return (
    <LightTooltip title={hasDescription ? undefined : t('descriptionRequired')}>
      <Icon
        sx={{
          color,
          fontSize: 22,
          position: 'absolute',
          top: -1,
          right: -9,
        }}
      />
    </LightTooltip>
  );
}
