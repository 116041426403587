import React, { useEffect, useRef, useState } from 'react';
import QuestionnaireTitle from '../../components/Questionnaire/Title';
import AddButtonHolder from '../../components/Questionnaire/AddButtonHolder';
import {
  CopyWrapper,
  Description,
  PageContainer,
  PreviewBox,
  Reminder,
  Section,
  SectionGroupContainer,
  StyledHeader,
  StyledTh,
} from './index.styles';
import Title from '../../../components/Title';
import {
  getWasteAndOtherActivitiesData,
  getWasteAndOtherActivitiesDataEmissionShares,
  putWasteAndOtherActivitiesData,
  setSelectedDate,
} from '../../../redux-file/questionnaires/thunks';
import { useAppDispatch, useAppSelector } from '../../../redux-file/hooks';
import { useGhgOrganization, useLanguage } from 'src/common/hooks';
import {
  timeConverter,
  getFirstDateOfPeriod,
} from '../../../utils/timeConverter';
import QuestionnaireSectionTitle from '../../components/QuestionnaireSectionTitle';
import WasteForm from '../../components/Questionnaire/forms/wasteForm';
import WastePreview from '../../components/Questionnaire/preview/wastePreview';
import OtherFuelForm from '../../components/Questionnaire/forms/waste-otherfuel-form';
import OtherFuelPreview from '../../components/Questionnaire/preview/waste-other-fuel-preview';
import OtherFormEnergyForm from '../../components/Questionnaire/forms/waste-other-energy-form';
import OtherEmissionsForm from '../../components/Questionnaire/forms/waste-other-emissions';
import OtherEmissionsPreview from '../../components/Questionnaire/preview/waste-other-emissions-preview';
import ElecricityPreview from '../../components/Questionnaire/preview/electricityPreview';
import PageLoading from '../../../components/PageLoading';
import SomethingWentWrong from '../../../components/SomethingWentWrong';
import AddProductButton from '../../../components/AddProductButton';
import {
  other_energy_cloud,
  other_fuel_cloud,
  waste_cloud,
  other_emissions_cloud,
} from './clouds_data';
import UploadExcelButton from '../../../components/UploadExcelButton';
import { IntuitiveCalendarPeriod } from '../../components/IntuitiveCalendar';
import CopyForm from '../../components/CopyForm';
import DataEntryCompletedCheckbox from 'src/Ghg/components/DataEntryCompletedCheckbox';
import { GhgDataEntryCompletedType } from 'src/Ghg/components/DataEntryCompletedCheckbox/interfaces';

export default function WasteQuestionnaire() {
  const { t, language } = useLanguage();

  const dispatch = useAppDispatch();

  const organization = useGhgOrganization();
  const reportingPeriod = organization.co2.reporting_freq;

  const {
    selectedDate,
    wasteData,
    wasteDataError,
    wasteDataLoading,
    wasteDataEmissionShares,
  } = useAppSelector((state) => state.questionnaire);

  const wasteInit = {
    waste_type: '',
    amount_kg: '',
    recycled_or_composed_flag: false,
    manual_factor: '',
  };

  const otherFuelInit = {
    type_of_fuel: '',
    amount_per_period: '',
    unit: '',
    manual_factor: '',
  };

  const otherEnergyInit = {
    emission_factor_is_known: false,
    energy_usage_data_factor_unknown: {
      spending_pln_or_kwh_per_period: '',
      unit: '',
    },
    energy_usage_data_factor_known: {
      factor: '',
      amount_per_period: '',
      unit_label: '',
    },
    certified_sources_are_utilized: false,
    certified_energy_usage_data: {
      certified_energy_type_label: '',
      certified_percentge: '', //int
      emission_factor: '',
    },
  };

  const otherEmissionsInit = {
    name: '',
    amount_kg_eq_co2_in_period: '',
  };

  const [active1, setActive1] = useState<undefined | number>(undefined);
  const [active2, setActive2] = useState<undefined | number>(undefined);
  const [active3, setActive3] = useState<undefined | number>(undefined);
  const [active4, setActive4] = useState<undefined | number>(undefined);

  const [newFormWaste, setNewFormWaste] = useState(true);
  const [newFormOtherFuel, setNewFormOtherFuel] = useState(true);
  const [newFormEnergyFuel, setNewFormEnergyFuel] = useState(true);
  const [newFormOtherEmissions, setNewFormOtherEmissions] = useState(true);

  const [wasteState, setWasteState] = useState([wasteInit]);
  const [otherFuelState, setOtherFuelState] = useState([otherFuelInit]);
  const [otherEnergyState, setOtherEnergyState] = useState([otherEnergyInit]);
  const [otherEmissionsState, setOtherEmissionsState] = useState([
    otherEmissionsInit,
  ]);

  useEffect(() => {
    if (wasteData.waste_data.length === 0) {
      setNewFormWaste(true);
    } else {
      setNewFormWaste(false);
    }

    setWasteState(
      wasteData.waste_data.length !== 0 &&
        wasteDataEmissionShares.waste_data.length !== 0
        ? wasteData.waste_data.map((el: any, i: number) => ({
            ...el,
            emission_shared: wasteDataEmissionShares.waste_data[i]?.share,
          }))
        : [wasteInit]
    );

    if (wasteData.other_fuel_related_activities_data.length === 0) {
      setNewFormOtherFuel(true);
    } else {
      setNewFormOtherFuel(false);
    }

    setOtherFuelState(
      wasteData.other_fuel_related_activities_data.length !== 0 &&
        wasteDataEmissionShares.other_fuel_related_activities_data.length !== 0
        ? wasteData.other_fuel_related_activities_data.map(
            (el: any, i: number) => ({
              ...el,
              emission_shared:
                wasteDataEmissionShares.other_fuel_related_activities_data[i]
                  ?.share,
            })
          )
        : [otherFuelInit]
    );

    if (wasteData.other_energy_related_activities.length === 0) {
      setNewFormEnergyFuel(true);
    } else {
      setNewFormEnergyFuel(false);
    }

    setOtherEnergyState(
      wasteData.other_energy_related_activities.length !== 0 &&
        wasteDataEmissionShares.other_energy_related_activities.length !== 0
        ? wasteData.other_energy_related_activities.map(
            (el: any, i: number) => ({
              ...el,
              emission_shared:
                wasteDataEmissionShares.other_energy_related_activities[i]
                  ?.share,
            })
          )
        : [otherEnergyInit]
    );

    setNewFormOtherEmissions(wasteData.other_emissions.length === 0);
    setOtherEmissionsState(
      wasteData.other_emissions.length !== 0 &&
        wasteDataEmissionShares.other_emissions.length !== 0
        ? wasteData.other_emissions.map((el: any, i: number) => ({
            ...el,
            emission_shared: wasteDataEmissionShares.other_emissions[i]?.share,
          }))
        : [otherEmissionsInit]
    );
  }, [wasteData, selectedDate, wasteDataEmissionShares]);

  const refreshForm = () => {
    dispatch(
      getWasteAndOtherActivitiesData({
        orgId: organization.id,
        period: timeConverter(selectedDate, reportingPeriod),
      })
    );
    dispatch(
      getWasteAndOtherActivitiesDataEmissionShares({
        orgId: organization.id,
        period: timeConverter(selectedDate, reportingPeriod),
      })
    );
  };

  const sendData = (data: any) => {
    dispatch(
      putWasteAndOtherActivitiesData({
        period: timeConverter(selectedDate, reportingPeriod),
        data,
      })
    );
  };

  useEffect(() => {
    if (!!reportingPeriod) {
      refreshForm();
    }
  }, [selectedDate, reportingPeriod]);

  const combinedData = {
    waste_data:
      wasteState.length === 1 && wasteState[0].waste_type === ''
        ? []
        : wasteState,
    other_fuel_related_activities_data:
      otherFuelState.length === 1 && otherFuelState[0].type_of_fuel === ''
        ? []
        : otherFuelState,
    other_energy_related_activities:
      otherEnergyState.length === 1 &&
      otherEnergyState[0].emission_factor_is_known === false &&
      otherEnergyState[0].energy_usage_data_factor_unknown
        .spending_pln_or_kwh_per_period === ''
        ? []
        : otherEnergyState,
    other_emissions:
      otherEmissionsState.length === 1 &&
      otherEmissionsState[0].amount_kg_eq_co2_in_period === ''
        ? []
        : otherEmissionsState,
  };

  const waste_ref = useRef(null);
  const other_fuel_ref = useRef(null);
  const other_energy_ref = useRef(null);
  const other_emissions_ref = useRef(null);

  const combineWasteData = (fullData: any, wasteFormData: any) => {
    return {
      waste_data: [...wasteFormData, ...fullData.waste_data],
      other_fuel_related_activities_data:
        fullData.other_fuel_related_activities_data,
      other_energy_related_activities: fullData.other_energy_related_activities,
      other_emissions: fullData.other_emissions,
    };
  };
  const combineOtherFuelData = (fullData: any, fuelData: any) => {
    return {
      waste_data: fullData.waste_data,
      other_fuel_related_activities_data: [
        ...fuelData,
        ...fullData.other_fuel_related_activities_data,
      ],
      other_energy_related_activities: fullData.other_energy_related_activities,
      other_emissions: fullData.other_emissions,
    };
  };
  const combineOtherEnergyData = (fullData: any, energyData: any) => {
    return {
      waste_data: fullData.waste_data,
      other_energy_related_activities: [
        ...energyData,
        ...fullData.other_energy_related_activities,
      ],
      other_fuel_related_activities_data:
        fullData.other_fuel_related_activities_data,
      other_emissions: fullData.other_emissions,
    };
  };
  const combineOtherEmissionsData = (fullData: any, otherData: any) => {
    return {
      waste_data: fullData.waste_data,
      other_fuel_related_activities_data:
        fullData.other_fuel_related_activities_data,
      other_energy_related_activities: fullData.other_energy_related_activities,
      other_emissions: [...otherData, ...fullData.other_emissions],
    };
  };

  return (
    <PageContainer>
      <SectionGroupContainer>
        <QuestionnaireTitle info text={t('waste-and-other.title')} />

        <Description>{t('waste-and-other.description')}</Description>
        <Reminder>{t('goods-and-services.reminder')}</Reminder>

        <CopyWrapper>
          <Title title={t('forms.select-period')} description={null} />
          <CopyForm
            form="WasteAndOtherActivitiesData"
            orgId={organization.id}
            refresh={() => refreshForm()}
          />
        </CopyWrapper>
        <IntuitiveCalendarPeriod
          reportingPeriod={reportingPeriod}
          selectedDate={getFirstDateOfPeriod(
            selectedDate ? new Date(selectedDate) : new Date(),
            reportingPeriod ?? 'M'
          )}
          setSelectedDate={(selDate: Date) =>
            dispatch(setSelectedDate(selDate))
          }
        />

        {wasteDataLoading && <PageLoading />}
        {wasteDataError && <SomethingWentWrong />}

        {!wasteDataLoading && !wasteDataError && (
          <>
            <Section>
              <div ref={waste_ref} />
              <QuestionnaireSectionTitle
                level="primary"
                text={t('waste-and-other.waste.title')}
                boxContent={waste_cloud}
                active={active1}
              />
              <DataEntryCompletedCheckbox
                selectedDate={selectedDate}
                type={GhgDataEntryCompletedType.SCOPE_3_WASTE}
              />
              {newFormWaste && (
                <>
                  <PreviewBox>
                    {wasteState.map((el, id) => (
                      <WasteForm
                        setNewForm={setNewFormWaste}
                        id={id}
                        key={id}
                        setState={setWasteState}
                        state={wasteState}
                        active={active1}
                        setActive={setActive1}
                        combinedData={combinedData}
                      />
                    ))}
                  </PreviewBox>
                  <UploadExcelButton
                    name={t('waste-and-other.waste.title')}
                    slug="WasteData"
                    fullData={combinedData}
                    combineData={combineWasteData}
                    sendData={sendData}
                    refresh={refreshForm}
                  />
                </>
              )}
              {!newFormWaste && (
                <>
                  <PreviewBox>
                    <StyledHeader>
                      <StyledTh width={30}>
                        {t('waste-and-other.waste.table.col1')}
                      </StyledTh>
                      <StyledTh width={23}>
                        {t('waste-and-other.waste.table.col2')}
                      </StyledTh>
                      <StyledTh width={23}>
                        {t('waste-and-other.waste.table.col3')}
                      </StyledTh>
                      <StyledTh width={23}>
                        {t(
                          'direct-emission.stationary-combustion.table.emission_shared'
                        )}
                      </StyledTh>
                      <StyledTh width={0}>
                        {t('sold-products.preview.edit')}
                      </StyledTh>
                    </StyledHeader>
                    <div>
                      {wasteState.map((el, id) => {
                        if (active1 === id) {
                          return (
                            <WasteForm
                              setNewForm={setNewFormWaste}
                              active={active1}
                              setActive={setActive1}
                              combinedData={combinedData}
                              id={id}
                              key={id}
                              setState={setWasteState}
                              state={wasteState}
                            />
                          );
                        } else {
                          return (
                            <WastePreview
                              language={language}
                              setState={setWasteState}
                              id={id}
                              key={id}
                              state={wasteState}
                              setActive={setActive1}
                            />
                          );
                        }
                      })}
                    </div>
                  </PreviewBox>
                  <AddButtonHolder
                    firstChild={
                      <AddProductButton
                        onClick={() => {
                          setWasteState([wasteInit, ...wasteState]);
                          setActive1(0);
                          if (waste_ref && waste_ref.current) {
                            // @ts-ignore
                            waste_ref.current.scrollIntoView({
                              behavior: 'smooth',
                            });
                          }
                        }}
                      />
                    }
                    secondChild={
                      <UploadExcelButton
                        name={t('waste-and-other.waste.title')}
                        slug="WasteData"
                        fullData={combinedData}
                        combineData={combineWasteData}
                        sendData={sendData}
                        refresh={refreshForm}
                      />
                    }
                  />
                </>
              )}
            </Section>

            <Section>
              <div ref={other_fuel_ref} />
              <QuestionnaireSectionTitle
                level="primary"
                text={t('waste-and-other.other-fuel.title')}
                boxContent={other_fuel_cloud}
                active={active2}
              />
              <DataEntryCompletedCheckbox
                selectedDate={selectedDate}
                type={GhgDataEntryCompletedType.SCOPE_3_FUEL_RELATED}
              />
              {newFormOtherFuel && (
                <>
                  <PreviewBox>
                    {otherFuelState.map((el, id) => (
                      <OtherFuelForm
                        setNewForm={setNewFormOtherFuel}
                        active={active2}
                        setActive={setActive2}
                        combinedData={combinedData}
                        id={id}
                        key={id}
                        setState={setOtherFuelState}
                        state={otherFuelState}
                      />
                    ))}
                  </PreviewBox>
                  <UploadExcelButton
                    name={t('waste-and-other.other-fuel.title')}
                    slug="OtherFuelRelatedActivities"
                    fullData={combinedData}
                    combineData={combineOtherFuelData}
                    sendData={sendData}
                    refresh={refreshForm}
                  />
                </>
              )}
              {!newFormOtherFuel && (
                <>
                  <PreviewBox>
                    <StyledHeader>
                      <StyledTh width={30}>
                        {t('waste-and-other.other-fuel.amount_per_period')}
                      </StyledTh>
                      <StyledTh width={23}>
                        {t('waste-and-other.other-fuel.type_of_fuel')}
                      </StyledTh>
                      <StyledTh width={23}>
                        {t('waste-and-other.other-fuel.unit')}
                      </StyledTh>
                      <StyledTh width={23}>
                        {t(
                          'direct-emission.stationary-combustion.table.emission_shared'
                        )}
                      </StyledTh>
                      <StyledTh width={0}>
                        {t('sold-products.preview.edit')}
                      </StyledTh>
                    </StyledHeader>
                    <div>
                      {otherFuelState.map((el, id) => {
                        if (active2 === id) {
                          return (
                            <OtherFuelForm
                              setNewForm={setNewFormOtherFuel}
                              active={active2}
                              setActive={setActive2}
                              combinedData={combinedData}
                              id={id}
                              key={id}
                              setState={setOtherFuelState}
                              state={otherFuelState}
                            />
                          );
                        } else {
                          return (
                            <OtherFuelPreview
                              language={language}
                              setState={setOtherFuelState}
                              id={id}
                              key={id}
                              state={otherFuelState}
                              setActive={setActive2}
                            />
                          );
                        }
                      })}
                    </div>
                  </PreviewBox>
                  <AddButtonHolder
                    firstChild={
                      <AddProductButton
                        onClick={() => {
                          setOtherFuelState([otherFuelInit, ...otherFuelState]);
                          setActive2(0);
                          if (other_fuel_ref && other_fuel_ref.current) {
                            // @ts-ignore
                            other_fuel_ref.current.scrollIntoView({
                              behavior: 'smooth',
                            });
                          }
                        }}
                      />
                    }
                    secondChild={
                      <UploadExcelButton
                        name={t('waste-and-other.other-fuel.title')}
                        slug="OtherFuelRelatedActivities"
                        fullData={combinedData}
                        combineData={combineOtherFuelData}
                        sendData={sendData}
                        refresh={refreshForm}
                      />
                    }
                  />
                </>
              )}
            </Section>

            <Section>
              <div ref={other_energy_ref} />
              <QuestionnaireSectionTitle
                level="primary"
                text={t('waste-and-other.other-energy.title')}
                boxContent={other_energy_cloud}
                active={active3}
              />
              <DataEntryCompletedCheckbox
                selectedDate={selectedDate}
                type={GhgDataEntryCompletedType.SCOPE_3_ENERGY_RELATED}
              />
              {newFormEnergyFuel && (
                <>
                  <PreviewBox>
                    {otherEnergyState.map((el, id) => (
                      <OtherFormEnergyForm
                        setNewForm={setNewFormEnergyFuel}
                        combinedData={combinedData}
                        active={active3}
                        setActive={setActive3}
                        id={id}
                        key={id}
                        setState={setOtherEnergyState}
                        state={otherEnergyState}
                      />
                    ))}
                  </PreviewBox>
                  <UploadExcelButton
                    name={t('waste-and-other.other-energy.title')}
                    slug="EnergyUsageData"
                    fullData={combinedData}
                    combineData={combineOtherEnergyData}
                    sendData={sendData}
                    refresh={refreshForm}
                  />
                </>
              )}
              {!newFormEnergyFuel && (
                <>
                  <PreviewBox>
                    <StyledHeader>
                      <StyledTh width={30}>
                        {t('waste-and-other.other-energy.table.power-spending')}
                      </StyledTh>
                      <StyledTh width={20}>
                        {t('waste-and-other.other-energy.table.unit')}
                      </StyledTh>
                      <StyledTh width={25}>
                        {t(
                          'direct-emission.stationary-combustion.table.emission_shared'
                        )}
                      </StyledTh>

                      <StyledTh width={25}>
                        {t(
                          'waste-and-other.other-energy.table.certified-percentage'
                        )}
                      </StyledTh>
                      <StyledTh width={0}>
                        {t('sold-products.preview.edit')}
                      </StyledTh>
                    </StyledHeader>
                    <div>
                      {otherEnergyState.map((el, id) => {
                        if (active3 === id) {
                          return (
                            <OtherFormEnergyForm
                              setNewForm={setNewFormEnergyFuel}
                              combinedData={combinedData}
                              active={active3}
                              setActive={setActive3}
                              id={id}
                              key={id}
                              setState={setOtherEnergyState}
                              state={otherEnergyState}
                            />
                          );
                        } else {
                          return (
                            <ElecricityPreview
                              language={language}
                              setState={setOtherEnergyState}
                              id={id}
                              key={id}
                              state={otherEnergyState}
                              setActive={setActive3}
                            />
                          );
                        }
                      })}
                    </div>
                  </PreviewBox>
                  <AddButtonHolder
                    firstChild={
                      <AddProductButton
                        onClick={() => {
                          setOtherEnergyState([
                            otherEnergyInit,
                            ...otherEnergyState,
                          ]);
                          setActive3(0);
                          if (other_energy_ref && other_energy_ref.current) {
                            // @ts-ignore
                            other_energy_ref.current.scrollIntoView({
                              behavior: 'smooth',
                            });
                          }
                        }}
                      />
                    }
                    secondChild={
                      <UploadExcelButton
                        name={t('waste-and-other.other-energy.title')}
                        slug="EnergyUsageData"
                        fullData={combinedData}
                        combineData={combineOtherEnergyData}
                        sendData={sendData}
                        refresh={refreshForm}
                      />
                    }
                  />
                </>
              )}
            </Section>

            <Section>
              <div ref={other_emissions_ref} />
              <QuestionnaireSectionTitle
                level="primary"
                text={t('waste-and-other.other-emissions.title')}
                boxContent={other_emissions_cloud}
                active={active4}
              />
              <DataEntryCompletedCheckbox
                selectedDate={selectedDate}
                type={GhgDataEntryCompletedType.SCOPE_3_OTHER_EMISSIONS}
              />
              {newFormOtherEmissions && (
                <>
                  <PreviewBox>
                    {otherEmissionsState.map((el, id) => (
                      <OtherEmissionsForm
                        setNewForm={setNewFormOtherEmissions}
                        setActive={setActive4}
                        active={active4}
                        combinedData={combinedData}
                        id={id}
                        key={id}
                        setState={setOtherEmissionsState}
                        state={otherEmissionsState}
                      />
                    ))}
                  </PreviewBox>
                  <UploadExcelButton
                    name={t('waste-and-other.other-emissions.title')}
                    slug="OtherEmissionsData"
                    fullData={combinedData}
                    combineData={combineOtherEmissionsData}
                    sendData={sendData}
                    refresh={refreshForm}
                  />
                </>
              )}
              {!newFormOtherEmissions && (
                <>
                  <PreviewBox>
                    <StyledHeader>
                      <StyledTh width={80}>
                        {t('waste-and-other.other-emissions.name')}
                      </StyledTh>
                      <StyledTh width={20}>
                        {t(
                          'direct-emission.stationary-combustion.table.emission_shared'
                        )}
                      </StyledTh>

                      <StyledTh width={0}>
                        {t('sold-products.preview.edit')}
                      </StyledTh>
                    </StyledHeader>
                    <div>
                      {otherEmissionsState.map((el, id) => {
                        if (active4 === id) {
                          return (
                            <OtherEmissionsForm
                              setNewForm={setNewFormOtherEmissions}
                              setActive={setActive4}
                              active={active4}
                              combinedData={combinedData}
                              id={id}
                              key={id}
                              setState={setOtherEmissionsState}
                              state={otherEmissionsState}
                            />
                          );
                        } else {
                          return (
                            <OtherEmissionsPreview
                              setState={setOtherEmissionsState}
                              id={id}
                              key={id}
                              state={otherEmissionsState}
                              setActive={setActive4}
                            />
                          );
                        }
                      })}
                    </div>
                  </PreviewBox>
                  <AddButtonHolder
                    firstChild={
                      <AddProductButton
                        onClick={() => {
                          setOtherEmissionsState([
                            otherEmissionsInit,
                            ...otherEmissionsState,
                          ]);
                          setActive4(0);
                          if (
                            other_emissions_ref &&
                            other_emissions_ref.current
                          ) {
                            // @ts-ignore
                            other_emissions_ref.current.scrollIntoView({
                              behavior: 'smooth',
                            });
                          }
                        }}
                      />
                    }
                    secondChild={
                      <UploadExcelButton
                        name={t('waste-and-other.other-emissions.title')}
                        slug="OtherEmissionsData"
                        fullData={combinedData}
                        combineData={combineOtherEmissionsData}
                        sendData={sendData}
                        refresh={refreshForm}
                      />
                    }
                  />
                </>
              )}
            </Section>
          </>
        )}
      </SectionGroupContainer>
    </PageContainer>
  );
}
