import React from 'react';
import { HelpOutlined } from '@mui/icons-material';
import { Box, IconButton, SxProps, Typography } from '@mui/material';

import { StyledFlex } from 'src/components/StyledFlex';
import { useLanguage } from 'src/common/hooks';

interface Props {
  fieldName?: string;
  sx?: SxProps;
  children: React.ReactNode;
  helpText?: string;
}

export default function FieldLabel(props: Props) {
  const { t } = useLanguage({ keyPrefix: 'esg:dma.step1.valueChain' });
  const { fieldName, sx, helpText } = props;
  return (
    <StyledFlex sx={{ alignItems: 'flex-start', p: '10px', ...sx }}>
      <Typography sx={{ width: '30%' }} variant="subtitle2">
        {fieldName && t(fieldName)}
      </Typography>
      <Box sx={{ width: '5%' }} />

      {props.children}

      <Box sx={{ minWidth: '40px' }}>
        {helpText && (
          <IconButton color="success">
            <HelpOutlined />
          </IconButton>
        )}
      </Box>
    </StyledFlex>
  );
}
