import React from 'react';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import LoginIcon from '@mui/icons-material/Login';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useNavigate } from 'react-router-dom';
import { StyledButton, StyledButtonBox } from '../index.styles';
import PasswordVerificationForm from 'src/components/PasswordVerificationForm';
import ButtonGroupField from 'src/components/ButtonGroupField';
import { toast } from 'react-toastify';
import { extractSchemaErrors, mapError } from 'src/utils/validation';
import {
  FormErrors,
  RegistrationUBNPSchema,
  firstPageFields,
  validateForm,
} from '../form';
import { ReportingFreq } from 'src/utils/api.interfaces';
import { client } from 'src/utils/api-client';
import { ReportingPeriodHeader, ReportingPeriodInfo } from './index.styles';

export default function RegistrationSecondPage(props: {
  form: RegistrationUBNPSchema;
  setForm: React.Dispatch<React.SetStateAction<RegistrationUBNPSchema>>;
  errors: FormErrors;
  setErrors: React.Dispatch<React.SetStateAction<FormErrors>>;
  onPrevious: () => void;
}) {
  const { form, setForm, errors, setErrors, onPrevious } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const reporting_freq_options = [
    [ReportingFreq.M, t('registration_bnp:reportingFrequency.monthly')],
    [ReportingFreq.Q, t('registration_bnp:reportingFrequency.quarterly')],
    [ReportingFreq.Y, t('registration_bnp:reportingFrequency.yearly')],
  ];

  const isError = (fieldName: keyof RegistrationUBNPSchema) =>
    !!form[fieldName] && !!mapError(errors, fieldName, form[fieldName]);
  const errorText = (fieldName: keyof RegistrationUBNPSchema) =>
    !!form[fieldName] &&
    String(t(mapError(errors, fieldName, form[fieldName]) || ''));

  const mutation = useMutation({
    mutationFn: () => client.post('/web/register_ubnp', form),
    onError: (err: any) => {
      toast.error(String(t('registration_bnp:formError')));
      const extractedErrors = extractSchemaErrors(err, form);
      setErrors(extractedErrors);
      if (
        Object.keys(extractedErrors).some((key) =>
          firstPageFields.includes(key as keyof RegistrationUBNPSchema)
        )
      ) {
        onPrevious();
      }
    },
    onSuccess: () => {
      toast.success(String(t('registration_bnp:success')));
      navigate('/login');
    },
  });

  return (
    <>
      <PasswordVerificationForm
        setNewPassword={(value: string) =>
          setForm({ ...form, password: value })
        }
        translationPrefix="registration"
      />

      {isError('password') && (
        <Alert sx={{ mt: '20px' }} severity="error">
          {errorText('password')}
        </Alert>
      )}

      <ReportingPeriodHeader>
        {t('registration_bnp:reportingFrequency.reportingPeriod')}
      </ReportingPeriodHeader>
      <ReportingPeriodInfo>
        {t('registration_bnp:reportingFrequency.info')}
      </ReportingPeriodInfo>
      <Box sx={{ mt: '30px' }}>
        <ButtonGroupField
          options={reporting_freq_options}
          value={form.reporting_frequency as string}
          setValue={(period: string) =>
            setForm({
              ...form,
              reporting_frequency: period as ReportingFreq,
            })
          }
        />
      </Box>
      <StyledButtonBox sx={{ mt: '50px', justifyContent: 'space-between' }}>
        <StyledButton
          variant="outlined"
          size="large"
          onClick={onPrevious}
          disabled={mutation.isLoading}
          startIcon={<NavigateBeforeIcon />}
        >
          {t('registration_bnp:previousPage')}
        </StyledButton>
        <StyledButton
          variant="contained"
          size="large"
          onClick={() => mutation.mutate()}
          disabled={
            Object.keys(validateForm(form)).length > 0 || mutation.isLoading
          }
          endIcon={<LoginIcon />}
        >
          {t('registration_bnp:register')}
        </StyledButton>
      </StyledButtonBox>
    </>
  );
}
