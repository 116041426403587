import { t } from 'i18next';

export const numericOptions = [
  {
    value: 1,
    label: t('esg:dma.selectingStakeholders.sustainabilityAwareness.level1'),
  },
  {
    value: 2,
    label: t('esg:dma.selectingStakeholders.sustainabilityAwareness.level2'),
  },
  {
    value: 3,
    label: t('esg:dma.selectingStakeholders.sustainabilityAwareness.level3'),
  },
  {
    value: 4,
    label: t('esg:dma.selectingStakeholders.sustainabilityAwareness.level4'),
  },
  {
    value: 5,
    label: t('esg:dma.selectingStakeholders.sustainabilityAwareness.level5'),
  },
  {
    value: null,
    label: t('esg:common.numericOptions.none'),
  },
];

export const booleanOptions = [
  {
    value: null,
    label: t('esg:common.none'),
  },
  {
    value: true,
    label: t('esg:common.yes'),
  },
  {
    value: false,
    label: t('esg:common.no'),
  },
];
