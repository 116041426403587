import React from 'react';
import assets from '../assets';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SomethingWentWrong from '../../../components/SomethingWentWrong';
import SurveyContainer from '../../../components/Survey/Container';
import { Logo } from '../../../components/Survey/icons';
import ImageHeader from '../../../components/Survey/ImageHeader';
import Title from '../../../components/Survey/Title';
import ReportTabs from '../../../components/esg/reportTabs';
import { Tab } from './types';
import {
  useInstallations,
  useOperators,
  useProductionProcesses,
} from 'src/Cbam/hooks';
import OperatorForm from 'src/Cbam/Operator/Form';
import InstallationForm from 'src/Cbam/Installation/Form';
import ProductionProcessEdit from 'src/Cbam/ProductionProcess/Edit';
import PageLoading from 'src/components/PageLoading';

const keyPrefix = 'cbam:productionProcessSurvey';

const tabData = [
  {
    tab: Tab.Operator,
    name: 'Operator',
    url: '../operator',
  },
  {
    tab: Tab.Installation,
    name: 'Installation',
    url: '../installation',
  },
  {
    tab: Tab.ProductionProcess,
    name: 'Prodution Process',
    url: '../production-process',
  },
];

export default function ProductionProcessSurveyQuestionnaire() {
  const { token, tab } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation(undefined, {
    keyPrefix,
  });

  React.useEffect(() => window.scrollTo(0, 0), [tab]);

  const { data: productionProcesses } = useProductionProcesses();
  const { data: installations } = useInstallations();
  const { data: operators } = useOperators();

  const productionProcess =
    productionProcesses.length > 0 ? productionProcesses[0] : null;
  const installation =
    installations.length > 0
      ? installations.find((i) => i.id === productionProcess?.installation_id)
      : null;
  const operator =
    operators.length > 0
      ? operators.find((o) => o.id === installation?.operator_id)
      : null;

  const selectedTabIdx = tabData.findIndex((el) => el.tab === tab);
  if (selectedTabIdx === -1) return <Navigate to={tabData[0].url} />;

  const selectedTab = tabData[selectedTabIdx];
  const prevTab = tabData[selectedTabIdx - 1];
  const nextTab = tabData[selectedTabIdx + 1];

  const prevLink = prevTab && {
    onClick: () => navigate(prevTab.url),
    label: `${keyPrefix}.back`,
  };

  const navigateNext = () => navigate(nextTab?.url ?? '../../end');

  const nextLink = {
    onClick: navigateNext,
    label: nextTab ? `${keyPrefix}.next` : `${keyPrefix}.finish`,
  };

  if (!token) return <SomethingWentWrong />;

  if (!productionProcess || !installation || !operator) return <PageLoading />;

  return (
    <SurveyContainer
      logo={<Logo />}
      header={
        <ImageHeader
          imageSrc={assets.questionnaire.image}
          text={t('mainHeader')}
          small
        />
      }
      prevLink={prevLink}
      nextLink={nextLink}
    >
      <Title title={t('questionnaire.title')} />
      <ReportTabs data={tabData} />
      {selectedTab.tab === Tab.Operator && (
        <OperatorForm id={operator.id} survey onSaved={navigateNext} />
      )}
      {selectedTab.tab === Tab.Installation && (
        <InstallationForm id={installation.id} survey onSaved={navigateNext} />
      )}
      {selectedTab.tab === Tab.ProductionProcess && (
        <ProductionProcessEdit id={productionProcess.id} survey />
      )}
      {/* {selectedTab.tab === Tab.Verifier && <VerifierForm />} */}
    </SurveyContainer>
  );
}
