import React from 'react';

import { useTranslation } from 'react-i18next';
import { useGhgOrganization } from 'src/common/hooks';
import { useSupplierSurvey } from '../../hooks';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { Close, Visibility } from '@mui/icons-material';

interface Props {
  id?: number;
  open: boolean;
  onClose: () => void;
}

export default function PreviewDialog({ id, open, onClose }: Props) {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'ghg:scope3GoodsAndServices.productsFromSuppliers.preview',
  });
  const { t: translate } = useTranslation();

  const organization = useGhgOrganization();

  const { data } = useSupplierSurvey(organization.id);

  const answer = data?.find((survey) => survey.id === id)?.answer;

  const openDetailedPreview = () =>
    window.open(
      `/questionnaire-readonly/${answer?.questionnaire_answer}`,
      '_blank'
    );

  const emissionsKnown = answer?.emissions_known ? 'yes' : 'no';

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <IconButton
        sx={{ position: 'absolute', right: '10px', top: '10px' }}
        onClick={onClose}
      >
        <Close />
      </IconButton>
      <DialogTitle>
        <Typography>{t('title')}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ mb: '24px' }}>
          {t('companyName')}: <b>{answer?.company_name}</b>
        </Typography>
        <Typography sx={{ mb: '24px' }}>
          {t('contactEmail')}: <b>{answer?.contact_email}</b>
        </Typography>
        <Typography sx={{ mb: '24px' }}>
          {t('validPeriod')}: <b>{answer?.valid_since}</b> -{' '}
          <b>{answer?.valid_until}</b>
        </Typography>
        <Typography sx={{ mb: '32px' }}>
          {t('emissionsKnown')}: <b>{translate(emissionsKnown)}</b>
        </Typography>

        <Button startIcon={<Visibility />} onClick={openDetailedPreview}>
          {t('detailedPreview')}
        </Button>
      </DialogContent>
    </Dialog>
  );
}
