import React from 'react';
import DmaStage from '../components/DmaStage';
import DmaStep from '../components/DmaStep';
import { useLanguage } from 'src/common/hooks';

export default function Stage7() {
  const { t } = useLanguage({ keyPrefix: 'esg:dma' });
  return (
    <DmaStage title={t('stagesV2.relevantDataPoints.title')}>
      <DmaStep title={t('stagesV2.relevantDataPoints.steps.1')} disabled />
    </DmaStage>
  );
}
