import React, { useState } from 'react';
import {
  Box,
  BoxContainer,
  BoxDate,
  BoxItemInner,
  Container,
  HiddenIconButton,
  NewReportBox,
  Subtitle,
  TitleWrapper,
} from './index.styles';

import { useEsgOrganization } from 'src/common/hooks';
import PageLoading from '../../../../components/PageLoading';
import SomethingWentWrong from '../../../../components/SomethingWentWrong';
import { useTranslation } from 'react-i18next';
import { FormType } from '../Report/index.styles';
import IconSvg from '../../../components/IconSvg';
import { EsgReportSchemaOut } from '../../../../Esg/api.types';
import { Button, Menu, MenuItem, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditNameModal from '../Report/editNameModal';
import DeleteReportModal from '../Report/deleteReportModal';
import { StyledFlex } from '../../../../components/StyledFlex';
import EsgExportToolsButton from 'src/components/EsgExportToolsButton';
import { useEsgReports } from 'src/Esg/hooks';
import { useNavigate } from 'react-router-dom';
import TextWithTooltip from 'src/components/TextWithTooltip';

function BoxItem({
  item,
  isOutdated,
}: {
  item: EsgReportSchemaOut;
  isOutdated?: boolean;
}) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [buttonVisible, setButtonVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const organization = useEsgOrganization();
  const canEdit = organization.can_write_data;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setButtonVisible(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setButtonVisible(false);
    setOpen(false);
  };

  if (!!item) {
    let methodologyTxt = `${t('esg:questionnaireMethodology.methodology')}: ${t(
      `esg:questionnaireMethodology.${item.questionnaire_methodology}`
    )}`;

    if (isOutdated) {
      methodologyTxt = `${t(
        'esg:questionnaireMethodology.outdated'
      )} ${methodologyTxt}`;
    }

    return (
      <BoxItemInner buttonVisible={buttonVisible}>
        <Box to={`/esg/reports/${item.id}`}>
          <Typography sx={{ position: 'absolute', top: '20px' }} variant="h3">
            <TextWithTooltip minLength={16} text={item.name} />
          </Typography>
          <Typography
            sx={{ position: 'absolute', top: '56px' }}
            variant="caption"
          >
            <TextWithTooltip minLength={68} text={methodologyTxt} />
          </Typography>
          <Typography
            sx={{
              position: 'absolute',
              top: '102px',
              fontSize: '22px',
              color: '#888',
            }}
            variant="caption"
          >
            {item.period}
          </Typography>
          <StyledFlex
            sx={{
              position: 'absolute',
              top: '142px',
              justifyContent: 'flex-start',
            }}
          >
            {item.categories.map((category) => (
              <FormType bgc={category.color} key={category.key}>
                <TextWithTooltip minLength={16} text={category.name} />
              </FormType>
            ))}
          </StyledFlex>
        </Box>
        <StyledFlex
          style={{
            width: '100%',
            borderTop: '1px solid #dadada',
            marginTop: '10px',
          }}
        >
          <BoxDate>{`${t('esg:last-update')} ${new Date(
            item.last_update!
          ).toLocaleDateString()}`}</BoxDate>

          {canEdit && (
            <HiddenIconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </HiddenIconButton>
          )}

          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={() => setOpen(true)}>
              {t('esg:editNamePeriod')}
            </MenuItem>
            <MenuItem
              onClick={() => {
                setDeleteModalOpen(true);
              }}
            >
              {t('esg:deleteReport')}
            </MenuItem>
          </Menu>
        </StyledFlex>
        <EditNameModal
          open={open}
          setClose={handleClose}
          shouldRedirect={false} // temporary solution before refactor
          report={item}
        />
        <DeleteReportModal
          open={deleteModalOpen}
          setClose={() => setDeleteModalOpen(false)}
          id={item.id.toString()}
        />
      </BoxItemInner>
    );
  } else {
    return <div />;
  }
}
const link_to_new_report = '/esg/reports/new';

export default function ReportsIndex() {
  const { t } = useTranslation();
  const organization = useEsgOrganization();
  const canWrite = organization.can_write_data;
  const navigate = useNavigate();

  const { isLoading, _data, isError } = useEsgReports({
    esg_organization_id: organization.id,
  });

  if (isError) return <SomethingWentWrong />;
  if (isLoading) return <PageLoading />;

  const lastUsedItem = _data?.filter(
    (el) => el.id.toString() === localStorage.getItem('esg_report')
  )?.[0];

  const isReportOutdated = (report: EsgReportSchemaOut) => {
    const TARGET_METHODOLOGY = 'EFRAG_XBRL_202409';
    return (
      report?.questionnaire_methodology !== TARGET_METHODOLOGY &&
      _data?.some(
        (r) =>
          r.questionnaire_methodology === TARGET_METHODOLOGY &&
          r.period === report.period
      )
    );
  };

  return (
    <Container>
      <TitleWrapper>
        <h1>{t('esg:reports')}</h1>
        <div style={{ display: 'flex', gap: '20px' }}>
          <EsgExportToolsButton
            organizationIds={[organization.id]}
            size="large"
          />
          {canWrite && (
            <Button size="large" onClick={() => navigate(link_to_new_report)}>
              {t('esg:new-report')}
            </Button>
          )}
        </div>
      </TitleWrapper>
      {_data.length ? (
        <div>
          {!!lastUsedItem && (
            <div style={{ marginBottom: '56px' }}>
              <Subtitle>{t('esg:recently-opened')}</Subtitle>
              <BoxItem
                item={lastUsedItem}
                isOutdated={isReportOutdated(lastUsedItem)}
              />
            </div>
          )}
          <Subtitle>{t('esg:all-reports')}</Subtitle>
          <BoxContainer>
            {_data
              ?.sort((a, b) => {
                // @ts-ignore
                return new Date(b.last_update) - new Date(a.last_update);
              })
              .map((el) => {
                return (
                  <BoxItem
                    key={el.id}
                    item={el}
                    isOutdated={isReportOutdated(el)}
                  />
                );
              })}
          </BoxContainer>
        </div>
      ) : (
        <div>
          <Subtitle>{t('esg:empty')}</Subtitle>
          <NewReportBox to={link_to_new_report}>
            <p>
              {t('esg:create-new')} {'>'}
            </p>
            <IconSvg name="esg-new-report" />
          </NewReportBox>
        </div>
      )}
    </Container>
  );
}
