import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledTitle } from 'src/Ghg/components/Table/index.styles';
import { useOrganization } from 'src/common/hooks';
import SingleFileUpload from 'src/components/SingleFileUpload';
import { client } from 'src/utils/api-client';
import CodeOfConductRequests from './Surveys';
import { useQuery } from 'react-query';
import { UploadedFileSchema } from '../../CodeOfConductSurvey/types';

export default function CodeOfConduct() {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'ghg:scope3GoodsAndServices',
  });
  const org = useOrganization();

  const endpointUrl = `/web/organizations/${org.id}/code-of-conduct`;

  const query = useQuery(
    ['code-of-conduct', { organizationId: org.id }],
    () =>
      client
        .get<UploadedFileSchema>(endpointUrl)
        .then((res) => (res.status === 200 ? res.data : null)) // we can get no-content response
  );

  const attachText = query.data
    ? t('codeOfConduct.replaceFile')
    : t('codeOfConduct.attachFile');

  return (
    <div style={{ minHeight: '500px', minWidth: '900px' }}>
      <StyledTitle>{t('tabs.codeOfConduct')}</StyledTitle>
      <SingleFileUpload
        file={query.data || null}
        url={endpointUrl}
        attachButtonText={attachText}
        onSuccess={() => query.refetch()}
      />
      {/* only show the table if a CoC has been uploaded */}
      {query.data !== null && <CodeOfConductRequests />}
    </div>
  );
}
