import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';
import ProgressBar from 'src/components/ProgressBar';
import { StyledFlex } from 'src/components/StyledFlex';
import { EsgProgressBarViews as Tabs } from './types';
import EsgAdministrationPanelToggle from './components/Toggle';
import { StyledPanel } from '../styles';
import IndicatorsResponsibilityTable from './IndicatorsResponsibilityTable';
import { useEsgReports, useIndicators } from '../hooks';
import { useEsgOrganization } from 'src/common/hooks';
import FilterPanel from './FilterPanel';
import { getReportProgress } from './utils';
import PageLoading from 'src/components/PageLoading';
import SomethingWentWrong from 'src/components/SomethingWentWrong';

export default function EsgAdministratonPanel() {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'esg:administrationPanel',
  });
  const organization = useEsgOrganization();
  // const { reports, isLoading } = useReports(organization.id); // outdated?
  const {
    isLoading,
    _data: reports,
    isError,
  } = useEsgReports({
    esg_organization_id: organization.id,
  });

  const [activeReportId, setActiveReportId] = React.useState<number | null>(
    null
  );
  const report = reports.find((r) => r.id === activeReportId) || reports[0];

  const { indicators, answeredQuestions } = useIndicators(activeReportId);

  React.useEffect(() => {
    if (reports.length > 0) {
      setActiveReportId(reports[0].id);
    }
  }, [reports]);

  if (isError) return <SomethingWentWrong />;

  if (isLoading) return <PageLoading />;

  const reportProgress = getReportProgress(
    indicators,
    answeredQuestions,
    report
  );

  return (
    <Box sx={{ p: '16px 20px' }}>
      <Typography variant="h1">{t('title')}</Typography>
      <br />
      <br />
      <FilterPanel
        reports={reports}
        activeReport={report}
        setActiveReportId={setActiveReportId}
      />
      <br />
      <br />
      <Box key={report?.id}>
        <Typography variant="h3">
          {`${organization.name}: ${t('common.report')} (${report?.period})`}
        </Typography>
        <br />
        <br />
        <Typography variant="overlineText">{t('progress.title')}</Typography>
        <br />
        <br />
        <StyledFlex>
          <ProgressBar pct={reportProgress} steps={[0, 30, 60, 100]} />
          <EsgAdministrationPanelToggle
            value={Tabs.LIST}
            sx={{ ml: '10px', width: '600px' }}
          />
        </StyledFlex>
        <br />
        <br />
        <StyledPanel sx={{ minWidth: '1000px' }}>
          <IndicatorsResponsibilityTable report={report} />
        </StyledPanel>
      </Box>
    </Box>
  );
}
