import React from 'react';

import {
  Box,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { useGhgUserPermissions } from '../hooks';
import { parseGhgUserPermissonsObject } from '../utils';
import { StyledTableCell, StyledTableStickyCell } from '../styles';
import { ProductPlatform } from 'src/utils/api.interfaces';
import { useOrganizationMembers } from 'src/Superadmin/hooks';
import PageLoading from 'src/components/PageLoading';
import { useTranslation } from 'react-i18next';

interface Props {
  userId: number;
  year: number;
}

export default function GhgUserPermissionsTable(props: Props) {
  const { userId, year } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'superadmin:settings.tabs.users.usersTable.permissions',
  });

  const {
    isLoading,
    scopeSections,
    userOrganizations,
    ghgUserPermissons,
    options,
    handleChange,
  } = useGhgUserPermissions(userId);

  const { userOrganizationMap } = useOrganizationMembers();

  const permissions = parseGhgUserPermissonsObject(ghgUserPermissons);

  if (isLoading) return <PageLoading />;

  return (
    <Box sx={{ overflowX: 'scroll' }}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableStickyCell>{t('headers.scope')}</StyledTableStickyCell>
            {userOrganizations.map((organization) => (
              <StyledTableCell key={`head-${organization.id}`}>
                {organization.name}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {scopeSections.map((scopeSection) => {
            const key = `${scopeSection.scope}::${scopeSection.section}`;
            return (
              <TableRow key={key}>
                <StyledTableStickyCell>
                  {t(scopeSection.scope)}: {t(scopeSection.section)}
                </StyledTableStickyCell>
                {userOrganizations.map((organization) => {
                  const perm = permissions[year]?.[organization.id]?.[key];

                  const organizationMember =
                    userOrganizationMap[userId]?.[organization.id];

                  const disabled = !organizationMember?.products?.includes(
                    ProductPlatform.Co2
                  );
                  const memberRole = options.roles.find(
                    (r) => r[0] === organizationMember?.role
                  )?.[0];

                  const nullValue = memberRole ? (
                    <>
                      {t(`options.${memberRole}`)}&nbsp;
                      {t(`options.fromGeneralPermissions`)}
                    </>
                  ) : (
                    t(`options.accessDenied`)
                  );
                  return (
                    <StyledTableCell key={organization.id}>
                      <Select
                        fullWidth
                        disabled={disabled}
                        size="small"
                        value={perm?.role || '-'}
                        onChange={(e) =>
                          handleChange({
                            id: perm?.id,
                            organization_id: organization.id,
                            scope: scopeSection.scope,
                            section: scopeSection.section,
                            role: e.target.value,
                            app_user_id: userId,
                            year,
                          })
                        }
                      >
                        <MenuItem value="-">{nullValue}</MenuItem>
                        {options.roles.map((role) => (
                          <MenuItem key={role[0]} value={role[0]}>
                            {t(`options.${role[0]}`)}
                          </MenuItem>
                        ))}
                      </Select>
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
}
