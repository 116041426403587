import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {
  ListItemIcon,
  MenuItem,
  TableBody,
  TableRow,
  Typography,
} from '@mui/material';
import { useEsgUserPermissions } from '../hooks';
import { parseEsgUserPermissonsSubtopics } from '../utils';
import { StyledTableCell, StyledTableStickyCell } from '../styles';
import { StyledFlex } from 'src/components/StyledFlex';
import PermissionSelect from './PermissionSelect';
import IndicatorCollapse from './IndicatorCollapse';
import { QuestionnaireMethodology } from 'src/Esg/types';
import { useTranslation } from 'react-i18next';

interface Props {
  userId: number;
  questionnaireMethodology: QuestionnaireMethodology;
  reportYear: number | null;
}

export default function Body(props: Props) {
  const { t } = useTranslation();
  const { userId, questionnaireMethodology, reportYear } = props;
  const { options, userOrganizations } = useEsgUserPermissions(userId);
  const indicators = options.indicators.filter(
    (i) => i.questionnaire_methodology === questionnaireMethodology
  );
  const subtopics = parseEsgUserPermissonsSubtopics(
    indicators,
    options.indicator_categories,
    options.questions
  );

  const [expanded, setExpanded] = React.useState<number[]>([]);
  const handleExpandClick = (id: number, isExpanded: boolean) =>
    isExpanded
      ? setExpanded(expanded.filter((_id) => _id !== id))
      : setExpanded([...expanded, id]);

  return (
    <TableBody>
      {Object.values(subtopics).map((subtopic) => {
        const isExpanded = expanded.includes(subtopic.indicatorCategory.id);
        const Icon = isExpanded ? ExpandLessIcon : ExpandMoreIcon;

        const _handleExpandClick = () =>
          handleExpandClick(subtopic.indicatorCategory.id, isExpanded);

        const key = subtopic.indicatorCategory.id;

        return (
          <React.Fragment key={key}>
            {/* Indicator Category Row  */}
            <TableRow key={subtopic.indicatorCategory.id}>
              <StyledTableStickyCell sx={{ p: 0 }}>
                <MenuItem
                  onClick={_handleExpandClick}
                  sx={{ pt: '16px', pb: '16px', whiteSpace: 'normal' }}
                >
                  <StyledFlex sx={{ position: 'sticky', left: 0 }}>
                    <ListItemIcon>
                      <Icon sx={{ fontWeight: 600 }} />
                    </ListItemIcon>
                    <Typography sx={{ fontWeight: 600, textWrap: 'wrap' }}>
                      {subtopic.indicatorCategory.name}
                    </Typography>
                  </StyledFlex>
                </MenuItem>
              </StyledTableStickyCell>
              {userOrganizations.map((organization) => {
                const report = options.reports.find(
                  (r) =>
                    r.period === reportYear &&
                    r.questionnaire_methodology === questionnaireMethodology &&
                    r.esg_organization_id === organization.id
                );
                return !report ? (
                  <StyledTableCell key={key + organization.id}>
                    <Typography>
                      {t(
                        'superadmin:settings.tabs.users.usersTable.permissions.other.noReport'
                      )}
                    </Typography>
                  </StyledTableCell>
                ) : (
                  <StyledTableCell
                    key={key + organization.id}
                    sx={{ p: '8px' }}
                  >
                    <PermissionSelect
                      userId={userId}
                      organizationId={organization.id}
                      questionId={null}
                      indicatorId={null}
                      indicatorCategoryId={subtopic.indicatorCategory.id}
                      reportId={report.id}
                    />
                  </StyledTableCell>
                );
              })}
            </TableRow>

            <IndicatorCollapse
              subtopic={subtopic}
              isExpanded={isExpanded}
              userId={userId}
              reportYear={reportYear}
              questionnaireMethodology={questionnaireMethodology}
            />
          </React.Fragment>
        );
      })}
    </TableBody>
  );
}
