import React from 'react';
import { useLanguage } from 'src/common/hooks';
import {
  useEsgSubTopic,
  useEsgStakeholderAnswer,
  useEsgTopic,
} from 'src/Esg/Dmav2/hooks';
import CustomSelect from 'src/components/CustomSelect';

import { Divider, Typography } from '@mui/material';
import { StyledCell, StyledRow, StyledSubTopicCell } from './styles';
import {
  EsgStakeholderAnswerInSchema,
  EsgStakeholderAnswerOutSchema,
} from '../../types';
import { StyledFlex } from 'src/components/StyledFlex';
import { HelpOutline } from '@mui/icons-material';
import { decodeTokenFromParams } from '../utils';
import LightTooltipHtml from 'src/components/LightTooltipHtml';

interface QuestionnaireTableProps {
  esgStakeholderSurveyId?: number;
  readOnly?: boolean;
}

export default function QuestionnaireTable(props: QuestionnaireTableProps) {
  const { t } = useLanguage();
  const { stakeholderSurveySubtopics: esgSubTopics } = useEsgSubTopic();
  const { data: esgTopics } = useEsgTopic();

  // Decode the token if it exists
  const token_data = decodeTokenFromParams();

  // optional survey params for authenticated users
  const surveyParams = props.esgStakeholderSurveyId && {
    esg_stakeholder_survey_id: props.esgStakeholderSurveyId,
  };
  const {
    _data: answers,
    update,
    create,
    _delete: deleteAnswer,
  } = useEsgStakeholderAnswer(surveyParams || {});

  const changeAnswer = (
    data: EsgStakeholderAnswerInSchema | EsgStakeholderAnswerOutSchema
  ) => {
    if (props.readOnly) return;
    if ('id' in data && data.id) {
      if (data.positive === null && data.negative === null) {
        deleteAnswer.mutate(data.id);
      } else {
        update.mutate(data);
      }
    } else {
      create.mutate(data);
    }
  };

  const answerMap = new Map(
    answers.map((answer) => [answer.esg_dma_sub_topic_id, answer])
  );

  if (!esgTopics || !esgSubTopics) {
    return <div>Loading...</div>;
  }

  let topicsWithSubTopics = esgTopics.map((topic) => ({
    ...topic,
    subtopics: esgSubTopics.filter(
      (subtopic) => subtopic.topic_id.toString() === topic.id.toString()
    ),
  }));

  topicsWithSubTopics = topicsWithSubTopics.filter(
    (topic) => topic.subtopics.length > 0
  );

  const translatedColumn = (key: string) =>
    t(`esg:dma.survey.table.columns.${key}`);

  const getImpactOptions = [
    { value: 0, label: t('esg:dma.survey.table.selectOptions.0') },
    { value: 1, label: t('esg:dma.survey.table.selectOptions.1') },
    { value: 2, label: t('esg:dma.survey.table.selectOptions.2') },
    { value: 3, label: t('esg:dma.survey.table.selectOptions.3') },
    { value: null, label: t('esg:dma.survey.table.selectOptions.null') },
  ];

  // Ensure we have a number for esg_stakeholder_survey_id
  const esg_stakeholder_survey_id =
    token_data?.data?.esg_stakeholder_survey_id ??
    props.esgStakeholderSurveyId ??
    0;

  return (
    <div style={{ width: '100%' }}>
      <Divider />
      {/* Header */}
      <StyledRow>
        <StyledSubTopicCell>
          <Typography variant="captionText">
            {translatedColumn('subtopic')}
          </Typography>
        </StyledSubTopicCell>
        <StyledCell>
          <Typography variant="captionText">
            {translatedColumn('positiveImpact')}
          </Typography>
        </StyledCell>
        <StyledCell>
          <Typography variant="captionText">
            {translatedColumn('negativeImpact')}
          </Typography>
        </StyledCell>
      </StyledRow>

      {/* Data */}
      {topicsWithSubTopics.map((topic) => (
        <React.Fragment key={topic.id}>
          {/* Topic Row */}
          <div
            style={{
              backgroundColor: topic.color,
              height: '40px',
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '24px',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h6">
              {topic.key}: {topic.name}
            </Typography>
            {topic.hint && (
              <LightTooltipHtml title={topic.hint} placement="right-end">
                <HelpOutline fontSize="small" sx={{ mx: 1 }} />
              </LightTooltipHtml>
            )}
          </div>

          {/* Subtopic Rows */}
          {topic.subtopics.map((subtopic) => {
            const answer = answerMap.get(subtopic.id) || {
              positive: null,
              negative: null,
              id: null,
            };

            const baseRow = {
              positive: answer.positive,
              negative: answer.negative,
              esg_dma_sub_topic_id: subtopic.id,
              esg_dma_topic_id: subtopic.topic_id,
              esg_stakeholder_survey_id,
            };

            // Construct the row object
            let row:
              | EsgStakeholderAnswerInSchema
              | EsgStakeholderAnswerOutSchema = { ...baseRow };

            if (answer.id !== null) {
              row = { ...row, id: answer.id };
            }

            return (
              <React.Fragment key={subtopic.id}>
                <Divider />
                <StyledRow>
                  <StyledSubTopicCell>
                    <StyledFlex>
                      <Typography variant="subtitle2">
                        {subtopic.description}
                      </Typography>
                      {subtopic.hint && (
                        <LightTooltipHtml
                          title={subtopic.hint}
                          placement="right-end"
                        >
                          <HelpOutline fontSize="small" sx={{ mx: 1 }} />
                        </LightTooltipHtml>
                      )}
                    </StyledFlex>
                  </StyledSubTopicCell>
                  <StyledCell>
                    <CustomSelect
                      key={`${row.esg_dma_sub_topic_id}-positive`}
                      value={row.positive}
                      options={getImpactOptions}
                      onChange={(value) =>
                        changeAnswer({ ...row, positive: value })
                      }
                      disabled={props.readOnly}
                    />
                  </StyledCell>
                  <StyledCell>
                    <CustomSelect
                      key={`${row.esg_dma_sub_topic_id}-negative`}
                      value={row.negative}
                      options={getImpactOptions}
                      onChange={(value) =>
                        changeAnswer({ ...row, negative: value })
                      }
                      disabled={props.readOnly}
                    />
                  </StyledCell>
                </StyledRow>
              </React.Fragment>
            );
          })}
        </React.Fragment>
      ))}
    </div>
  );
}
