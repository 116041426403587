import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import React from 'react';
import { StyledPanel } from 'src/components/StyledPanel';

import { toast } from 'react-toastify';
import NavigationBlockerModal from 'src/components/NavigationBlockerModal';
import { useAllCountries, useCbamOrganization } from 'src/common/hooks';
import SingleFileUpload from 'src/components/SingleFileUpload';
import { useLocation } from 'react-router-dom';
import {
  CbamSupportingDocumentForImportedGoodInSchema,
  CbamSupportingDocumentForImportedGoodOutSchema,
  CbamSupportingDocumentForProductionProcessInSchema,
  CbamSupportingDocumentForProductionProcessOutSchema,
  SupportingDocumentForImportedGoodType,
  SupportingDocumentForProductionProcessType,
  SupportingDocumentUsage,
} from '../types';
import { useTranslation } from 'react-i18next';
import {
  useSupportingDocumentsForImportedGoods,
  useSupportingDocumentsForProductionProcesses,
} from '../hooks';
import { FormErrors, requiredFields, validateForm } from './FormValidation';
import { extractSchemaErrors, mapError } from 'src/utils/validation';
import {
  StyledFormControl,
  StyledSectionDivider,
  StyledSectionHeader,
  StyledTextField,
} from '../styles';
import { UploadedFileSchema } from 'src/Ghg/CodeOfConductSurvey/types';
import { DatePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import { dateFormat } from 'src/common/constants';

interface Props {
  id: number;
  onSaved?: () => void;
}

export default function SupportingDocumentForm(props: Props) {
  const { id, onSaved } = props;
  const { id: organizationId } = useCbamOrganization();
  const { t } = useTranslation(undefined, {
    keyPrefix: 'cbam:supportingDocuments',
  });

  const location = useLocation();
  const usage = location.state.usage as SupportingDocumentUsage;

  const { data, create, update } =
    usage === SupportingDocumentUsage.ForImportedGood
      ? useSupportingDocumentsForImportedGoods()
      : useSupportingDocumentsForProductionProcesses();

  const documentId = Number(id);
  const document = (data as any).find(
    (document: any) => document.id === documentId
  );

  const { data: countries } = useAllCountries();

  const [errors, setErrors] = React.useState<FormErrors>({});

  const [form, setForm] = React.useState<
    | CbamSupportingDocumentForImportedGoodOutSchema
    | CbamSupportingDocumentForImportedGoodInSchema
    | CbamSupportingDocumentForProductionProcessOutSchema
    | CbamSupportingDocumentForProductionProcessInSchema
  >({
    cbam_organization_id: organizationId,
    reference_number: '',
    country: '',
    line_item_number: null,
    issuing_authority_name: '',
    validity_start_date: null,
    validity_end_date: null,
    description: '',
    attachment: null,

    type: null,
  });

  const [isModified, setIsModified] = React.useState(false);

  const handleFormChange = (
    key: string,
    value: string | number | UploadedFileSchema | null
  ) => {
    const newForm = {
      ...form,
      [key]: value,
    };
    setForm(newForm);
    setErrors(validateForm(newForm, t));
    setIsModified(true);
  };

  const handleFormDateChange = (key: string, value: Date | null) => {
    const newValue = !!value ? format(value, dateFormat) : null;
    const newForm = {
      ...form,
      [key]: newValue,
    };
    setForm(newForm);
    setErrors(validateForm(newForm, t));
    setIsModified(true);
  };

  React.useEffect(() => {
    if (document) setForm(document);
  }, [document]);

  const save = !document ? create.mutateAsync : update.mutateAsync;

  const isError = (
    fieldName:
      | keyof CbamSupportingDocumentForImportedGoodInSchema
      | keyof CbamSupportingDocumentForProductionProcessInSchema
  ) => !!form[fieldName] && !!mapError(errors, fieldName, form[fieldName]);
  const errorText = (
    fieldName:
      | keyof CbamSupportingDocumentForImportedGoodInSchema
      | keyof CbamSupportingDocumentForProductionProcessInSchema
  ) =>
    isError(fieldName) && (mapError(errors, fieldName, form[fieldName]) || '');

  const handleSaveClick = () => {
    setIsModified(false);
    save(form as any)
      .then(() => onSaved?.())
      .catch((err: any) => {
        setIsModified(true);
        toast.error('Failed to save');
        const extractedErrors = extractSchemaErrors(err, form);
        setErrors(extractedErrors);
      });
  };

  return (
    <Box>
      <NavigationBlockerModal shouldBlock={isModified} />
      <StyledPanel>
        <StyledSectionHeader>{t(`about`)}</StyledSectionHeader>
        <StyledTextField
          label={t(`form.reference_number`)}
          value={form.reference_number}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleFormChange('reference_number', e.currentTarget.value);
          }}
          required={requiredFields.includes('reference_number')}
          error={isError('reference_number')}
          helperText={errorText('reference_number')}
        />

        <StyledFormControl required={requiredFields.includes('type')}>
          <InputLabel id="type">{t(`form.type`)}</InputLabel>
          <Select
            labelId="type"
            label={t(`form.type`)}
            value={form.type || ''}
            onChange={(e) => handleFormChange('type', e.target.value)}
          >
            {usage === SupportingDocumentUsage.ForImportedGood &&
              Object.values(SupportingDocumentForImportedGoodType).map(
                (type) => (
                  <MenuItem key={type} value={type}>
                    {t(`types.${type}`)} ({type})
                  </MenuItem>
                )
              )}
            {usage === SupportingDocumentUsage.ForProductionProcess &&
              Object.values(SupportingDocumentForProductionProcessType).map(
                (type) => (
                  <MenuItem key={type} value={type}>
                    {t(`types.${type}`)} ({type})
                  </MenuItem>
                )
              )}
          </Select>
        </StyledFormControl>

        <StyledFormControl>
          <InputLabel id="country">{t(`form.country`)}</InputLabel>
          <Select
            labelId="country"
            label={t(`form.country`)}
            value={form.country}
            onChange={(e) => handleFormChange('country', e.target.value)}
          >
            <MenuItem value="">None</MenuItem>
            {countries.map((country) => (
              <MenuItem key={country.iso_code} value={country.iso_code}>
                {country.name}
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>

        <StyledTextField
          label={t(`form.line_item_number`)}
          value={form.line_item_number || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleFormChange(
              'line_item_number',
              Number(e.currentTarget.value) || null // TODO: ugly hack to only allow integers
            );
          }}
          required={requiredFields.includes('line_item_number')}
          error={isError('line_item_number')}
          helperText={errorText('line_item_number')}
        />

        <StyledTextField
          label={t(`form.issuing_authority_name`)}
          value={form.issuing_authority_name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleFormChange('issuing_authority_name', e.currentTarget.value);
          }}
          required={requiredFields.includes('issuing_authority_name')}
          error={isError('issuing_authority_name')}
          helperText={errorText('issuing_authority_name')}
        />

        <StyledFormControl>
          <DatePicker
            label={t(`form.validity_start_date`)}
            value={
              form.validity_start_date
                ? new Date(form.validity_start_date)
                : null
            }
            onChange={(date) =>
              handleFormDateChange('validity_start_date', date)
            }
            slotProps={{
              textField: {
                // preventing manual input typing as onChange makes instant request to backend
                onKeyDown: (e) => e.preventDefault(),
              },
            }}
          />
        </StyledFormControl>

        <StyledFormControl error={isError('validity_end_date')}>
          <DatePicker
            label={t(`form.validity_end_date`)}
            value={
              form.validity_end_date ? new Date(form.validity_end_date) : null
            }
            onChange={(date) => handleFormDateChange('validity_end_date', date)}
            slotProps={{
              textField: {
                onKeyDown: (e) => e.preventDefault(),
              },
            }}
          />
          {isError('validity_end_date') && (
            <Typography color="error">
              {errorText('validity_end_date')}
            </Typography>
          )}
        </StyledFormControl>

        <StyledTextField
          label={t(`form.description`)}
          value={form.description}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleFormChange('description', e.currentTarget.value);
          }}
          required={requiredFields.includes('description')}
          error={isError('description')}
          helperText={errorText('description')}
          multiline
          rows={3}
        />

        <StyledSectionDivider />
        <StyledSectionHeader>{t(`attachment`)}</StyledSectionHeader>
        <Typography sx={{ mb: '18px', fontStyle: 'italic' }}>
          {t(`attachmentInfo`)}
        </Typography>

        <SingleFileUpload
          url={`web/cbam/attachments?cbam_organization_id=${organizationId}`}
          file={form.attachment}
          onSuccess={(attachment) => handleFormChange('attachment', attachment)}
        />
      </StyledPanel>
      <br />
      <br />
      <Button
        sx={{ float: 'right' }}
        onClick={handleSaveClick}
        disabled={!isModified || Object.keys(validateForm(form, t)).length > 0}
      >
        Save changes
      </Button>
    </Box>
  );
}
