import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { Button, Dialog, IconButton } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import CloseIcon from '@mui/icons-material/Close';

import { StyledPanel } from 'src/Esg/styles';
import CustomMuiSelect from 'src/components/CustomMuiSelect';
import { useLanguage } from 'src/common/hooks';
import { StakeholdersSurveySummarySchema } from 'src/Esg/Dmav2/types';
import colors from 'src/theme/colors';

import { SortType, useSortedAnalyticsData } from './hooks';
import { sortOptions } from './utils';
import {
  GridRow,
  GridHeader,
  GridItem,
  StyledText,
  StyledTitle,
  FunctionalHeader,
} from './index.styles';
import { StyledTextLight } from '../styles';
import {
  useEsgStakeholdersAnswersAnalytics,
  useEsgSubTopic,
  useEsgTopic,
} from 'src/Esg/Dmav2/hooks';
import { useEsgReports } from 'src/Esg/hooks';

const formatValue = (
  value: number | null | undefined,
  fallback: string = '-'
) => value?.toFixed(2) ?? fallback;

/**
 * Calculates an RGBA color string based on the given value and RGB color.
 * Returns fully transparent color for null values.
 *
 * @param {number | null} value - Used to determine opacity, ranging from 0.2 (for 0) to 1 (for 10).
 * @param {string} colorRGB - Base RGB values as "99, 211, 164,".
 * @returns {string} RGBA color string.
 */
const getBackgroundColor = (value: number | null, colorRGB: string): string => {
  const startAlpha = 0.2;
  const endAlpha = 1;

  if (value === null) {
    return 'rgba(0, 0, 0, 0)';
  }

  const alpha = startAlpha + ((endAlpha - startAlpha) * value) / 10;

  return `rgba(${colorRGB} ${alpha})`;
};

interface ScoreGridItemProps {
  score: number | null;
  colorRGB: string;
}

function ScoreGridItem({ score, colorRGB }: ScoreGridItemProps) {
  return (
    <GridItem
      style={{
        width: '56px',
        height: '32px',
        borderRadius: '8px',
        backgroundColor: getBackgroundColor(score, colorRGB),
        color: '#38414f',
        fontFamily: 'Poppins',
        fontSize: '13px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '20px',
        letterSpacing: '0.26px',
        border: 'none',
      }}
    >
      {formatValue(score)}
    </GridItem>
  );
}

export default function StakeholdersImpactTable() {
  const { t, language } = useLanguage({
    keyPrefix: 'esg:dma.stakeholderAnalytics.dashboard',
  });

  const { reportId } = useParams();
  const { _instance: report } = useEsgReports({ id: Number(reportId) });
  const { analytics } = useEsgStakeholdersAnswersAnalytics(report?.esg_dma_id);
  const { subTopics, subTopicsMap } = useEsgSubTopic();
  const { topicsMap } = useEsgTopic();

  const [open, setOpen] = useState<boolean>(false);
  const [sort, setSort] = useState<SortType>('positive');
  const handleSortChange = (value: any) => {
    setSort(value);
  };

  const sortedData = useSortedAnalyticsData(subTopics, analytics, sort);

  return (
    <>
      <StyledPanel>
        <StyledTitle>{t('impactTable.title')}</StyledTitle>
        <StyledTextLight>{t('impactTable.subtitle')}</StyledTextLight>
        <FunctionalHeader>
          <CustomMuiSelect
            label={t('impactTable.sorting')}
            options={sortOptions}
            value={sort}
            onChange={(value) => handleSortChange(value)}
          />
          <Button
            variant="secondary"
            sx={{ marginRight: '-2px' }}
            startIcon={<HelpIcon sx={{ color: colors.esgMain }} />}
            onClick={() => setOpen(true)}
          >
            {t('howWeCalculate')}
          </Button>
        </FunctionalHeader>
        <div
          style={{
            paddingTop: '28px',
            width: '100%',
            display: 'block',
          }}
        >
          <GridHeader>
            <GridItem style={{ backgroundColor: '#F8F8F8' }}>
              <StyledText>{t('impactTable.number')}</StyledText>
            </GridItem>
            <GridItem
              style={{ backgroundColor: '#F8F8F8', justifyContent: 'start' }}
            >
              <StyledText>{t('impactTable.topic')}</StyledText>
            </GridItem>
            <GridItem>
              <StyledText>{t('impactTable.positiveImpactScale')}</StyledText>
            </GridItem>
            <GridItem>
              <StyledText>{t('impactTable.negativeImpactScale')}</StyledText>
            </GridItem>
          </GridHeader>

          {sortedData.map((surveySummary: StakeholdersSurveySummarySchema) => {
            const subTopic = subTopicsMap[surveySummary.esg_dma_sub_topic_id];
            const topic = topicsMap[subTopic?.topic_id];
            if (topic === undefined) return null;
            return (
              <GridRow key={topic.key}>
                <GridItem style={{ backgroundColor: '#F8F8F8' }}>
                  <StyledText style={{ textAlign: 'start' }}>
                    {topic.key}
                  </StyledText>
                </GridItem>
                <GridItem
                  style={{
                    backgroundColor: '#F8F8F8',
                    justifyContent: 'start',
                  }}
                >
                  <StyledText style={{ textAlign: 'start' }}>
                    {subTopic.description}
                  </StyledText>
                </GridItem>
                <GridItem>
                  <StyledText>
                    <ScoreGridItem
                      score={surveySummary.positive_avg}
                      colorRGB="99, 211, 164,"
                    />
                  </StyledText>
                </GridItem>
                <GridItem>
                  <StyledText>
                    <ScoreGridItem
                      score={surveySummary.negative_avg}
                      colorRGB="245, 110, 110,"
                    />
                  </StyledText>
                </GridItem>
              </GridRow>
            );
          })}
        </div>
      </StyledPanel>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="lg">
        <IconButton
          sx={{
            position: 'absolute',
            right: '10px',
            top: '10px',
          }}
          onClick={() => setOpen(false)}
        >
          <CloseIcon sx={{ fontSize: '24px' }} />
        </IconButton>
        <ReactSVG
          src={`https://assets.envirly.pl/dma/stakeholders/${language}/impact_calculation.svg`}
        />
      </Dialog>
    </>
  );
}
