import React, { useState } from 'react';

import { useAppSelector } from '../../../redux-file/hooks';
import Stats, { useValidTimeRange } from '../../components/Dashboard/Stats';
import { ContextlessBox as Box } from '../../components/Box';
import { start } from '../Dashboard';
import GraphComponent from '../../components/Dashboard/GraphComponent';
import { timeConverter } from '../../../utils/timeConverter';
import { Text } from './index.styles';
import { useTranslation } from 'react-i18next';
import GroupChart from '../../components/Dashboard/GroupChart';
import TableChart from '../../components/Dashboard/TableChart';
import PageLoading from '../../../components/PageLoading';
import { client } from 'src/utils/api-client';
import { useQuery } from 'react-query';
import { OrganizationDataOutDetailedSchema } from 'src/utils/api.interfaces';
import SomethingWentWrong from 'src/components/SomethingWentWrong';
import { useLanguage } from 'src/common/hooks';

const SuperDashboard = () => {
  const { t, language } = useLanguage();
  const selectedCompanies = useAppSelector(
    (state) => state.ghgSuperdashboard.selectedCompanies
  );

  const { data: organizations } = useQuery('superdashboard-organizations', () =>
    client
      .get<OrganizationDataOutDetailedSchema[]>('/web/organizations')
      .then((res) => res.data.filter((org) => !!org.co2))
  );
  const selectedOrgIds =
    organizations &&
    selectedCompanies.filter((id) =>
      organizations.some((org) => org.id === +id)
    );

  const [startTime, setStartTime] = useState<Date | null>(start);
  const [endTime, setEndTime] = useState<Date | null>(new Date());
  const timeRange = useValidTimeRange(startTime, endTime);
  const queryEnabled = selectedOrgIds !== undefined && !!timeRange;
  const reportingFreq = organizations?.[0].co2!.reporting_freq || 'M';
  const {
    data: dashboardStats,
    isLoading: dashboardStatsLoading,
    isError,
  } = useQuery(
    ['superdashboard-stats', selectedOrgIds, timeRange, language],
    async () => {
      const start = timeConverter(timeRange?.start, reportingFreq);
      const end = timeConverter(timeRange?.end, reportingFreq);

      const res = await client.post(
        `/web/v2/superdashboard_stats_and_figures?start=${start}&end=${end}&max_org_count=15`,
        {
          organization_ids: selectedOrgIds,
        }
      );
      return res.data;
    },
    {
      enabled: queryEnabled,
      cacheTime: 0,
    }
  );

  if (isError) return <SomethingWentWrong />;
  if (dashboardStats === undefined) return <PageLoading />;

  const {
    carbon_footprint: carbonFootprint,
    total_emissions_by_scope: totalEmissionsByScope,
    emission_ranking_scope_3: SAEmissionRankingScope3,
    scope_1_by_org: SAScope1,
    scope_2_by_org: SAScope2,
    scope_3_by_org: SAScope3,
    scope_footprint_by_org: SAEmissionsPerScopeBar,
    scope_footprint_by_org_table: SAEmissionsPerScopeTable,
    emission_ranking_by_org: SATheMostEmissiveCompanies,
    carbon_footprint_by_org: SAGroupsCarbonFootprint,
  } = dashboardStats.figures;

  return (
    <>
      <Stats
        reportingPeriod={reportingFreq}
        calendar
        startTime={startTime}
        setStartTime={setStartTime}
        endTime={endTime}
        setEndTime={setEndTime}
        data={dashboardStats.stats.stats}
        dataChanges={dashboardStats.stats.changes}
      />

      <Text>{t('superadmin:text')}</Text>
      <Box
        dashboard
        text="dashboard.carbon-footprint"
        reportingFreq={reportingFreq}
      >
        <GraphComponent
          plotData={carbonFootprint.value}
          error={false}
          loading={dashboardStatsLoading}
          errorType={carbonFootprint.error_type}
          errorMsg={carbonFootprint.error_msg}
        />
      </Box>
      <Box
        dashboard
        text="dashboard.total-emission"
        reportingFreq={reportingFreq}
      >
        <GraphComponent
          plotData={totalEmissionsByScope.value}
          error={isError}
          loading={dashboardStatsLoading}
          errorType={totalEmissionsByScope.error_type}
          errorMsg={totalEmissionsByScope.error_msg}
        />
      </Box>

      <Box
        dashboard
        text="dashboard.emission-ranking"
        reportingFreq={reportingFreq}
      >
        <GraphComponent
          plotData={SAEmissionRankingScope3.value}
          error={isError}
          loading={dashboardStatsLoading}
          errorType={SAEmissionRankingScope3.error_type}
          errorMsg={SAEmissionRankingScope3.error_msg}
        />
      </Box>

      <Text>{t('superadmin:text2')}</Text>

      <Box
        dashboard
        text="superadmin:chart.scope-1"
        reportingFreq={reportingFreq}
      >
        <GraphComponent
          plotData={SAScope1.value}
          error={isError}
          loading={dashboardStatsLoading}
          errorType={SAScope1.error_type}
          errorMsg={SAScope1.error_msg}
        />
      </Box>

      <Box
        dashboard
        text="superadmin:chart.scope-2"
        reportingFreq={reportingFreq}
      >
        <GraphComponent
          plotData={SAScope2.value}
          error={isError}
          loading={dashboardStatsLoading}
          errorType={SAScope2.error_type}
          errorMsg={SAScope2.error_msg}
        />
      </Box>

      <Box
        dashboard
        text="superadmin:chart.scope-3"
        reportingFreq={reportingFreq}
      >
        <GraphComponent
          plotData={SAScope3.value}
          error={isError}
          loading={dashboardStatsLoading}
          errorType={SAScope3.error_type}
          errorMsg={SAScope3.error_msg}
        />
      </Box>

      <Box
        text="dashboard.carbon-footprint-rank"
        dashboard
        reportingFreq={reportingFreq}
      >
        <GraphComponent
          plotData={SATheMostEmissiveCompanies.value}
          error={isError}
          loading={dashboardStatsLoading}
          errorType={SATheMostEmissiveCompanies.error_type}
          errorMsg={SATheMostEmissiveCompanies.error_msg}
        />
      </Box>

      {Object.keys(SAGroupsCarbonFootprint).length < 15 && (
        <Box
          dashboard
          text="dashboard.carbon-footprint-group"
          reportingFreq={reportingFreq}
        >
          <GroupChart
            loading={dashboardStatsLoading}
            data={SAGroupsCarbonFootprint}
            errorType={SAGroupsCarbonFootprint.error_type}
            errorMsg={SAGroupsCarbonFootprint.error_msg}
          />
        </Box>
      )}

      <Box
        dashboard
        text="dashboard.companys-emission-scope"
        reportingFreq={reportingFreq}
      >
        <GraphComponent
          plotData={SAEmissionsPerScopeBar.value}
          error={isError}
          loading={dashboardStatsLoading}
          errorType={SAEmissionsPerScopeBar.error_type}
          errorMsg={SAEmissionsPerScopeBar.error_msg}
        />
      </Box>

      <Box
        dashboard
        text="dashboard.companys-emission-scope"
        reportingFreq={reportingFreq}
      >
        <TableChart
          data={SAEmissionsPerScopeTable}
          loading={dashboardStatsLoading}
        />
      </Box>
    </>
  );
};

export default SuperDashboard;
