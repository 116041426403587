import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Breadcrumbs, ThemeProvider, Typography } from '@mui/material';

import TopBar from '../components/TopBar';
import { StyledContainer } from './index.styles';
import superadminTheme from './theme';
import NewUnit from './Unit/NewUnit';
import EditUnit from './Unit/EditUnit';
import NewUser from './User/NewUser';
import Settings from './Settings';
import { useTranslation } from 'react-i18next';
import EditUser from './User/EditUser';
import MyAccount from './MyAccount';
import Layout from '../views/components/Layout';

const getBreadcrumbs = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // a hack to prohibit from breadcrumb navigation from my-account to org/user settings
  // without moving it out of covenient wrappers
  if (window.location.pathname.includes('superadmin/my-account'))
    return <div />;

  const breadcrumbs = window.location.pathname
    .split('/')
    .filter((segment) => !!segment);
  if (breadcrumbs.length === 1 && breadcrumbs.includes('superadmin'))
    return <div />;

  return breadcrumbs.map((segment) => {
    const segmentPath = window.location.pathname.split(segment)[0] + segment;
    const handleNavigation = () => navigate(segmentPath);

    const translatedSegment = t(`superadmin:breadcrumbs.${segment}`, '');
    if (!translatedSegment) return null; // skipping unregistered (not translated) path segments

    return (
      <Typography
        key={segment}
        sx={{
          cursor: 'pointer',
          fontSize: '12px',
          lineHeight: '36px',
          letterSpacing: '0.12px',
          color: '#4D4D4D',
          fontWeight: 500,
        }}
        onClick={handleNavigation}
      >
        {translatedSegment}
      </Typography>
    );
  });
};

const Superadmin = () => {
  return (
    <ThemeProvider theme={superadminTheme}>
      <Layout topbar={<TopBar />}>
        <Breadcrumbs separator=">" sx={{ ml: '5%', mt: '7px' }}>
          {getBreadcrumbs()}
        </Breadcrumbs>
        <StyledContainer>
          <Routes>
            <Route path="/my-account" element={<MyAccount />} />
            <Route path="/org-structure/create-unit" element={<NewUnit />} />
            <Route path="/org-structure/unit/:id" element={<EditUnit />} />
            <Route path="/users/create-user" element={<NewUser />} />
            <Route path="/users/:id" element={<EditUser />} />
            <Route path="/*" element={<Settings />} />
          </Routes>
        </StyledContainer>
      </Layout>
    </ThemeProvider>
  );
};

export default Superadmin;
