import React from 'react';
import DmaStage from '../components/DmaStage';
import DmaStep from '../components/DmaStep';
import { useLanguage } from 'src/common/hooks';
import { ImageSlider } from '../components/ImageSlider';
import { generateImageUrls } from '../utils';
import LightTooltip from 'src/components/LightTooltip';
import { Link } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { Analytics, Groups } from '@mui/icons-material';

export default function Stage4() {
  const { t, language: l } = useLanguage({ keyPrefix: 'esg:dma' });

  return (
    <DmaStage title={t('stagesV2.interestedParties.title')}>
      <DmaStep
        title={t('stagesV2.interestedParties.steps.1')}
        videoComponent={<ImageSlider images={generateImageUrls(l, 6, 8)} />}
      />
      <DmaStep
        title={t('stagesV2.interestedParties.steps.2')}
        navigateToComponent={
          <LightTooltip title={t('step.stakeholdersButton.toggle')}>
            <Link to="./stakeholders/selecting-stakeholders">
              <IconButton>
                <Groups />
              </IconButton>
            </Link>
          </LightTooltip>
        }
      />
      <DmaStep
        title={t('stagesV2.interestedParties.steps.3')}
        navigateToComponent={
          <LightTooltip title={t('step.analyticsButton.toggle')}>
            <Link to="./stakeholders/analytics/dashboard">
              <IconButton>
                <Analytics />
              </IconButton>
            </Link>
          </LightTooltip>
        }
      />
      <DmaStep
        title={t('stagesV2.interestedParties.steps.4')}
        navigateToComponent={
          <LightTooltip title={t('step.analyticsButton.toggle')}>
            <Link to="./stakeholders/analytics/dashboard">
              <IconButton>
                <Analytics />
              </IconButton>
            </Link>
          </LightTooltip>
        }
      />
      <DmaStep title={t('stagesV2.interestedParties.steps.5')} />
      <DmaStep
        title={t('stagesV2.interestedParties.steps.5_1')}
        substep
        navigateTo="../../questionnaires/SBM-1"
      />
      <DmaStep
        title={t('stagesV2.interestedParties.steps.5_2')}
        substep
        navigateTo="../../questionnaires/SBM-2"
      />
    </DmaStage>
  );
}
