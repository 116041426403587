import React from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import { List, ListItemIcon, ListItemText } from '@mui/material';
import { ListAlt, DashboardOutlined } from '@mui/icons-material';
import TableChartIcon from '@mui/icons-material/TableChart';

import { useAppSelector } from 'src/redux-file/hooks';
import LegacyNavItem from 'src/views/components/Sidebar/LegacyNavItem';
import SidebarNavItem from 'src/views/components/Sidebar/NavItem';
import { getOrganizationReports } from 'src/Esg/api';
import EsgSidebar from '../Sidebar';
import { hasTag, OrganizationTag } from 'src/utils/tags';

export default function EsgOrganizationSidebar() {
  const { t } = useTranslation('esg');
  const organization = useAppSelector((s) => s.platform.activeOrganization);

  const allOrganizations = useAppSelector((s) => s.platform.organizations);
  const isRootOrganization =
    allOrganizations?.find((org) => org.id === organization?.id)?.parent_id ===
    null;

  const { data } = useQuery(
    ['reports', { organizationId: organization?.id }],
    () => getOrganizationReports({ organizationId: organization!.id }),
    { enabled: organization !== null }
  );

  const reports = data || [];
  // order reports, newest first
  const orderedReports = [...reports].sort((a, b) => +b.period - +a.period);

  const hasConsolidationAccess =
    !!organization &&
    hasTag(organization, OrganizationTag.EsgConsolidation) &&
    isRootOrganization;

  return (
    <EsgSidebar>
      <List>
        {!!reports.length && (
          <>
            <SidebarNavItem to="/esg/administration-panel">
              <ListItemIcon>
                <DashboardOutlined />
              </ListItemIcon>
              <ListItemText primary={t('administrationPanel.title')} />
            </SidebarNavItem>
            {hasConsolidationAccess && (
              <SidebarNavItem to="/esg/data-visualization">
                <ListItemIcon>
                  <TableChartIcon />
                </ListItemIcon>
                <ListItemText primary={t('dataVisualization.sidebarTitle')} />
              </SidebarNavItem>
            )}
          </>
        )}
        <LegacyNavItem name="esg-reports" path="/esg/reports" />
        {orderedReports.map((report) => (
          <SidebarNavItem
            key={report.id}
            to={`/esg/reports/${report.id}`}
            matchTo={`/esg/reports/${report.id}/*`}
          >
            <ListItemIcon>
              <ListAlt />
            </ListItemIcon>
            <ListItemText primary={`${report.name} (${report.period})`} />
          </SidebarNavItem>
        ))}
      </List>
    </EsgSidebar>
  );
}
