import React from 'react';

import { Box, Button, Dialog, IconButton, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';

import { Trans, useTranslation } from 'react-i18next';
import LightTooltip from 'src/components/LightTooltip';
import { StyledDialogTitle, StyledDialogSubtitle } from './index.styles';
import { StyledFlex } from 'src/components/StyledFlex';
import { client } from 'src/utils/api-client';
import { useMutation } from 'react-query';
import PageLoading from 'src/components/PageLoading';

interface Props {
  organizationId: string;
  reportId: string | undefined;
  stakeholderTypeId: number;
  stakeholderName: string;
  disabled: boolean;
  useLegacyDma?: boolean;
}

export default function SendFormModal({
  organizationId,
  reportId,
  stakeholderTypeId,
  stakeholderName,
  disabled,
  useLegacyDma,
}: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState<boolean>(false);
  const [tooltipOpen, setTooltipOpen] = React.useState<boolean>(false);

  const createTokenV1 = () =>
    client.post<string>(
      `/web/esg_stakeholders/${organizationId}/${reportId}/stakeholders/${stakeholderTypeId}/token`
    );
  const createTokenV2 = () =>
    client.post<string>('/web/esg/v3/stakeholders-survey-token', {
      esg_report_id: reportId,
      esg_stakeholder_type_id: stakeholderTypeId,
    });

  const requestToken = useMutation(() =>
    (useLegacyDma ? createTokenV1() : createTokenV2()).then((res) => res.data)
  );

  const handleOpen = () => {
    setOpen(true);
    requestToken.mutate();
  };
  const handleClose = () => setOpen(false);

  const token = requestToken.data;
  const stakeholderUrlWithToken = `${window.location.origin}/stakeholders/${token}`;

  const handleLinkCopyClick = () => {
    setTooltipOpen(true);
    navigator.clipboard.writeText(stakeholderUrlWithToken);
    setTimeout(() => {
      setTooltipOpen(false);
    }, 1000);
  };

  return (
    <>
      <LightTooltip
        title={t('esg:dma.selectingStakeholders.table.columns.sendTooltip')}
      >
        <IconButton onClick={handleOpen} disabled={disabled}>
          <SendIcon />
        </IconButton>
      </LightTooltip>
      <Dialog open={open} onClose={handleClose} maxWidth="lg">
        <IconButton
          sx={{
            position: 'absolute',
            right: '10px',
            top: '10px',
          }}
          onClick={handleClose}
        >
          <CloseIcon sx={{ fontSize: '24px' }} />
        </IconButton>
        <Box sx={{ width: '950px' }}>
          <StyledDialogTitle>
            <Trans i18nKey="esg:dma.selectingStakeholders.table.modals.sendForm.title" />
            {stakeholderName}
          </StyledDialogTitle>
          <StyledDialogSubtitle>
            <Trans i18nKey="esg:dma.selectingStakeholders.table.modals.sendForm.subtitle" />
          </StyledDialogSubtitle>
          {!token ? (
            <PageLoading />
          ) : (
            <StyledFlex sx={{ p: '0px 24px 24px 24px' }}>
              <TextField fullWidth value={stakeholderUrlWithToken} />
              <LightTooltip
                open={tooltipOpen}
                title={t(
                  'esg:dma.selectingStakeholders.table.modals.sendForm.buttons.copyTooltip'
                )}
              >
                <Button
                  sx={{ ml: '10px', minWidth: '200px' }}
                  onClick={handleLinkCopyClick}
                  disabled={disabled}
                >
                  {t(
                    'esg:dma.selectingStakeholders.table.modals.sendForm.buttons.copy'
                  )}
                </Button>
              </LightTooltip>
            </StyledFlex>
          )}
        </Box>
      </Dialog>
    </>
  );
}
