import React from 'react';
import { GhgDataEntryCompletedType } from './interfaces';
import { useGhgDataEntriesCompleted } from './hooks';
import { useGhgOrganization } from 'src/common/hooks';
import { timeConverter } from 'src/utils/timeConverter';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormGroupProps,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

interface Props extends FormGroupProps {
  selectedDate: Date;
  type: GhgDataEntryCompletedType;
}

const dateFormat = 'dd.MM.yyyy';

export default function DataEntryCompletedCheckbox(props: Props) {
  const { selectedDate, type } = props;
  const { t } = useTranslation('ghg', {
    keyPrefix: 'dataEntryCompletedCheckbox',
  });
  const org = useGhgOrganization();
  const period = timeConverter(selectedDate, org.co2.reporting_freq);
  const { isLoading, _data, create, _delete } = useGhgDataEntriesCompleted({
    organization_id: org.id,
    period,
  });

  const checked = _data.find((entry) => entry.data_type === type);

  const handleChange = () => {
    if (isLoading) return;
    if (checked === undefined) {
      create.mutate({
        data_type: type,
        organization_id: org.id,
        period,
      });
    } else {
      _delete.mutate(checked.id);
    }
  };

  const label =
    checked === undefined
      ? t('labelNotCompleted')
      : t('labelCompleted', {
          date: format(new Date(checked.completed_at), dateFormat),
          user: checked.completed_by,
        });

  return (
    <FormGroup sx={{ mt: '-32px', mb: '12px', ...props.sx }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked !== undefined}
            onClick={() => handleChange()}
          />
        }
        label={label}
      />
    </FormGroup>
  );
}
