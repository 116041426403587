import React from 'react';
import { useEsgConsolidationTableData } from '../../hooks';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import AcceptableNumberField from 'src/components/NumberField/AcceptableNumberField';
import { ConsolidationTableDataRowSchema } from '../../types';
import colors from 'src/theme/colors';
import CollapsableButtonCell from 'src/Esg/Dmav2/components/CollapseButtonCell';
import { useTranslation } from 'react-i18next';
import TextWithTooltip from 'src/components/TextWithTooltip';
import { lightenColor } from 'src/views/components/QuestionnaireV3/helpers';

interface Props {
  tableId: number;
  period: string;
  questionnaireKey: string;
  color: string;
}

type TDimVal = any;

function calculateOrganizations(data?: ConsolidationTableDataRowSchema[]) {
  const orgsSet = new Set<string>();

  data?.forEach((row) => {
    row.values.forEach((v) => {
      orgsSet.add(v.organization_metadata.name);
    });
  });

  return Array.from(orgsSet);
}

export default function ConsolidationNumericTable({
  tableId,
  period,
  questionnaireKey,
  color,
}: Props) {
  const { data, update } = useEsgConsolidationTableData(tableId, period);
  const [collapsed, setCollapsed] = React.useState(false);
  const { t } = useTranslation(undefined, {
    keyPrefix: 'esg:dataVisualization',
  });

  const subOrganizations = calculateOrganizations(data?.rows);

  if (!data || data.rows.length === 0) return null;

  const renderOrgValue = (
    row: ConsolidationTableDataRowSchema,
    org: string
  ) => {
    const cell = row.values.find((v) => v.organization_metadata.name === org);
    if (!cell) return '-';
    return cell.value_label ?? cell.value;
  };

  const renderOrgSummaryValue = (org: string) => {
    const cell = data.organizations_summary_values.find(
      (v) => v.organization_metadata.name === org
    );
    return cell?.value ?? '-';
  };

  const renderDimLabel = (idx: number, dimVal: TDimVal) => {
    const dim = data.table_dimensions[idx];
    const option = dim.options.find((o) => o.key === dimVal);
    return option?.label ?? dimVal;
  };

  const serializeRowDimValues = (row: ConsolidationTableDataRowSchema) => {
    return Object.fromEntries(
      row.table_dimensions_values.map((dimVal, i) => [
        data.table_dimensions[i].key,
        dimVal,
      ])
    );
  };

  return (
    <TableContainer>
      <Table>
        <TableRow>
          <CollapsableButtonCell
            sx={{ border: `1px solid ${color}`, backgroundColor: color }}
            colSpan={
              (data.table_dimensions.length || 1) + 1 + subOrganizations.length
            }
            text={`${questionnaireKey} > ${data.title}`}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
          />
        </TableRow>
        {!collapsed && (
          <>
            <TableRow
              sx={{
                backgroundColor: colors.esgMain,
                color: colors.pureWhite,
                minWidth: '260px',
                border: `1px solid ${colors.esgMain}`,
                maxWidth: '130px',
              }}
            >
              {data.table_dimensions.map((dim) => (
                <TableCell key={dim.key}>
                  <Typography
                    variant="subtitle2"
                    sx={{ color: colors.pureWhite }}
                  >
                    <TextWithTooltip minLength={16} text={dim.label} />
                  </Typography>
                </TableCell>
              ))}
              {data.table_dimensions.length === 0 && <TableCell />}
              <TableCell sx={{ color: colors.pureWhite, minWidth: '150px' }}>
                <Typography
                  variant="subtitle2"
                  sx={{ color: colors.pureWhite }}
                >
                  {t('capitalGroup')}
                </Typography>
              </TableCell>
              {Array.from(subOrganizations).map((org) => (
                <TableCell
                  sx={{ color: colors.pureWhite, justifyItems: 'center' }}
                  key={org}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{ color: colors.pureWhite }}
                  >
                    <TextWithTooltip minLength={16} text={org} />
                  </Typography>
                </TableCell>
              ))}
            </TableRow>

            <TableBody>
              {data.rows.map((row, rowIdx) => (
                <TableRow
                  key={rowIdx}
                  sx={{
                    backgroundColor:
                      rowIdx % 2 === 0 ? 'transparent' : colors.backgroundInput,
                    height: '34px',
                  }}
                >
                  {row.table_dimensions_values.map((dimVal, dimIdx) => (
                    <TableCell
                      key={dimIdx}
                      sx={{
                        borderLeft: `1px solid ${colors.disabledGraphics}`,
                        borderRight: `1px solid ${colors.disabledGraphics}`,
                      }}
                    >
                      <Typography variant="subtitle2">
                        {renderDimLabel(dimIdx, dimVal)}
                        {/*  TextWithTooltip don't work here */}
                      </Typography>
                    </TableCell>
                  ))}
                  {data.table_dimensions.length === 0 && <TableCell />}
                  <TableCell
                    sx={{
                      borderLeft: `1px solid ${colors.disabledGraphics}`,
                      borderRight: `1px solid ${colors.disabledGraphics}`,
                    }}
                  >
                    <AcceptableNumberField
                      key={`${rowIdx}-${row.consolidated_value}`}
                      size="small"
                      value={row.consolidated_value}
                      placeholder={
                        row.summary_value !== null
                          ? String(row.summary_value)
                          : undefined
                      }
                      onAccept={(value) =>
                        update.mutate({
                          value: Number(value), // TODO: support null
                          table_dimensions_values: serializeRowDimValues(row),
                        })
                      }
                      sx={{ backgroundColor: color, borderRadius: '4px' }}
                    />
                  </TableCell>
                  {subOrganizations.map((org) => (
                    <TableCell
                      key={org}
                      sx={{
                        textAlign: 'center',
                        borderLeft: `1px solid ${colors.disabledGraphics}`,
                        borderRight: `1px solid ${colors.disabledGraphics}`,
                        maxWidth: '130px',
                      }}
                    >
                      <Typography variant="subtitle2">
                        {renderOrgValue(row, org)}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
              <TableRow
                sx={{
                  backgroundColor: lightenColor(color, 3),
                  borderLeft: `1px solid ${colors.disabledGraphics}`,
                  borderRight: `1px solid ${colors.disabledGraphics}`,
                  borderBottom: `1px solid ${colors.disabledGraphics}`,
                  height: '34px',
                }}
              >
                <TableCell
                  colSpan={data.table_dimensions.length}
                  sx={{
                    borderLeft: `1px solid ${colors.disabledGraphics}`,
                    borderRight: `1px solid ${colors.disabledGraphics}`,
                    borderBottom: `1px solid ${colors.disabledGraphics}`,
                  }}
                >
                  <Typography variant="subtitle2">
                    {t('consolidatedSummary')}
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    borderLeft: `1px solid ${colors.disabledGraphics}`,
                    borderRight: `1px solid ${colors.disabledGraphics}`,
                    borderBottom: `1px solid ${colors.disabledGraphics}`,
                  }}
                >
                  <TextField
                    size="small"
                    sx={{ backgroundColor: color, borderRadius: '4px' }}
                    disabled
                    value={data.consolidated_summary_value}
                  />
                </TableCell>
                {subOrganizations.map((org) => (
                  <TableCell
                    key={org}
                    sx={{
                      textAlign: 'center',
                      borderLeft: `1px solid ${colors.disabledGraphics}`,
                      borderRight: `1px solid ${colors.disabledGraphics}`,
                      borderBottom: `1px solid ${colors.disabledGraphics}`,
                      maxWidth: '130px',
                    }}
                  >
                    <Typography variant="subtitle2">
                      {renderOrgSummaryValue(org)}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </>
        )}
      </Table>
    </TableContainer>
  );
}
