import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useInstallations } from '../hooks';
import { Box, IconButton, Typography } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { StyledFlex } from '../../components/StyledFlex';
import { useTranslation } from 'react-i18next';
import InstallationForm from './Form';

export default function Installation() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation(undefined, {
    keyPrefix: 'cbam:installations',
  });
  const { data } = useInstallations();
  const installationId = Number(id);
  const installation = data.find(
    (installation) => installation.id === installationId
  );
  return (
    <Box>
      <StyledFlex sx={{ justifyContent: 'flex-start' }}>
        <IconButton onClick={() => navigate('..')}>
          <ChevronLeft />
        </IconButton>
        <Typography variant="h2">
          {t('installation')}: {installation?.name || t('new')}
        </Typography>
      </StyledFlex>
      <br />
      <InstallationForm
        key={data.length}
        id={installationId}
        onSaved={() => navigate('..')}
      />
    </Box>
  );
}
