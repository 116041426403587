import React from 'react';
import { StyledPanel, StyledTitle } from '../../index.styles';
import { useLanguage } from 'src/common/hooks';
import SingleFileUpload from 'src/components/SingleFileUpload';
import { UploadedFileSchema } from 'src/Ghg/CodeOfConductSurvey/types';
import { Box } from '@mui/material';
import OrganizationalLogo from 'src/components/OrganizationalLogo';
import { FileUploadError } from 'src/components/UploadModal/types';

const logotypeUploadUrl = '/web/organizational-logotypes';

export type OrganizationalLogoState = {
  logo: UploadedFileSchema | null;
  setLogo: (logo: UploadedFileSchema | null) => void;
};

export const useOrganizationalLogo = (): OrganizationalLogoState => {
  const [logo, setLogo] = React.useState<UploadedFileSchema | null>(null);
  return { logo, setLogo };
};

type OrganizationLogotypeProps = OrganizationalLogoState & {};

export default function UploadOrganizationalLogo(
  props: OrganizationLogotypeProps
) {
  const { t } = useLanguage({
    keyPrefix: 'superadmin:components.panels.unit.logo',
  });
  const buttonText = props.logo ? t('buttons.change') : t('buttons.upload');

  const extractError = (error: FileUploadError) => {
    return t(`uploadErrors.${error}`, { defaultValue: undefined });
  };

  return (
    <div>
      <StyledTitle sx={{ fontSize: '28px', mt: '40px' }}>
        {t('title')}
      </StyledTitle>

      <StyledPanel sx={{ mt: '32px', p: '32px' }}>
        <Box display="flex" flexDirection="column">
          {props.logo && <OrganizationalLogo logo={props.logo} />}
          <SingleFileUpload
            attachButtonText={buttonText}
            file={props.logo}
            url={logotypeUploadUrl}
            onSuccess={props.setLogo}
            onRemoved={() => props.setLogo(null)}
            tryExtractErrorMessage={extractError}
          />
        </Box>
      </StyledPanel>
    </div>
  );
}
