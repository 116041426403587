import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useEsgQuestionnaires } from '../hooks';
import { Box } from '@mui/system';
import { FormControl, Typography } from '@mui/material';
import { StyledPanel } from 'src/components/StyledPanel';
import { IntuitiveCalendarPeriod } from 'src/views/components/IntuitiveCalendar';
import { ReportingFreq } from 'src/utils/api.interfaces';
import { periodToDate, timeConverter } from 'src/utils/timeConverter';
import {
  EsgQuestionnaireSimpleSchema,
  QuestionnaireMethodology,
} from '../types';
import ConsolidationQuestionPanels from './Consolidation';
import Autocomplete from 'src/components/Autocomplete';

export default function EsgDataVisualization() {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'esg:dataVisualization',
  });
  const { data: questionnaires } = useEsgQuestionnaires(
    QuestionnaireMethodology.EFRAG_XBRL_202409
  );

  const [period, setPeriod] = React.useState<string>(
    timeConverter(new Date(), ReportingFreq.Y)
  );

  const [questionnaire, setQuestionnaire] =
    React.useState<EsgQuestionnaireSimpleSchema | null>(null);

  const handleQuestionnaireChange = (value: string | null) => {
    setQuestionnaire(
      questionnaires.find((questionnaire) => questionnaire.key === value) ??
        null
    );
  };

  useEffect(() => {
    if (questionnaires.length > 0) {
      setQuestionnaire(questionnaires[0]);
    }
  }, [questionnaires]);

  return (
    <Box sx={{ p: '16px 20px' }}>
      <Typography variant="h1" sx={{ mb: '32px' }}>
        {t('title')}
      </Typography>
      <StyledPanel>
        <FormControl fullWidth sx={{ mb: '16px' }}>
          <Autocomplete
            label={t('selectQuestionnaireKey')}
            placeholder={t('selectQuestionnaireKey')}
            options={questionnaires.map((questionnaire) => ({
              value: questionnaire.key,
              label: questionnaire.title,
            }))}
            value={questionnaire?.key ?? null}
            onChange={handleQuestionnaireChange}
            disableClearable
          />
        </FormControl>
        <IntuitiveCalendarPeriod
          reportingPeriod={ReportingFreq.Y}
          selectedDate={periodToDate(period, ReportingFreq.Y)}
          setSelectedDate={(selDate: Date) =>
            setPeriod(timeConverter(selDate, ReportingFreq.Y))
          }
        />
      </StyledPanel>
      {!!questionnaire && (
        <ConsolidationQuestionPanels
          questionnaireKey={questionnaire.key}
          period={period}
          color={questionnaire.color}
        />
      )}
    </Box>
  );
}
